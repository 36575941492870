import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";

const UpdateContactDialog = ({
  open,
  closeUpdateContactDialog,
  updateContact,
  contact,
}) => {
  const [formData, setFormData] = useState({
    full_name: "",
    email: "",
    phone_number: "",
    location_name: "",
    date_met: null,
    notes: "",
    follow_up_priority: "",
  });

  // Format for the date input field (YYYY-MM-DD)
  const [dateInputValue, setDateInputValue] = useState("");

  useEffect(() => {
    if (contact) {
      // Parse the date from string format (e.g., "January 1st 2023") to Date object
      let dateMet = null;
      if (contact.date_met) {
        try {
          const [month, dayWithSuffix, year] = contact.date_met.split(" ");
          const day = dayWithSuffix.replace(/(st|nd|rd|th)/, "");
          const monthIndex = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
          ].indexOf(month);
          dateMet = new Date(parseInt(year), monthIndex, parseInt(day));

          // Format the date for the input field
          const formattedDate = formatDateForInput(dateMet);
          setDateInputValue(formattedDate);
        } catch (error) {
          console.error("Error parsing date:", error);
          dateMet = new Date();
          setDateInputValue(formatDateForInput(dateMet));
        }
      }

      setFormData({
        full_name: contact.full_name || "",
        email: contact.email || "",
        phone_number: contact.phone_number || "",
        location_name: contact.location_name || "",
        date_met: dateMet,
        notes: contact.notes || "",
        follow_up_priority: contact.follow_up_priority || "",
      });
    }
  }, [contact]);

  const formatDateForInput = (date) => {
    if (!date) return "";
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleDateChange = (e) => {
    const dateValue = e.target.value;
    setDateInputValue(dateValue);

    if (dateValue) {
      const [year, month, day] = dateValue.split("-").map(Number);
      const date = new Date(year, month - 1, day);
      setFormData({
        ...formData,
        date_met: date,
      });
    } else {
      setFormData({
        ...formData,
        date_met: null,
      });
    }
  };

  const handleSubmit = () => {
    // Format the date as a string in the format "Month Day Year" (e.g., "January 1st 2023")
    let formattedDate = "";
    let date = null;
    if (formData.date_met) {
      date = new Date(formData.date_met);
      const day = date.getDate();
      const month = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ][date.getMonth()];
      const year = date.getFullYear();

      // Add the appropriate suffix to the day
      let daySuffix = "th";
      if (day % 10 === 1 && day !== 11) daySuffix = "st";
      else if (day % 10 === 2 && day !== 12) daySuffix = "nd";
      else if (day % 10 === 3 && day !== 13) daySuffix = "rd";

      formattedDate = `${month} ${day}${daySuffix} ${year}`;
    }

    const updatedData = {
      full_name: formData.full_name,
      email: formData.email,
      phone_number: formData.phone_number,
      location_name: formData.location_name,
      notes: formData.notes,
      follow_up_priority: formData.follow_up_priority,
    };

    if (date) {
      updatedData.date_met = date;
    }

    updateContact(contact.id, updatedData);
    closeUpdateContactDialog();
  };

  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 600 } }}
      maxWidth="md"
      open={open}
    >
      <DialogTitle>Update Contact</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Name"
              name="full_name"
              value={formData.full_name}
              onChange={handleChange}
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Phone Number"
              name="phone_number"
              value={formData.phone_number}
              onChange={handleChange}
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Met in"
              name="location_name"
              value={formData.location_name}
              onChange={handleChange}
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Date Met"
              type="date"
              value={dateInputValue}
              onChange={handleDateChange}
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth margin="normal">
              <InputLabel id="follow-up-priority-label">
                Follow-up Priority
              </InputLabel>
              <Select
                labelId="follow-up-priority-label"
                id="follow-up-priority"
                name="follow_up_priority"
                value={formData.follow_up_priority}
                onChange={handleChange}
                label="Follow-up Priority"
              >
                <MenuItem value="">None</MenuItem>
                <MenuItem value="high">High</MenuItem>
                <MenuItem value="medium">Medium</MenuItem>
                <MenuItem value="low">Low</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Notes"
              name="notes"
              value={formData.notes}
              onChange={handleChange}
              margin="normal"
              multiline
              rows={4}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeUpdateContactDialog}>Cancel</Button>
        <Button onClick={handleSubmit} variant="contained" color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateContactDialog;
