import React, { useState, useEffect } from "react";
import { homeObjOne, homeObjTwo, homeObjThree } from "./Data";
import AppHeader from "../../components/Navigation/AppHeader";
import {
  Container,
  Box,
  Typography,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Button,
  CircularProgress,
  Snackbar,
  Alert,
  keyframes,
} from "@mui/material";
import * as action from "../../service/action";
import { useUserContext } from "../../pages/Login/userContext";

// Define keyframes for floating animation
const floatAnimation = keyframes`
  0% { transform: translateY(0px) translateX(0px); }
  50% { transform: translateY(-15px) translateX(10px); }
  100% { transform: translateY(0px) translateX(0px); }
`;

function Products() {
  const [isLoading, setIsLoading] = useState(false);
  const [loadingProduct, setLoadingProduct] = useState(null);
  const [errorNotification, setErrorNotification] = useState({
    show: false,
    message: "",
  });
  const [successNotification, setSuccessNotification] = useState({
    show: false,
    message: "",
  });

  // Use the UserContext to get the user
  const { getUser } = useUserContext();

  // Check for successful purchase return from Stripe
  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const purchaseSuccess = query.get("purchase_success");

    if (purchaseSuccess === "true") {
      setSuccessNotification({
        show: true,
        message:
          "Thank you for your purchase! Your premium business cards have been ordered and will be processed shortly. You'll receive a confirmation email with all the details.",
      });

      // Clean up the URL to remove query parameters
      window.history.replaceState({}, document.title, window.location.pathname);
    }
  }, []);

  // Get the current user ID from the UserContext
  const getUserId = () => {
    const user = getUser();
    return user?.uid || "anonymous"; // Firebase uses uid instead of id
  };

  const handleShopNow = async (product, productName, e) => {
    e.preventDefault(); // Prevent default link behavior
    try {
      setIsLoading(true);
      setLoadingProduct(product);

      // Get the current user ID if available (optional)
      const userId = getUserId();

      // Create checkout product and redirect to Stripe
      const response = await action.createCheckoutProduct({
        product: productName,
        success_url: window.location.origin + "/products?purchase_success=true",
        cancel_url: window.location.origin + "/products",
        client_reference_id: userId,
        metadata: {
          productId: productName,
          source: "products_page",
        },
      });

      if (response && response.data && response.data.url) {
        // Redirect to Stripe Checkout
        window.location.href = response.data.url;
      } else {
        // Handle error response
        console.error("Failed to create checkout product:", response);
        const errorMessage =
          response?.data?.error ||
          "Failed to create checkout. Please try again.";
        setErrorNotification({
          show: true,
          message: errorMessage,
        });
        // Fallback to original link if checkout creation fails
        window.open(product.linkTo, "_blank");
      }
    } catch (error) {
      console.error("Error creating checkout product:", error);
      setErrorNotification({
        show: true,
        message: "An unexpected error occurred. Please try again later.",
      });
      // Fallback to original link if an error occurs
      window.open(product.linkTo, "_blank");
    } finally {
      setIsLoading(false);
      setLoadingProduct(null);
    }
  };

  // Handle closing the error notification
  const handleCloseErrorNotification = () => {
    setErrorNotification({ ...errorNotification, show: false });
  };

  // Handle closing the success notification
  const handleCloseSuccessNotification = () => {
    setSuccessNotification({ ...successNotification, show: false });
  };

  return (
    <>
      {/* Header */}
      <AppHeader />

      {/* Hero Section */}
      <Box
        sx={{
          pt: { xs: 12, md: 18 },
          pb: { xs: 8, md: 10 },
          background: "linear-gradient(135deg, #0f0f23 0%, #1a1a3a 100%)",
          color: "white",
          textAlign: "center",
          position: "relative",
          overflow: "hidden",
          "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background:
              "radial-gradient(circle at 20% 150%, rgba(120, 120, 255, 0.15) 0%, transparent 60%)",
            zIndex: 1,
          },
        }}
      >
        {/* Animated background dots */}
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 0,
            overflow: "hidden",
          }}
        >
          {[...Array(6)].map((_, index) => (
            <Box
              key={index}
              sx={{
                position: "absolute",
                width: { xs: "100px", md: "150px" },
                height: { xs: "100px", md: "150px" },
                borderRadius: "50%",
                background:
                  "radial-gradient(circle, rgba(220, 0, 78, 0.03) 0%, rgba(220, 0, 78, 0.01) 70%, transparent 100%)",
                top: `${Math.random() * 100}%`,
                left: `${Math.random() * 100}%`,
                animation: `${floatAnimation} ${
                  7 + index * 2
                }s ease-in-out infinite`,
                animationDelay: `${index * 0.5}s`,
                opacity: 0.6,
              }}
            />
          ))}
        </Box>

        <Container maxWidth="lg" sx={{ position: "relative", zIndex: 2 }}>
          <Typography
            variant="h2"
            component="h1"
            sx={{
              fontWeight: 800,
              mb: 3,
              fontSize: { xs: "2.75rem", md: "4rem" },
              letterSpacing: "-0.5px",
              background: "linear-gradient(90deg, #ffffff 0%, #e0e0ff 100%)",
              backgroundClip: "text",
              WebkitBackgroundClip: "text",
              color: "transparent",
              textShadow: "0 2px 10px rgba(255, 255, 255, 0.15)",
            }}
          >
            Premium Business Cards
          </Typography>
          <Typography
            variant="h5"
            sx={{
              fontWeight: 400,
              mb: 5,
              opacity: 0.9,
              maxWidth: "800px",
              mx: "auto",
              lineHeight: 1.6,
              fontSize: { xs: "1.1rem", md: "1.35rem" },
            }}
          >
            Elevate your professional image with our meticulously crafted
            business cards, designed to make a lasting impression in every
            professional encounter.
          </Typography>
        </Container>
      </Box>

      {/* Featured Products Grid */}
      <Box
        sx={{
          py: { xs: 10, md: 14 },
          background: "linear-gradient(to bottom, #f8f9fa, #f0f2f5)",
          position: "relative",
          "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            height: "1px",
            background:
              "linear-gradient(90deg, transparent, rgba(0,0,0,0.05), transparent)",
          },
          "&::after": {
            content: '""',
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            height: "1px",
            background:
              "linear-gradient(90deg, transparent, rgba(0,0,0,0.05), transparent)",
          },
        }}
      >
        <Container maxWidth="lg">
          <Typography
            variant="h3"
            component="h2"
            sx={{
              fontWeight: 600,
              mb: 2,
              textAlign: "center",
              color: "#0f0f23",
              position: "relative",
              display: "inline-block",
              left: "50%",
              transform: "translateX(-50%)",
              "&::after": {
                content: '""',
                position: "absolute",
                bottom: -10,
                left: "25%",
                width: "50%",
                height: "3px",
                background:
                  "linear-gradient(90deg, transparent, #dc004e, transparent)",
                borderRadius: "2px",
              },
            }}
          >
            Our Premium Collections
          </Typography>

          <Typography
            variant="body1"
            sx={{
              textAlign: "center",
              color: "#555",
              mb: 8,
              maxWidth: "700px",
              mx: "auto",
              fontSize: "1.1rem",
            }}
          >
            Each card collection is designed with meticulous attention to detail
            and premium materials
          </Typography>

          <Grid container spacing={5}>
            {/* Product Cards will go here */}
            <Grid item xs={12} md={4}>
              <Card
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: "12px",
                  overflow: "hidden",
                  boxShadow: "0 10px 30px rgba(0,0,0,0.08)",
                  transition: "all 0.3s ease",
                  "&:hover": {
                    transform: "translateY(-8px)",
                    boxShadow: "0 15px 35px rgba(0,0,0,0.15)",
                  },
                  position: "relative",
                }}
              >
                <Box sx={{ position: "relative", overflow: "hidden" }}>
                  <CardMedia
                    component="img"
                    height="300"
                    image={homeObjOne.img}
                    alt={homeObjOne.alt}
                    sx={{
                      objectFit: "cover",
                      transition:
                        "transform 0.6s cubic-bezier(0.33, 1, 0.68, 1)",
                      "&:hover": {
                        transform: "scale(1.08)",
                      },
                    }}
                  />
                  <Box
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      background:
                        "linear-gradient(to top, rgba(0,0,0,0.2) 0%, transparent 30%)",
                      opacity: 0,
                      transition: "opacity 0.3s ease",
                      "&:hover": {
                        opacity: 1,
                      },
                    }}
                  />
                </Box>
                <CardContent sx={{ flexGrow: 1, p: 4 }}>
                  <Typography
                    gutterBottom
                    variant="subtitle1"
                    component="div"
                    color="primary"
                    sx={{
                      fontWeight: 600,
                      letterSpacing: "1px",
                      textTransform: "uppercase",
                      fontSize: "0.85rem",
                    }}
                  >
                    {homeObjOne.topLine}
                  </Typography>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="h3"
                    sx={{
                      fontWeight: 700,
                      mb: 2,
                      color: "#263238",
                    }}
                  >
                    {homeObjOne.headline}
                  </Typography>
                  <Typography
                    variant="body1"
                    color="text.secondary"
                    sx={{ mb: 4, lineHeight: 1.6 }}
                  >
                    {homeObjOne.description}
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={(e) =>
                      handleShopNow(homeObjOne, "bappa-preferred", e)
                    }
                    disabled={isLoading && loadingProduct === homeObjOne}
                    sx={{
                      bgcolor: "#dc004e",
                      "&:hover": {
                        bgcolor: "#c2003c",
                        transform: "translateY(-2px)",
                        boxShadow: "0 5px 15px rgba(220, 0, 78, 0.4)",
                      },
                      borderRadius: "8px",
                      py: 1.5,
                      px: 3,
                      boxShadow: "0 4px 12px rgba(220, 0, 78, 0.25)",
                      transition: "all 0.3s ease",
                      textTransform: "none",
                      fontWeight: 600,
                      fontSize: "1rem",
                    }}
                  >
                    {isLoading && loadingProduct === homeObjOne ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      homeObjOne.buttonLabel
                    )}
                  </Button>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={4}>
              <Card
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: "12px",
                  overflow: "hidden",
                  boxShadow: "0 10px 30px rgba(0,0,0,0.08)",
                  transition: "all 0.3s ease",
                  "&:hover": {
                    transform: "translateY(-8px)",
                    boxShadow: "0 15px 35px rgba(0,0,0,0.15)",
                  },
                  position: "relative",
                }}
              >
                <Box sx={{ position: "relative", overflow: "hidden" }}>
                  <CardMedia
                    component="img"
                    height="300"
                    image={homeObjTwo.img}
                    alt={homeObjTwo.alt}
                    sx={{
                      objectFit: "cover",
                      transition:
                        "transform 0.6s cubic-bezier(0.33, 1, 0.68, 1)",
                      "&:hover": {
                        transform: "scale(1.08)",
                      },
                    }}
                  />
                  <Box
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      background:
                        "linear-gradient(to top, rgba(0,0,0,0.2) 0%, transparent 30%)",
                      opacity: 0,
                      transition: "opacity 0.3s ease",
                      "&:hover": {
                        opacity: 1,
                      },
                    }}
                  />
                </Box>
                <CardContent sx={{ flexGrow: 1, p: 4 }}>
                  <Typography
                    gutterBottom
                    variant="subtitle1"
                    component="div"
                    color="primary"
                    sx={{
                      fontWeight: 600,
                      letterSpacing: "1px",
                      textTransform: "uppercase",
                      fontSize: "0.85rem",
                    }}
                  >
                    {homeObjTwo.topLine}
                  </Typography>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="h3"
                    sx={{
                      fontWeight: 700,
                      mb: 2,
                      color: "#263238",
                    }}
                  >
                    {homeObjTwo.headline}
                  </Typography>
                  <Typography
                    variant="body1"
                    color="text.secondary"
                    sx={{ mb: 4, lineHeight: 1.6 }}
                  >
                    {homeObjTwo.description}
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={(e) =>
                      handleShopNow(homeObjTwo, "bappa-custom", e)
                    }
                    disabled={isLoading && loadingProduct === homeObjTwo}
                    sx={{
                      bgcolor: "#dc004e",
                      "&:hover": {
                        bgcolor: "#c2003c",
                        transform: "translateY(-2px)",
                        boxShadow: "0 5px 15px rgba(220, 0, 78, 0.4)",
                      },
                      borderRadius: "8px",
                      py: 1.5,
                      px: 3,
                      boxShadow: "0 4px 12px rgba(220, 0, 78, 0.25)",
                      transition: "all 0.3s ease",
                      textTransform: "none",
                      fontWeight: 600,
                      fontSize: "1rem",
                    }}
                  >
                    {isLoading && loadingProduct === homeObjTwo ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      homeObjTwo.buttonLabel
                    )}
                  </Button>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={4}>
              <Card
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: "12px",
                  overflow: "hidden",
                  boxShadow: "0 10px 30px rgba(0,0,0,0.08)",
                  transition: "all 0.3s ease",
                  "&:hover": {
                    transform: "translateY(-8px)",
                    boxShadow: "0 15px 35px rgba(0,0,0,0.15)",
                  },
                  position: "relative",
                }}
              >
                <Box sx={{ position: "relative", overflow: "hidden" }}>
                  <CardMedia
                    component="img"
                    height="300"
                    image={homeObjThree.img}
                    alt={homeObjThree.alt}
                    sx={{
                      objectFit: "cover",
                      transition:
                        "transform 0.6s cubic-bezier(0.33, 1, 0.68, 1)",
                      "&:hover": {
                        transform: "scale(1.08)",
                      },
                    }}
                  />
                  <Box
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      background:
                        "linear-gradient(to top, rgba(0,0,0,0.2) 0%, transparent 30%)",
                      opacity: 0,
                      transition: "opacity 0.3s ease",
                      "&:hover": {
                        opacity: 1,
                      },
                    }}
                  />
                </Box>
                <CardContent sx={{ flexGrow: 1, p: 4 }}>
                  <Typography
                    gutterBottom
                    variant="subtitle1"
                    component="div"
                    color="primary"
                    sx={{
                      fontWeight: 600,
                      letterSpacing: "1px",
                      textTransform: "uppercase",
                      fontSize: "0.85rem",
                    }}
                  >
                    {homeObjThree.topLine}
                  </Typography>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="h3"
                    sx={{
                      fontWeight: 700,
                      mb: 2,
                      color: "#263238",
                    }}
                  >
                    {homeObjThree.headline}
                  </Typography>
                  <Typography
                    variant="body1"
                    color="text.secondary"
                    sx={{ mb: 4, lineHeight: 1.6 }}
                  >
                    {homeObjThree.description}
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={(e) =>
                      handleShopNow(homeObjThree, "bappa-platinum", e)
                    }
                    disabled={isLoading && loadingProduct === homeObjThree}
                    sx={{
                      bgcolor: "#dc004e",
                      "&:hover": {
                        bgcolor: "#c2003c",
                        transform: "translateY(-2px)",
                        boxShadow: "0 5px 15px rgba(220, 0, 78, 0.4)",
                      },
                      borderRadius: "8px",
                      py: 1.5,
                      px: 3,
                      boxShadow: "0 4px 12px rgba(220, 0, 78, 0.25)",
                      transition: "all 0.3s ease",
                      textTransform: "none",
                      fontWeight: 600,
                      fontSize: "1rem",
                    }}
                  >
                    {isLoading && loadingProduct === homeObjThree ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      homeObjThree.buttonLabel
                    )}
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Call to Action Section */}
      <Box
        sx={{
          py: { xs: 10, md: 14 },
          background: "linear-gradient(135deg, #0c0c1d 0%, #1a1a3a 100%)",
          color: "white",
          textAlign: "center",
          position: "relative",
          overflow: "hidden",
          "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundImage:
              'url("data:image/svg+xml,%3Csvg width="40" height="40" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M20 20.5V18H0v-2h20v-2H0v-2h20v-2H0V8h20V6H0V4h20V2H0V0h22v20h2V0h2v20h2V0h2v20h2V0h2v20h2V0h2v20h2v2H20v-1.5zM0 20h2v20H0V20zm4 0h2v20H4V20zm4 0h2v20H8V20zm4 0h2v20h-2V20zm4 0h2v20h-2V20zm4 4h20v2H20v-2zm0 4h20v2H20v-2zm0 4h20v2H20v-2zm0 4h20v2H20v-2z" fill="rgba(255,255,255,0.05)" fill-opacity="0.05" fill-rule="evenodd"/%3E%3C/svg%3E")',
            zIndex: 1,
          },
        }}
      >
        <Container maxWidth="md" sx={{ position: "relative", zIndex: 2 }}>
          <Typography
            variant="h3"
            component="h2"
            sx={{
              fontWeight: 700,
              mb: 3,
              fontSize: { xs: "2.25rem", md: "3rem" },
              background: "linear-gradient(90deg, #ffffff 0%, #e0e0ff 100%)",
              backgroundClip: "text",
              WebkitBackgroundClip: "text",
              color: "transparent",
              letterSpacing: "-0.5px",
            }}
          >
            Ready to Make a Lasting Impression?
          </Typography>
          <Typography
            variant="h6"
            sx={{
              fontWeight: 400,
              mb: 6,
              opacity: 0.9,
              maxWidth: "700px",
              mx: "auto",
              lineHeight: 1.6,
              fontSize: { xs: "1rem", md: "1.2rem" },
            }}
          >
            Choose from our premium selection of business cards designed by
            experts to elevate your professional presence and create memorable
            first impressions
          </Typography>
          <Button
            variant="contained"
            size="large"
            href="/get-started"
            sx={{
              bgcolor: "#dc004e",
              "&:hover": {
                bgcolor: "#c2003c",
                transform: "translateY(-3px)",
                boxShadow: "0 8px 20px rgba(220, 0, 78, 0.4)",
              },
              borderRadius: "8px",
              py: 2,
              px: 5,
              fontSize: "1.1rem",
              textTransform: "none",
              fontWeight: 600,
              boxShadow: "0 6px 15px rgba(220, 0, 78, 0.35)",
              transition: "all 0.3s ease",
              position: "relative",
              overflow: "hidden",
              "&::after": {
                content: '""',
                position: "absolute",
                top: 0,
                left: "-100%",
                width: "100%",
                height: "100%",
                background:
                  "linear-gradient(90deg, transparent, rgba(255,255,255,0.2), transparent)",
                transition: "0.5s",
              },
              "&:hover::after": {
                left: "100%",
              },
            }}
          >
            Get Started
          </Button>
        </Container>
      </Box>

      {/* Error Notification */}
      <Snackbar
        open={errorNotification.show}
        autoHideDuration={6000}
        onClose={handleCloseErrorNotification}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        sx={{
          top: { xs: 16, sm: 24 },
        }}
      >
        <Alert
          onClose={handleCloseErrorNotification}
          severity="error"
          sx={{
            width: "100%",
            boxShadow: "0 4px 20px rgba(0,0,0,0.15)",
            borderRadius: "8px",
            "& .MuiAlert-icon": {
              fontSize: "1.2rem",
            },
            "& .MuiAlert-message": {
              fontSize: "0.95rem",
              fontWeight: 500,
            },
            py: 1.5,
            px: 2,
          }}
        >
          {errorNotification.message}
        </Alert>
      </Snackbar>

      {/* Success Notification */}
      <Snackbar
        open={successNotification.show}
        autoHideDuration={6000}
        onClose={handleCloseSuccessNotification}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        sx={{
          top: { xs: 16, sm: 24 },
        }}
      >
        <Alert
          onClose={handleCloseSuccessNotification}
          severity="success"
          sx={{
            width: "100%",
            boxShadow: "0 4px 20px rgba(0,0,0,0.15)",
            borderRadius: "8px",
            "& .MuiAlert-icon": {
              fontSize: "1.2rem",
            },
            "& .MuiAlert-message": {
              fontSize: "0.95rem",
              fontWeight: 500,
            },
            py: 1.5,
            px: 2,
          }}
        >
          {successNotification.message}
        </Alert>
      </Snackbar>

      {/* Original InfoSection components (hidden) */}
      {/* 
      <InfoSection {...homeObjOne} />
      <InfoSection {...homeObjTwo} />
      <InfoSection {...homeObjThree} />
      */}
    </>
  );
}

export default Products;
