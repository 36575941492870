import React from "react";
import { Box, Typography, Button, Avatar, useTheme } from "@mui/material";
import { styled } from "@mui/system";
import { downloadVcard } from "./SaveContact";
import SaveContact from "./SaveContact";
import LanguageIcon from "@mui/icons-material/Language";
import QrCode from "@mui/icons-material/QrCode";
import IconButton from "@mui/material/IconButton";
import QrCodeModal from "./QrCodeModal";
import { useMediaQuery } from "@mui/material";

import instagramIcon from "../../images/instagram-color-icon.png";
import twitterIcon from "../../images/twitter-color-icon.png";
import youtubeIcon from "../../images/youtube-color-icon.png";
import linkedinIcon from "../../images/linkedin-app-icon.png";
import snapchatIcon from "../../images/snapchat-color-icon.png";
import tiktokIcon from "../../images/tiktok-color-icon.png";
import facebookIcon from "../../images/facebook-round-color-icon.png";
import whatsappIcon from "../../images/whatsapp-round-color-icon.png";
import cashappIcon from "../../images/cash-app-icon.png";
import venmoIcon from "../../images/venmo-icon.png";
import paypalIcon from "../../images/paypal-icon.png";

const PreviewContainer = styled(Box)(({ theme }) => {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return {
    backgroundColor: "#f0f0f0",
    borderRadius: "20px",
    overflow: "hidden",
    width: "100%",
    maxWidth: isMobile ? "100%" : "360px",
    boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
  };
});

const CoverPhoto = styled(Box)(({ theme }) => ({
  height: "200px",
  width: "100%",
  backgroundSize: "cover",
  backgroundPosition: "center",
  position: "relative",
}));

const ProfileContent = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  paddingTop: 0,
  position: "relative",
  width: "100%",
}));

const ProfilePhotoContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  width: theme.spacing(20),
  height: theme.spacing(20),
  margin: "0 auto",
  marginTop: theme.spacing(-10),
}));

const ProfilePhoto = styled(Avatar)(({ theme }) => ({
  width: "100%",
  height: "100%",
  border: "4px solid #f0f0f0",
  zIndex: 1,
}));

const CompanyLogo = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(7),
  height: theme.spacing(7),
  border: "2px solid #f0f0f0",
  position: "absolute",
  bottom: theme.spacing(-3),
  right: theme.spacing(-1),
  zIndex: 2,
  backgroundColor: "#fff",
  boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
}));

const ActionButton = styled(Button)(({ theme, color, customfont }) => ({
  backgroundColor: color,
  color: "#fff",
  borderRadius: "20px",
  padding: theme.spacing(1, 3),
  marginBottom: theme.spacing(1),
  fontFamily: customfont || "inherit",
  "&:hover": {
    backgroundColor: "#444444",
    opacity: 0.9,
  },
}));

const ContactButton = styled(Button)(({ theme, color, customfont }) => ({
  backgroundColor: "#fff",
  color: color,
  borderRadius: "20px",
  padding: theme.spacing(1, 2),
  marginBottom: theme.spacing(1),
  position: "relative",
  fontFamily: customfont || "inherit",
  minHeight: "48px",
  height: "auto",
  whiteSpace: "normal",
  width: "100%",
  "&:hover": {
    backgroundColor: "#f0f0f0",
  },
}));

const ProfilePreview = ({
  formData,
  preferences,
  redirectEnabled,
  redirectLink,
  onExchangeContact,
  openExchangeContact,
  isProfilePage,
}) => {
  const theme = useTheme();
  const openSaveContactModal = () => setSaveContactModalOpen(true);
  const closeSaveContactModal = () => setSaveContactModalOpen(false);
  const [saveContactModalOpen, setSaveContactModalOpen] = React.useState(false);
  const [qrCodeModalOpen, setQrCodeModalOpen] = React.useState(false);
  const openQrCodeModal = () => setQrCodeModalOpen(true);
  const closeQrCodeModal = () => setQrCodeModalOpen(false);
  const {
    firstName,
    lastName,
    title,
    cardTheme,
    linkColor,
    profilePhoto,
    coverPhoto,
    matchLinkIcons,
    socials,
    socialOrder,
    companyPhoto,
  } = formData;

  const { showSaveContactButton, showExchangeContactButton } = preferences;

  const renderSocialLinks = () => {
    const socialIcons = {
      website: <LanguageIcon />,
      instagram: instagramIcon,
      twitter: twitterIcon,
      linkedin: linkedinIcon,
      snapchat: snapchatIcon,
      tiktok: tiktokIcon,
      facebook: facebookIcon,
      youtube: youtubeIcon,
      whatsapp: whatsappIcon,
      cashapp: cashappIcon,
      venmo: venmoIcon,
      paypal: paypalIcon,
    };

    const socialUrls = {
      website: (url) => url,
      instagram: (username) => `https://www.instagram.com/${username}`,
      twitter: (username) => `https://www.twitter.com/${username}`,
      linkedin: (url) => url,
      snapchat: (username) => `https://www.snapchat.com/add/${username}`,
      tiktok: (username) => `https://www.tiktok.com/@${username}`,
      facebook: (url) => url,
      youtube: (url) => url,
      whatsapp: (number) => `https://wa.me/${number}`,
      cashapp: (username) => `https://cash.app/$${username}`,
      venmo: (username) => `https://venmo.com/${username}`,
      paypal: (username) => `https://www.paypal.me/${username}`,
    };

    return socialOrder
      .flatMap((platform) =>
        socials[platform].map((value, index) => {
          if (platform === "website" ? value.name && value.url : value) {
            return (
              <ContactButton
                key={`${platform}-${index}`}
                fullWidth
                customfont={preferences.font}
                sx={{
                  color: matchLinkIcons ? cardTheme : linkColor,
                  "& .MuiButton-startIcon": {
                    position: "absolute",
                    left: theme.spacing(1.5),
                    top: "50%",
                    transform: "translateY(-50%)",
                    marginRight: 0,
                  },
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: theme.spacing(1.5, 1),
                  width: "100%",
                  height: "auto",
                  minHeight: "48px",
                  textAlign: "center",
                  mx: "auto",
                }}
                startIcon={
                  typeof socialIcons[platform] === "object" ? (
                    socialIcons[platform]
                  ) : (
                    <img
                      src={socialIcons[platform]}
                      alt={platform}
                      style={{ width: "20px", height: "20px" }}
                    />
                  )
                }
                onClick={() =>
                  window.open(
                    platform === "website"
                      ? `https://${value.url
                          .replace("https://", "")
                          .replace("http://", "")}`
                      : socialUrls[platform](value),
                    "_blank"
                  )
                }
              >
                <Typography
                  variant="button"
                  component="span"
                  sx={{
                    width: "100%",
                    textAlign: "center",
                    fontWeight: "bold",
                    fontSize: (theme) => {
                      const text =
                        platform === "website"
                          ? value.name
                          : platform === "whatsapp"
                          ? `WhatsApp ${index + 1 === 1 ? "" : index + 1}`
                          : platform === "linkedin"
                          ? `LinkedIn ${index + 1 === 1 ? "" : index + 1}`
                          : platform === "youtube"
                          ? `YouTube ${index + 1 === 1 ? "" : index + 1}`
                          : platform === "facebook"
                          ? `Facebook ${index + 1 === 1 ? "" : index + 1}`
                          : `@${value}`;

                      // Dynamically adjust font size based on text length
                      if (text.length <= 20) {
                        return "18px"; // Slightly reduced from 20px
                      } else if (text.length <= 25) {
                        return "16px";
                      } else if (text.length <= 30) {
                        return "14px";
                      } else {
                        return "12px"; // Minimum size for very long text
                      }
                    },
                    textTransform: "none", // This prevents all-caps text
                    fontFamily: preferences.font || "inherit",
                    display: "block",
                    paddingLeft: "32px", // Increased left padding to accommodate repositioned icon
                    paddingRight: "6px", // Reduced right padding
                    maxWidth: "100%",
                    wordBreak: "break-word", // Allow breaking long words
                    whiteSpace: "normal", // Allow text to wrap
                    lineHeight: 1.2, // Tighter line height for wrapped text
                    margin: "0 auto", // Center the text
                  }}
                >
                  {platform === "website"
                    ? value.name
                    : platform === "whatsapp"
                    ? `WhatsApp ${index + 1 === 1 ? "" : index + 1}`
                    : platform === "linkedin"
                    ? `LinkedIn ${index + 1 === 1 ? "" : index + 1}`
                    : platform === "youtube"
                    ? `YouTube ${index + 1 === 1 ? "" : index + 1}`
                    : platform === "facebook"
                    ? `Facebook ${index + 1 === 1 ? "" : index + 1}`
                    : `@${value}`}
                </Typography>
              </ContactButton>
            );
          }
          return null;
        })
      )
      .filter(Boolean);
  };

  return (
    <PreviewContainer>
      <CoverPhoto style={{ backgroundImage: `url(${coverPhoto})` }} />
      <ProfileContent>
        <ProfilePhotoContainer>
          <ProfilePhoto src={profilePhoto} />
          {companyPhoto && (
            <Avatar
              src={companyPhoto}
              sx={{
                width: 56,
                height: 56,
                border: "2px solid #f0f0f0",
                position: "absolute",
                bottom: -10,
                right: -10,
                zIndex: 2,
                backgroundColor: "#fff",
                boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
              }}
            />
          )}
        </ProfilePhotoContainer>
        <Box sx={{ textAlign: "center", mb: 2, mt: 2 }}>
          <Typography variant="h5" gutterBottom fontFamily={preferences.font}>
            {firstName} {lastName}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            gutterBottom
            fontFamily={preferences.font}
          >
            {title}
          </Typography>
        </Box>
        {redirectEnabled ? (
          <Box sx={{ textAlign: "center", mt: 2 }}>
            <Typography variant="body2" color="textSecondary">
              Card will redirect to link:
            </Typography>
            <Typography
              variant="body1"
              color="primary"
              sx={{ wordBreak: "break-all" }}
              onClick={() => window.open(redirectLink, "_blank")}
            >
              {redirectLink || "No redirect link set"}
            </Typography>
          </Box>
        ) : (
          <>
            {showSaveContactButton && (
              <ActionButton
                fullWidth
                customfont={preferences.font}
                sx={{ mb: 1, backgroundColor: cardTheme }}
                onClick={() =>
                  isProfilePage
                    ? openSaveContactModal()
                    : downloadVcard(formData.links)
                }
              >
                Save Contact
              </ActionButton>
            )}
            {showExchangeContactButton && (
              <ActionButton
                fullWidth
                customfont={preferences.font}
                sx={{ mb: 1, backgroundColor: cardTheme }}
                onClick={isProfilePage ? onExchangeContact : undefined}
              >
                Exchange Contact
              </ActionButton>
            )}
            {renderSocialLinks()}
            <Box display="flex" justifyContent="center" width="100%">
              <IconButton
                style={{ padding: 0 }}
                onClick={isProfilePage ? openQrCodeModal : undefined}
              >
                <QrCode sx={{ color: cardTheme, fontSize: 80 }} />
              </IconButton>
            </Box>
          </>
        )}
      </ProfileContent>
      <SaveContact
        open={saveContactModalOpen}
        handleClose={closeSaveContactModal}
        profile={{ ...formData, preferences }}
        openExchangeContact={openExchangeContact}
      />
      <QrCodeModal
        open={qrCodeModalOpen}
        handleClose={closeQrCodeModal}
        url={formData.profileUrl}
      />
    </PreviewContainer>
  );
};

export default ProfilePreview;
