import React, { useState, useEffect, useRef, useCallback } from "react";
import { MapContainer, TileLayer, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import "leaflet.markercluster";
import { useMediaQuery, Box, CircularProgress } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import "./MapViewCustomStyles.css";

// Fix for default marker icon
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

// Create a custom icon factory function
const createCustomIcon = (color) => {
  return new L.Icon({
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
    className: "custom-icon",
    iconStyle: `
      filter: drop-shadow(0 0 0 ${color}) 
              drop-shadow(0 0 0 ${color}) 
              drop-shadow(0 0 0 ${color}) 
              drop-shadow(0 0 0 ${color}) 
              drop-shadow(0 0 0 ${color});
    `,
  });
};

// MapController component to handle map operations
const MapController = ({
  searchLocation,
  mapRef,
  geoContacts,
  markerClusterRef,
  getPriorityColor,
}) => {
  const map = useMap();

  // Store the map instance in the ref
  useEffect(() => {
    if (map && mapRef) {
      mapRef.current = map;
    }
  }, [map, mapRef]);

  // Handle search location changes
  useEffect(() => {
    if (searchLocation && searchLocation.lat && searchLocation.lng) {
      map.setView([searchLocation.lat, searchLocation.lng], 10);
    }
  }, [searchLocation, map]);

  // Handle marker creation and clustering
  useEffect(() => {
    if (map && geoContacts && geoContacts.length > 0) {
      // Clear existing marker cluster if it exists
      if (markerClusterRef.current) {
        map.removeLayer(markerClusterRef.current);
        markerClusterRef.current = null;
      }

      // Initialize marker cluster
      markerClusterRef.current = L.markerClusterGroup({
        spiderfyOnMaxZoom: true,
        showCoverageOnHover: false,
        zoomToBoundsOnClick: true,
        // Custom icon creation for clusters
        iconCreateFunction: (cluster) => {
          // Get all markers in this cluster
          const markers = cluster.getAllChildMarkers();

          // Find the highest priority contact in the cluster
          let highestPriority = "none";
          const priorityRank = { high: 3, medium: 2, low: 1, none: 0 };

          markers.forEach((marker) => {
            if (marker.options.priority) {
              const priority = marker.options.priority;
              if (priorityRank[priority] > priorityRank[highestPriority]) {
                highestPriority = priority;
              }
            }
          });

          // Get the color for the highest priority
          const priorityColor = getPriorityColor(highestPriority);

          // Create a custom colored cluster icon with stronger styling
          return L.divIcon({
            html: `<div class="custom-cluster-inner" style="
              background-color: ${priorityColor}; 
              color: white; 
              border-radius: 50%; 
              display: flex; 
              align-items: center; 
              justify-content: center; 
              width: 100%; 
              height: 100%;
              font-weight: bold;
              font-size: 14px;
              box-shadow: 0 0 0 4px white, 0 0 0 5px rgba(0,0,0,0.2);
            ">${cluster.getChildCount()}</div>`,
            className: "marker-cluster-custom",
            iconSize: L.point(40, 40),
            iconAnchor: L.point(20, 20),
          });
        },
      });
      map.addLayer(markerClusterRef.current);

      // Group contacts by location
      const groupedContacts = geoContacts.reduce((acc, contact) => {
        const key = `${contact.location.latitude},${contact.location.longitude}`;
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(contact);
        return acc;
      }, {});

      // Create markers for each contact
      Object.entries(groupedContacts).forEach(([key, contacts], index) => {
        const [lat, lng] = key.split(",").map(Number);
        contacts.forEach((contact, i) => {
          const angle = (Math.PI * 2 * i) / contacts.length;
          const offsetDistance = 0.0001; // Adjust this value to increase/decrease the spread
          const newLat = lat + Math.cos(angle) * offsetDistance * (index + 1);
          const newLng = lng + Math.sin(angle) * offsetDistance * (index + 1);

          // Get priority color for the contact
          const priorityColor = getPriorityColor(
            contact.follow_up_priority || "none"
          );

          // Create a custom colored marker
          const markerHtmlStyles = `
            background-color: ${priorityColor};
            width: 2rem;
            height: 2rem;
            display: block;
            left: -1rem;
            top: -1rem;
            position: relative;
            border-radius: 2rem 2rem 0;
            transform: rotate(45deg);
            border: 1px solid #FFFFFF;
            box-shadow: 0 0 2px rgba(0,0,0,0.5);
          `;

          const icon = L.divIcon({
            className: "custom-pin",
            iconAnchor: [0, 24],
            popupAnchor: [0, -36],
            html: `<span style="${markerHtmlStyles}" />`,
          });

          const marker = L.marker([newLat, newLng], {
            icon,
            priority: contact.follow_up_priority || "none", // Store priority in marker options
          });
          const popupContent = `
            <div>
              <h3>${contact.full_name}</h3>
              <p>Email: ${contact.email || "N/A"}</p>
              <p>Phone: ${contact.phone_number || "N/A"}</p>
              <p>Met in: ${contact.location_name || "N/A"}</p>
              <p>Date: ${contact.date_met || "N/A"}</p>
              <p>Priority: ${
                contact.follow_up_priority
                  ? contact.follow_up_priority.charAt(0).toUpperCase() +
                    contact.follow_up_priority.slice(1)
                  : "None"
              }</p>
            </div>
          `;
          marker.bindPopup(popupContent);
          markerClusterRef.current.addLayer(marker);
        });
      });

      // Fit map to show all markers
      if (markerClusterRef.current.getLayers().length > 0) {
        try {
          map.fitBounds(markerClusterRef.current.getBounds(), {
            padding: [50, 50],
          });
        } catch (error) {
          console.error("Error fitting bounds:", error);
        }
      }
    }
  }, [map, geoContacts, markerClusterRef, getPriorityColor]);

  return null;
};

const MapView = ({ contacts, updateContact, getPriorityColor }) => {
  const [geoContacts, setGeoContacts] = useState([]);
  const center = [40, -95]; // Default center of the map
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const mapRef = useRef(null);
  const markerClusterRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);

  const processContacts = useCallback(() => {
    // Filter contacts with valid coordinates
    const withCoordinates = contacts.filter(
      (contact) =>
        contact &&
        contact.location &&
        contact.location.latitude &&
        contact.location.longitude
    );

    setGeoContacts(withCoordinates);
    setIsLoading(false);
  }, [contacts]);

  useEffect(() => {
    setIsLoading(true);
    processContacts();
  }, [processContacts]);

  // Calculate appropriate map height based on viewport
  const mapHeight = isMobile ? "50vh" : "70vh";

  const mapStyle = {
    height: mapHeight,
    width: "100%",
    borderRadius: "8px",
    border: "1px solid rgba(0, 0, 0, 0.12)",
    overflow: "hidden", // Ensure content doesn't overflow
  };

  // Ensure the container has proper dimensions
  const containerStyle = {
    width: "100%",
    height: mapHeight,
    position: "relative",
    marginBottom: "20px",
  };

  // Force map to resize when component mounts or window resizes
  useEffect(() => {
    const handleResize = () => {
      if (mapRef.current) {
        mapRef.current.invalidateSize();
      }
    };

    window.addEventListener("resize", handleResize);

    // Small delay to ensure the map container is fully rendered
    const timeoutId = setTimeout(() => {
      handleResize();
    }, 300);

    return () => {
      window.removeEventListener("resize", handleResize);
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <Box sx={{ width: "100%" }}>
      {/* Map Container with proper dimensions */}
      <Box sx={containerStyle}>
        {isLoading ? (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              bgcolor: "rgba(255, 255, 255, 0.8)",
              zIndex: 1,
              borderRadius: "8px",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <MapContainer
            center={center}
            zoom={isMobile ? 3 : 4}
            style={mapStyle}
            whenCreated={(mapInstance) => {
              // Force resize after map is created
              setTimeout(() => {
                mapInstance.invalidateSize();
              }, 100);
            }}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <MapController
              mapRef={mapRef}
              geoContacts={geoContacts}
              markerClusterRef={markerClusterRef}
              getPriorityColor={getPriorityColor}
            />
          </MapContainer>
        )}
      </Box>
    </Box>
  );
};

export default MapView;
