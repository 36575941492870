import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Container,
  Box,
  Link,
  Button,
  IconButton,
  Drawer,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import bappaLogo from "../../images/bappa-logo.png";

// Default navigation items
const defaultNavItems = [
  { label: "Home", href: "/" },
  { label: "Products", href: "/products" },
  { label: "Pricing", href: "/pricing" },
];

// Default action items
const defaultActionItems = [
  { label: "Login", href: "/log-in", variant: "text" },
  {
    label: "Get Started",
    href: "/get-started",
    variant: "contained",
    sx: {
      bgcolor: "#dc004e",
      textTransform: "none",
      fontSize: "1rem",
      fontWeight: 400,
      px: 3,
      py: 1,
      borderRadius: "8px",
      "&:hover": {
        bgcolor: "#0f0f23",
      },
    },
  },
];

const AppHeader = ({
  logo = bappaLogo,
  navItems = defaultNavItems,
  actionItems = defaultActionItems,
  backgroundColor = "#0f0f23",
  maxWidth = "lg",
}) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  // Handle scroll effect
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <AppBar
        position="fixed"
        elevation={0}
        sx={{
          backdropFilter: isScrolled ? "blur(10px)" : "none",
          transition: "all 0.3s ease",
          borderBottom: isScrolled
            ? "1px solid rgba(255, 255, 255, 0.1)"
            : "none",
          backgroundColor: backgroundColor,
        }}
      >
        <Container maxWidth={maxWidth}>
          <Toolbar
            sx={{
              justifyContent: "space-between",
              height: { xs: "64px", md: "80px" },
              transition: "all 0.3s ease",
              px: { xs: 2, sm: 3, md: 4 },
            }}
          >
            {/* Logo */}
            <Link
              href="/"
              sx={{
                display: "flex",
                alignItems: "center",
                flexGrow: 0,
              }}
            >
              <Typography
                variant="h6"
                component="span"
                sx={{
                  fontWeight: "bold",
                  color: "white",
                  fontSize: { xs: "1.25rem", md: "1.5rem" },
                  display: "flex",
                  alignItems: "center",
                  letterSpacing: "0.02em",
                  textShadow: "0 1px 2px rgba(0,0,0,0.2)",
                }}
              >
                BappaCards{" "}
                <img
                  src={logo}
                  alt="BappaCards"
                  style={{
                    width: "28px",
                    height: "auto",
                    marginLeft: "8px",
                  }}
                />
              </Typography>
            </Link>

            {/* Center Navigation */}
            <Box
              sx={{
                display: { xs: "none", md: "flex" },
                gap: 4,
                alignItems: "center",
                justifyContent: "center",
                flexGrow: 1,
              }}
            >
              {navItems.map((item, index) => (
                <Link
                  key={index}
                  href={item.href}
                  sx={{
                    color: "white",
                    textDecoration: "none",
                    fontSize: "1rem",
                    fontWeight: 400,
                    opacity: 0.9,
                    "&:hover": {
                      opacity: 1,
                    },
                    ...(item.sx || {}),
                  }}
                >
                  {item.label}
                </Link>
              ))}
            </Box>

            {/* Right Side Buttons */}
            <Box
              sx={{
                display: "flex",
                gap: 2,
                alignItems: "center",
                flexGrow: 0,
              }}
            >
              <Box
                sx={{
                  display: { xs: "none", md: "flex" },
                  gap: 2,
                  alignItems: "center",
                }}
              >
                {actionItems.map((item, index) =>
                  item.variant === "text" ? (
                    <Link
                      key={index}
                      href={item.href}
                      sx={{
                        color: "white",
                        textDecoration: "none",
                        fontSize: "1rem",
                        fontWeight: 400,
                        opacity: 0.9,
                        "&:hover": {
                          opacity: 1,
                        },
                        ...(item.sx || {}),
                      }}
                    >
                      {item.label}
                    </Link>
                  ) : (
                    <Button
                      key={index}
                      variant={item.variant || "contained"}
                      href={item.href}
                      sx={item.sx || {}}
                    >
                      {item.label}
                    </Button>
                  )
                )}
              </Box>

              {/* Mobile Menu Button */}
              <IconButton
                sx={{
                  display: { xs: "flex", md: "none" },
                  color: "white",
                }}
                onClick={() => setMobileMenuOpen(true)}
              >
                <MenuIcon />
              </IconButton>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>

      {/* Mobile Menu Drawer */}
      <Drawer
        anchor="right"
        open={mobileMenuOpen}
        onClose={() => setMobileMenuOpen(false)}
        PaperProps={{
          sx: {
            width: "100%",
            bgcolor: "rgba(0, 0, 0, 0.95)",
            backdropFilter: "blur(10px)",
            color: "white",
          },
        }}
      >
        {/* Mobile Menu Content */}
        <Box sx={{ p: 4 }}>
          <Box sx={{ mb: 4 }}>
            <Typography
              variant="h6"
              component="span"
              sx={{ fontWeight: "bold", color: "white" }}
            >
              BappaCards{" "}
              <img
                src={logo}
                alt="BappaCards"
                style={{
                  width: "24px",
                  height: "auto",
                  marginLeft: "4px",
                }}
              />
            </Typography>
          </Box>

          {/* Mobile Navigation Links */}
          <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
            {navItems.map((item, index) => (
              <Link
                key={index}
                href={item.href}
                sx={{
                  color: "white",
                  textDecoration: "none",
                  fontSize: "1.2rem",
                  fontWeight: 400,
                }}
              >
                {item.label}
              </Link>
            ))}

            {/* Add text action items to mobile menu */}
            {actionItems
              .filter((item) => item.variant === "text")
              .map((item, index) => (
                <Link
                  key={`action-${index}`}
                  href={item.href}
                  sx={{
                    color: "white",
                    textDecoration: "none",
                    fontSize: "1.2rem",
                    fontWeight: 400,
                  }}
                >
                  {item.label}
                </Link>
              ))}
          </Box>

          {/* Add button action items to mobile menu */}
          {actionItems
            .filter((item) => item.variant !== "text")
            .map((item, index) => (
              <Button
                key={`action-btn-${index}`}
                variant={item.variant || "contained"}
                fullWidth
                href={item.href}
                sx={{
                  mt: 4,
                  bgcolor: "#dc004e",
                  textTransform: "none",
                  fontSize: "1.1rem",
                  py: 1.5,
                  "&:hover": {
                    bgcolor: "#0f0f23",
                  },
                  ...(item.mobileSx || {}),
                }}
              >
                {item.label}
              </Button>
            ))}
        </Box>
      </Drawer>
    </>
  );
};

export default AppHeader;
