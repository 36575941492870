import React, { useState, useEffect } from "react";
import {
  Container,
  Box,
  Typography,
  Link,
  Button,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import bappaTap from "../../images/bappa-tap.png";
import bappaLogo from "../../images/bappa-logo.png";
import image6 from "../../images/6.jpg";
import card1 from "../../images/card1.jpg";
import personTap from "../../images/personTap.jpg";
import nfcTap from "../../images/nfcTap.jpg";
import "./Lander.css";
import { motion } from "framer-motion";
import { ContactUsModal } from "../../components/ContactUs";
import AppHeader from "../../components/Navigation/AppHeader";

const Lander = () => {
  const [contactUsOpen, setContactUsOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [heroVisible, setHeroVisible] = useState(true);

  // Handle scroll effect for hero animations
  useEffect(() => {
    // Removing the scroll handler that hides content
    // The hero content will now remain visible at all times
    setHeroVisible(true);
  }, []);

  const handleOpenContactUs = () => {
    setContactUsOpen(true);
  };

  const handleCloseContactUs = () => {
    setContactUsOpen(false);
  };

  // Hero animation variants
  const heroTextVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: "easeOut",
      },
    },
  };

  const heroImageVariants = {
    hidden: { opacity: 0, scale: 0.95 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.8,
        delay: 0.2,
        ease: "easeOut",
      },
    },
  };

  return (
    <>
      <AppHeader logo={bappaLogo} backgroundColor="#0f0f23" maxWidth="lg" />

      {/* Enhanced Hero Section with Gradient */}
      <Box
        className="lander-container"
        sx={{
          background: "linear-gradient(135deg, #0f0f23 0%, #1a1a3a 100%)",
          position: "relative",
          overflow: "hidden",
          minHeight: "100vh",
          display: "flex",
          alignItems: "center",
          pt: { xs: 10, md: 10 },
        }}
      >
        {/* Abstract background elements */}
        <Box
          sx={{
            position: "absolute",
            top: "-10%",
            right: "-5%",
            width: "500px",
            height: "500px",
            borderRadius: "50%",
            background:
              "radial-gradient(circle, rgba(220, 0, 78, 0.15) 0%, rgba(220, 0, 78, 0) 70%)",
            filter: "blur(50px)",
            zIndex: 0,
          }}
        />
        <Box
          sx={{
            position: "absolute",
            bottom: "-10%",
            left: "-5%",
            width: "400px",
            height: "400px",
            borderRadius: "50%",
            background:
              "radial-gradient(circle, rgba(150, 50, 220, 0.1) 0%, rgba(150, 50, 220, 0) 70%)",
            filter: "blur(40px)",
            zIndex: 0,
          }}
        />

        <Container maxWidth="lg" sx={{ position: "relative", zIndex: 2 }}>
          <Box
            className="lander-content"
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              alignItems: "center",
              gap: { xs: 8, md: 12 },
              pt: { xs: "120px", md: "120px" },
              pb: { xs: "60px", md: "80px" },
              textAlign: { xs: "center", md: "left" },
            }}
          >
            <motion.div
              className="lander-text"
              initial="hidden"
              animate="visible"
              variants={heroTextVariants}
              sx={{ flex: "1" }}
            >
              <Box
                sx={{
                  display: "inline-block",
                  position: "relative",
                  mb: 4,
                }}
              >
                <Typography
                  variant="h1"
                  className="lander-heading"
                  sx={{
                    fontSize: { xs: "3.5rem", md: "5rem" },
                    fontWeight: "800",
                    mb: 0,
                    color: "white",
                    textShadow: "0 2px 10px rgba(0,0,0,0.2)",
                    lineHeight: "1.1",
                    position: "relative",
                    zIndex: 5,
                    letterSpacing: "-0.02em",
                  }}
                >
                  Make Every{" "}
                  <Box
                    component="span"
                    sx={{
                      display: "inline-block",
                      position: "relative",
                      "&::after": {
                        content: '""',
                        position: "absolute",
                        bottom: "0.1em",
                        left: 0,
                        width: "100%",
                        height: "0.1em",
                        backgroundColor: "#dc004e",
                        opacity: 0.6,
                      },
                    }}
                  >
                    Introduction
                  </Box>
                  <Box
                    component="span"
                    sx={{
                      display: "block",
                      color: "#dc004e",
                      fontWeight: "900",
                    }}
                  >
                    Count
                  </Box>
                </Typography>
                {/* Decorative underline */}
                <Box
                  sx={{
                    position: "absolute",
                    height: "4px",
                    width: "80px",
                    background: "#dc004e",
                    bottom: "-10px",
                    left: { xs: "calc(50% - 40px)", md: 0 },
                  }}
                />
              </Box>

              <Typography
                variant="h5"
                className="lander-subheading"
                sx={{
                  color: "rgba(255,255,255,0.9)",
                  fontWeight: "400",
                  fontSize: { xs: "1.1rem", md: "1.3rem" },
                  lineHeight: "1.5",
                  mb: 5,
                  maxWidth: "600px",
                  mx: { xs: "auto", md: 0 },
                }}
              >
                Effortlessly create, share, and elevate your connections with
                BappaCards—your digital business card for the modern
                professional.
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  gap: 3,
                  mb: 6,
                  justifyContent: { xs: "center", md: "flex-start" },
                  flexWrap: { xs: "wrap", sm: "nowrap" },
                }}
              >
                <Button
                  variant="contained"
                  size="large"
                  sx={{
                    background:
                      "linear-gradient(45deg, #dc004e 30%, #ff3378 90%)",
                    boxShadow: "0 8px 25px rgba(220, 0, 78, 0.25)",
                    "&:hover": {
                      background:
                        "linear-gradient(45deg, #c80046 30%, #e92e6c 90%)",
                      boxShadow: "0 8px 30px rgba(220, 0, 78, 0.35)",
                      transform: "translateY(-2px)",
                    },
                    padding: "12px 40px",
                    fontSize: "1.1rem",
                    fontWeight: "600",
                    borderRadius: "12px",
                    transition: "all 0.3s ease",
                    textTransform: "none",
                  }}
                  onClick={() => {
                    window.location.href = "/sign-up";
                  }}
                >
                  Try Now
                </Button>
                <Button
                  variant="outlined"
                  size="large"
                  sx={{
                    borderColor: "rgba(255,255,255,0.5)",
                    color: "white",
                    "&:hover": {
                      borderColor: "white",
                      backgroundColor: "rgba(255,255,255,0.05)",
                      transform: "translateY(-2px)",
                    },
                    padding: "12px 40px",
                    fontSize: "1.1rem",
                    fontWeight: "500",
                    borderRadius: "12px",
                    transition: "all 0.3s ease",
                    textTransform: "none",
                  }}
                  onClick={handleOpenContactUs}
                >
                  Contact Us
                </Button>
              </Box>

              <Box
                className="feature-highlights"
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: { xs: 2, md: 4 },
                  justifyContent: { xs: "center", md: "flex-start" },
                }}
              >
                {[
                  { text: "7-day free trial of Bappa Premium", icon: "🎁" },
                ].map((feature) => (
                  <Box
                    key={feature.text}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1.5,
                      color: "rgba(255,255,255,0.9)",
                      background: "rgba(255,255,255,0.05)",
                      backdropFilter: "blur(10px)",
                      padding: "10px 16px",
                      borderRadius: "10px",
                      border: "1px solid rgba(255,255,255,0.1)",
                      fontSize: "0.95rem",
                      fontWeight: "500",
                      transition: "all 0.3s ease",
                      "&:hover": {
                        background: "rgba(255,255,255,0.1)",
                        transform: "translateY(-2px)",
                        boxShadow: "0 5px 15px rgba(0,0,0,0.1)",
                      },
                    }}
                  >
                    <span>{feature.icon}</span>
                    {feature.text}
                  </Box>
                ))}
              </Box>
            </motion.div>

            <motion.div
              className="lander-image"
              initial="hidden"
              animate="visible"
              variants={heroImageVariants}
              sx={{ flex: "1" }}
            >
              <Box
                sx={{
                  position: "relative",
                  width: "100%",
                  maxWidth: "500px",
                  mx: "auto",
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: "-15%",
                    left: "-15%",
                    right: "-15%",
                    bottom: "-15%",
                    background:
                      "radial-gradient(50% 50% at 50% 50%, rgba(255, 51, 85, 0.15) 0%, rgba(33, 36, 61, 0) 100%)",
                    filter: "blur(40px)",
                    borderRadius: "60% 40% 60% 40% / 60% 40% 60% 40%",
                    animation: "morph 8s ease-in-out infinite",
                    zIndex: -1,
                  }}
                />
                <img
                  src={bappaTap}
                  alt="BappaCards Digital Business Card Demo"
                  style={{
                    width: "100%",
                    height: "auto",
                    borderRadius: "20px",
                    boxShadow: "0 20px 80px rgba(0, 0, 0, 0.3)",
                  }}
                />
              </Box>
            </motion.div>
          </Box>
        </Container>
      </Box>

      {/* Enhanced Step 1 Section */}
      <Box
        sx={{
          position: "relative",
          py: { xs: 10, md: 16 },
          bgcolor: "white",
          color: "#333",
          overflow: "hidden",
        }}
      >
        {/* Background decorative elements */}
        <Box
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            width: "40%",
            height: "100%",
            background:
              "linear-gradient(135deg, rgba(15, 15, 35, 0.03) 0%, rgba(15, 15, 35, 0) 100%)",
            borderRadius: "0 0 0 100%",
            zIndex: 0,
          }}
        />

        <Container maxWidth="lg" sx={{ position: "relative", zIndex: 1 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              mb: 8,
            }}
          >
            <Box
              sx={{
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
                width: "60px",
                height: "60px",
                borderRadius: "50%",
                background: "linear-gradient(135deg, #0f0f23 0%, #1a1a3a 100%)",
                color: "white",
                fontWeight: "bold",
                fontSize: "1.5rem",
                mb: 2,
                boxShadow: "0 4px 20px rgba(15, 15, 35, 0.2)",
              }}
            >
              1
            </Box>
            <Typography
              variant="h2"
              sx={{
                fontSize: { xs: "2.5rem", md: "3.5rem" },
                fontWeight: "bold",
                mb: 2,
                textAlign: "center",
                background: "linear-gradient(90deg, #0f0f23 30%, #333 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              Design Your Perfect Card
            </Typography>
            <Typography
              variant="h6"
              sx={{
                color: "#666",
                maxWidth: "800px",
                textAlign: "center",
                mb: 4,
                fontSize: { xs: "1rem", md: "1.1rem" },
                lineHeight: 1.6,
              }}
            >
              Create a custom digital business card that perfectly represents
              your brand and style. Complete customization puts you in control
              of your professional image.
            </Typography>
          </Box>

          <Box
            sx={{
              position: "relative",
              height: { xs: "400px", md: "600px" },
              borderRadius: "20px",
              overflow: "hidden",
              boxShadow: "0 20px 80px rgba(0, 0, 0, 0.15)",
            }}
          >
            {/* Video overlay with gradient */}
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background:
                  "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 50%, rgba(0,0,0,0.1) 100%)",
                zIndex: 1,
              }}
            />

            {/* Background video */}
            <Box
              component="video"
              autoPlay
              muted
              loop
              playsInline
              sx={{
                position: "absolute",
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            >
              <source
                src={
                  "https://res.cloudinary.com/dn2gadqqz/video/upload/v1736711728/createCard_ggbg9a.mp4"
                }
                type="video/mp4"
              />
            </Box>

            {/* Feature highlights overlay */}
            <Box
              sx={{
                position: "absolute",
                bottom: 0,
                left: 0,
                right: 0,
                p: { xs: 3, md: 5 },
                zIndex: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 2,
                  justifyContent: { xs: "center", md: "flex-start" },
                }}
              >
                {[
                  "Complete brand control",
                  "Unlimited design options",
                  "Easy to update anytime",
                  "Professional templates",
                ].map((feature) => (
                  <Box
                    key={feature}
                    sx={{
                      bgcolor: "rgba(255,255,255,0.9)",
                      color: "#0f0f23",
                      py: 1,
                      px: 2,
                      borderRadius: "8px",
                      fontSize: { xs: "0.8rem", md: "0.9rem" },
                      fontWeight: "600",
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <Box
                      sx={{
                        width: "8px",
                        height: "8px",
                        borderRadius: "50%",
                        bgcolor: "#dc004e",
                      }}
                    />
                    {feature}
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>

      {/* Enhanced Step 2 Section */}
      <Box
        sx={{
          position: "relative",
          minHeight: { xs: "80vh", md: "100vh" },
          width: "100%",
          overflow: "hidden",
          display: "flex",
          alignItems: "center",
          background: "linear-gradient(135deg, #0f0f23 0%, #1a1a3a 100%)",
        }}
      >
        {/* Background decorative elements */}
        <Box
          sx={{
            position: "absolute",
            top: "30%",
            left: "-5%",
            width: "300px",
            height: "300px",
            borderRadius: "50%",
            background:
              "radial-gradient(circle, rgba(220, 0, 78, 0.1) 0%, rgba(220, 0, 78, 0) 70%)",
            filter: "blur(50px)",
            zIndex: 0,
          }}
        />

        {/* Video Background with reduced opacity */}
        <Box
          component="video"
          autoPlay
          muted
          loop
          playsInline
          sx={{
            position: "absolute",
            width: "100%",
            height: "100%",
            objectFit: "cover",
            opacity: 0.4,
          }}
        >
          <source
            src={
              "https://res.cloudinary.com/dn2gadqqz/video/upload/v1736711728/bappaTap_to0wao.mp4"
            }
            type="video/mp4"
          />
        </Box>

        {/* Content Overlay */}
        <Container
          maxWidth="lg"
          sx={{
            position: "relative",
            zIndex: 2,
            py: { xs: 10, md: 0 },
          }}
        >
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true, amount: 0.3 }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                mb: 5,
              }}
            >
              <Box
                sx={{
                  display: "inline-flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "60px",
                  height: "60px",
                  borderRadius: "50%",
                  background:
                    "linear-gradient(135deg, #dc004e 0%, #ff3378 100%)",
                  color: "white",
                  fontWeight: "bold",
                  fontSize: "1.5rem",
                  mb: 2,
                  boxShadow: "0 4px 20px rgba(220, 0, 78, 0.3)",
                }}
              >
                2
              </Box>
            </Box>

            <Typography
              variant="h2"
              sx={{
                color: "white",
                fontSize: { xs: "2.5rem", md: "3.5rem" },
                fontWeight: "bold",
                lineHeight: 1.2,
                textAlign: "center",
                mb: 3,
                maxWidth: "900px",
                mx: "auto",
              }}
            >
              Share Effortlessly, Connect Instantly
            </Typography>
            <Typography
              variant="h6"
              sx={{
                color: "rgba(255,255,255,0.8)",
                fontWeight: "500",
                lineHeight: 1.6,
                textAlign: "center",
                maxWidth: "800px",
                mx: "auto",
                mb: 8,
                fontSize: { xs: "1rem", md: "1.1rem" },
              }}
            >
              Easily share your card with a tap or scan, and make meaningful
              connections wherever you go. No more fumbling with paper cards or
              manually typing contact details.
            </Typography>

            {/* Feature cards */}
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: { xs: "1fr", md: "repeat(3, 1fr)" },
                gap: 4,
                mt: 4,
              }}
            >
              {[
                {
                  title: "NFC Technology",
                  description:
                    "Just tap your card to any smartphone to instantly share your contact details",
                  icon: "📱",
                },
                {
                  title: "QR Code Sharing",
                  description:
                    "Allow others to scan your unique QR code for a contactless exchange of information",
                  icon: "🔄",
                },
                {
                  title: "Digital & Physical",
                  description:
                    "Perfect blend of physical presence with a premium card backed by powerful digital features",
                  icon: "💼",
                },
              ].map((feature, index) => (
                <motion.div
                  key={feature.title}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                  viewport={{ once: true, amount: 0.3 }}
                >
                  <Box
                    sx={{
                      bgcolor: "rgba(255,255,255,0.05)",
                      backdropFilter: "blur(10px)",
                      borderRadius: "16px",
                      p: 4,
                      height: "100%",
                      border: "1px solid rgba(255,255,255,0.1)",
                      transition: "all 0.3s ease",
                      "&:hover": {
                        transform: "translateY(-8px)",
                        boxShadow: "0 12px 30px rgba(0,0,0,0.2)",
                        bgcolor: "rgba(255,255,255,0.1)",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        fontSize: "2.5rem",
                        mb: 2,
                      }}
                    >
                      {feature.icon}
                    </Box>
                    <Typography
                      variant="h5"
                      sx={{
                        color: "white",
                        fontWeight: "bold",
                        mb: 2,
                      }}
                    >
                      {feature.title}
                    </Typography>
                    <Typography
                      sx={{
                        color: "rgba(255,255,255,0.7)",
                        fontSize: "1rem",
                        lineHeight: 1.6,
                      }}
                    >
                      {feature.description}
                    </Typography>
                  </Box>
                </motion.div>
              ))}
            </Box>
          </motion.div>
        </Container>
      </Box>

      {/* Enhanced Features Section */}
      <Box
        sx={{
          py: { xs: 12, md: 16 },
          bgcolor: "white",
          position: "relative",
          overflow: "hidden",
        }}
      >
        {/* Background decorative elements */}
        <Box
          sx={{
            position: "absolute",
            top: "10%",
            left: "5%",
            width: "500px",
            height: "500px",
            borderRadius: "50%",
            background:
              "radial-gradient(circle, rgba(15, 15, 35, 0.03) 0%, rgba(15, 15, 35, 0) 70%)",
            filter: "blur(50px)",
            zIndex: 0,
          }}
        />

        <Container maxWidth="lg" sx={{ position: "relative", zIndex: 1 }}>
          <Box sx={{ mb: 12 }}>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.7 }}
              viewport={{ once: true, amount: 0.3 }}
            >
              <Typography
                variant="h6"
                sx={{
                  color: "#dc004e",
                  fontWeight: "600",
                  textTransform: "uppercase",
                  letterSpacing: "1.5px",
                  mb: 2,
                  textAlign: "center",
                }}
              >
                Powerful Features
              </Typography>
              <Typography
                variant="h2"
                sx={{
                  fontSize: { xs: "2.5rem", md: "3.5rem" },
                  fontWeight: "800",
                  mb: 3,
                  lineHeight: 1.2,
                  textAlign: "center",
                  background: "linear-gradient(90deg, #0f0f23 30%, #333 100%)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
              >
                Your Own Personal Relationship Manager
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  color: "#666",
                  textAlign: "center",
                  maxWidth: "800px",
                  mx: "auto",
                  mb: 5,
                  fontSize: "1.1rem",
                  lineHeight: 1.6,
                }}
              >
                BappaCards goes beyond being just a digital business card. It's
                a comprehensive relationship management tool designed to help
                you build and maintain meaningful professional connections.
              </Typography>
            </motion.div>
          </Box>

          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: { xs: "1fr", md: "repeat(2, 1fr)" },
              gap: { xs: 6, md: 8 },
            }}
          >
            {[
              {
                title: "Real-Time Updates",
                description:
                  "Update your profile anytime with a few clicks—no more outdated cards. Keep your contact information, social links, and portfolio always current and relevant.",
                image: image6,
                icon: "🔄",
              },
              {
                title: "Brand Customization",
                description:
                  "Customize your digital business card with your logo, brand colors, and unique design elements. Create a card that truly represents your personal or company brand identity.",
                image: card1,
                icon: "🎨",
              },
              {
                title: "Contact Exchange",
                description:
                  "Effortlessly capture new connections and store their details with a simple tap or scan. Build your network without the hassle of manual data entry or lost contact information.",
                image: nfcTap,
                icon: "👥",
              },
              {
                title: "CRM Integration",
                description:
                  "Easily export contacts as a CSV file and seamlessly integrate them into your existing CRM systems for efficient follow-ups and relationship management.",
                image: personTap,
                icon: "📊",
              },
            ].map((feature, index) => (
              <motion.div
                key={feature.title}
                initial={{ opacity: 0, y: 30 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                viewport={{ once: true, amount: 0.3 }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", md: "row" },
                    gap: 4,
                    height: "100%",
                    p: { xs: 3, md: 4 },
                    borderRadius: "20px",
                    background: "linear-gradient(145deg, #ffffff, #f5f5f5)",
                    boxShadow: "8px 8px 30px #e6e6e6, -8px -8px 30px #ffffff",
                    transition: "all 0.3s ease",
                    overflow: "hidden",
                    position: "relative",
                    "&:hover": {
                      transform: "translateY(-8px)",
                      boxShadow:
                        "12px 12px 40px #e0e0e0, -12px -12px 40px #ffffff",
                      "& .feature-image": {
                        transform: "scale(1.05)",
                      },
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      flex: 1,
                      zIndex: 2,
                    }}
                  >
                    <Box
                      sx={{
                        fontSize: "2.5rem",
                        mb: 2,
                        display: "inline-block",
                        background:
                          "linear-gradient(135deg, #0f0f23 0%, #1a1a3a 100%)",
                        borderRadius: "12px",
                        p: 1.5,
                        boxShadow: "0 8px 20px rgba(15, 15, 35, 0.15)",
                        color: "white",
                        width: "60px",
                        height: "60px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {feature.icon}
                    </Box>
                    <Typography
                      variant="h4"
                      sx={{
                        fontSize: { xs: "1.8rem", md: "2rem" },
                        fontWeight: "700",
                        mb: 2,
                        color: "#0f0f23",
                      }}
                    >
                      {feature.title}
                    </Typography>
                    <Typography
                      sx={{
                        color: "#666",
                        mb: 3,
                        lineHeight: 1.7,
                        fontSize: "1rem",
                      }}
                    >
                      {feature.description}
                    </Typography>
                    <Box sx={{ mt: "auto" }}>
                      <Button
                        variant="text"
                        sx={{
                          color: "#dc004e",
                          p: 0,
                          fontWeight: "600",
                          textTransform: "none",
                          fontSize: "1rem",
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                          "&:hover": {
                            background: "transparent",
                            gap: 1.5,
                            color: "#a30039",
                          },
                          transition: "all 0.3s ease",
                        }}
                      >
                        Learn more
                        <span>→</span>
                      </Button>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      width: { xs: "100%", md: "200px" },
                      height: { xs: "200px", md: "auto" },
                      borderRadius: "12px",
                      overflow: "hidden",
                      boxShadow: "0 10px 30px rgba(0,0,0,0.1)",
                      order: { xs: -1, md: 1 },
                      mb: { xs: 3, md: 0 },
                    }}
                  >
                    <img
                      src={feature.image}
                      alt={feature.title}
                      className="feature-image"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        transition: "transform 0.5s ease",
                      }}
                    />
                  </Box>
                </Box>
              </motion.div>
            ))}
          </Box>

          {/* Additional platform features in a 3-column grid */}
          <Box sx={{ mt: 12 }}>
            <Typography
              variant="h3"
              sx={{
                fontSize: { xs: "2rem", md: "2.5rem" },
                fontWeight: "700",
                mb: 6,
                textAlign: "center",
                color: "#0f0f23",
              }}
            >
              More Powerful Capabilities
            </Typography>

            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: {
                  xs: "1fr",
                  sm: "repeat(2, 1fr)",
                  md: "repeat(3, 1fr)",
                },
                gap: 4,
              }}
            >
              {[
                {
                  title: "Analytics Dashboard",
                  description:
                    "Track how many times your card is viewed and measure engagement with your profile.",
                  icon: "📈",
                },
                {
                  title: "Social Media Integration",
                  description:
                    "Connect all your social profiles and online presences in one centralized location.",
                  icon: "📱",
                },
                {
                  title: "Custom Fields",
                  description:
                    "Add custom fields and information tailored to your specific industry or needs.",
                  icon: "🔧",
                },
              ].map((feature, index) => (
                <motion.div
                  key={feature.title}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.4, delay: index * 0.1 }}
                  viewport={{ once: true, amount: 0.3 }}
                >
                  <Box
                    sx={{
                      p: 3,
                      height: "100%",
                      borderRadius: "16px",
                      border: "1px solid rgba(0,0,0,0.05)",
                      transition: "all 0.3s ease",
                      background: "white",
                      "&:hover": {
                        boxShadow: "0 10px 30px rgba(0,0,0,0.05)",
                        transform: "translateY(-5px)",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        fontSize: "2rem",
                        mb: 2,
                      }}
                    >
                      {feature.icon}
                    </Box>
                    <Typography
                      variant="h5"
                      sx={{
                        fontWeight: "600",
                        mb: 1.5,
                        color: "#0f0f23",
                      }}
                    >
                      {feature.title}
                    </Typography>
                    <Typography
                      sx={{
                        color: "#666",
                        lineHeight: 1.6,
                      }}
                    >
                      {feature.description}
                    </Typography>
                  </Box>
                </motion.div>
              ))}
            </Box>

            {/* Call to action button */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mt: 10,
              }}
            >
              <motion.div
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.98 }}
              >
                <Button
                  variant="contained"
                  size="large"
                  sx={{
                    background:
                      "linear-gradient(45deg, #0f0f23 30%, #1a1a3a 90%)",
                    boxShadow: "0 8px 25px rgba(15, 15, 35, 0.25)",
                    "&:hover": {
                      background:
                        "linear-gradient(45deg, #0c0c1e 30%, #15152e 90%)",
                      boxShadow: "0 8px 30px rgba(15, 15, 35, 0.35)",
                    },
                    padding: "14px 48px",
                    fontSize: "1.1rem",
                    fontWeight: "600",
                    borderRadius: "12px",
                    transition: "all 0.3s ease",
                    textTransform: "none",
                  }}
                  onClick={() => {
                    window.location.href =
                      "https://shop.bappacards.com/collections/all";
                  }}
                >
                  Explore All Features
                </Button>
              </motion.div>
            </Box>
          </Box>
        </Container>
      </Box>

      {/* Enhanced Pricing Plans Section */}
      <Box
        sx={{
          py: { xs: 12, md: 16 },
          background: "linear-gradient(135deg, #f6f6f8 0%, #f0f0f5 100%)",
          position: "relative",
          overflow: "hidden",
        }}
      >
        {/* Decorative background elements */}
        <Box
          sx={{
            position: "absolute",
            top: "20%",
            right: "-10%",
            width: "400px",
            height: "400px",
            borderRadius: "50%",
            background:
              "radial-gradient(circle, rgba(15, 15, 35, 0.04) 0%, rgba(15, 15, 35, 0) 70%)",
            filter: "blur(50px)",
            zIndex: 0,
          }}
        />
        <Box
          sx={{
            position: "absolute",
            bottom: "-5%",
            left: "-5%",
            width: "300px",
            height: "300px",
            borderRadius: "50%",
            background:
              "radial-gradient(circle, rgba(220, 0, 78, 0.05) 0%, rgba(220, 0, 78, 0) 70%)",
            filter: "blur(60px)",
            zIndex: 0,
          }}
        />

        <Container maxWidth="lg" sx={{ position: "relative", zIndex: 1 }}>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.7 }}
            viewport={{ once: true, amount: 0.3 }}
          >
            <Box sx={{ textAlign: "center", mb: 10 }}>
              <Typography
                variant="h6"
                sx={{
                  color: "#dc004e",
                  fontWeight: "600",
                  textTransform: "uppercase",
                  letterSpacing: "1.5px",
                  mb: 2,
                }}
              >
                Pricing
              </Typography>
              <Typography
                variant="h2"
                sx={{
                  fontSize: { xs: "2.5rem", md: "3.5rem" },
                  fontWeight: "800",
                  mb: 3,
                  background: "linear-gradient(90deg, #0f0f23 30%, #333 100%)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
              >
                Plans for Professionals at Every Scale
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  color: "#666",
                  maxWidth: "800px",
                  mx: "auto",
                  mb: 2,
                  fontSize: "1.1rem",
                  lineHeight: 1.6,
                }}
              >
                Choose the perfect plan that fits your needs - whether you're an
                individual professional, growing team, or enterprise
                organization.
              </Typography>
            </Box>

            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: { xs: "1fr", md: "repeat(3, 1fr)" },
                gap: { xs: 4, md: 6 },
                alignItems: "stretch",
              }}
            >
              {/* Individual Plan */}
              <motion.div
                whileHover={{ y: -12 }}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
                viewport={{ once: true, amount: 0.3 }}
              >
                <Box
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    borderRadius: "24px",
                    background: "white",
                    overflow: "hidden",
                    boxShadow: "0 15px 50px rgba(0,0,0,0.05)",
                    transition: "all 0.3s ease",
                    border: "1px solid rgba(0,0,0,0.03)",
                    "&:hover": {
                      boxShadow: "0 25px 60px rgba(0,0,0,0.1)",
                    },
                  }}
                >
                  <Box
                    sx={{
                      p: 4,
                      borderBottom: "1px solid rgba(0,0,0,0.05)",
                      background:
                        "linear-gradient(135deg, rgba(0,0,0,0.01) 0%, rgba(0,0,0,0.02) 100%)",
                    }}
                  >
                    <Typography
                      variant="h3"
                      sx={{
                        fontSize: "2rem",
                        fontWeight: "700",
                        color: "#0f0f23",
                        mb: 1,
                      }}
                    >
                      For Individuals
                    </Typography>
                    <Typography
                      sx={{
                        color: "#dc004e",
                        fontWeight: "600",
                        mb: 1,
                        fontSize: "1.1rem",
                      }}
                    >
                      Your Personal Brand, Perfected
                    </Typography>
                  </Box>

                  <Box sx={{ p: 4, flexGrow: 1 }}>
                    <Typography
                      sx={{
                        fontSize: "1rem",
                        color: "#666",
                        mb: 4,
                        lineHeight: 1.6,
                      }}
                    >
                      Stand out with a sleek, digital business card that makes
                      sharing your details seamless and memorable. Leave a
                      lasting impression and grow your network effortlessly.
                    </Typography>

                    <Box sx={{ mb: 4 }}>
                      {[
                        "Unlimited digital card shares",
                        "Basic analytics",
                        "Custom branding options",
                        "Social media integration",
                      ].map((feature) => (
                        <Box
                          key={feature}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 2,
                            mb: 2,
                          }}
                        >
                          <Box
                            sx={{
                              width: "20px",
                              height: "20px",
                              borderRadius: "50%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              backgroundColor: "rgba(220, 0, 78, 0.1)",
                              color: "#dc004e",
                              fontSize: "0.8rem",
                              fontWeight: "bold",
                              flexShrink: 0,
                            }}
                          >
                            ✓
                          </Box>
                          <Typography
                            sx={{ color: "#444", fontSize: "0.95rem" }}
                          >
                            {feature}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  </Box>

                  <Box sx={{ p: 4, pt: 0, mt: "auto" }}>
                    <Button
                      variant="contained"
                      fullWidth
                      sx={{
                        background:
                          "linear-gradient(45deg, #dc004e 30%, #ff3378 90%)",
                        boxShadow: "0 6px 20px rgba(220, 0, 78, 0.25)",
                        py: 1.5,
                        textTransform: "none",
                        fontSize: "1.1rem",
                        fontWeight: "600",
                        borderRadius: "12px",
                        "&:hover": {
                          background:
                            "linear-gradient(45deg, #c80046 30%, #e92e6c 90%)",
                          boxShadow: "0 8px 25px rgba(220, 0, 78, 0.3)",
                          transform: "translateY(-3px)",
                        },
                        transition: "all 0.3s ease",
                      }}
                      onClick={() => {
                        window.location.href = "/sign-up";
                      }}
                    >
                      Try for free
                    </Button>
                    <Typography
                      sx={{
                        fontSize: "0.85rem",
                        color: "#666",
                        textAlign: "center",
                        mt: 2,
                      }}
                    >
                      7-day free trial of Bappa Premium
                    </Typography>
                  </Box>
                </Box>
              </motion.div>

              {/* Teams Plan */}
              <motion.div
                whileHover={{ y: -12 }}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{
                  duration: 0.3,
                  delay: 0.1,
                  hover: { duration: 0.3 },
                }}
                viewport={{ once: true, amount: 0.3 }}
              >
                <Box
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    borderRadius: "24px",
                    background: "white",
                    overflow: "hidden",
                    boxShadow: "0 15px 50px rgba(0,0,0,0.05)",
                    transition: "all 0.3s ease",
                    border: "1px solid rgba(0,0,0,0.03)",
                    transform: { xs: "scale(1)", md: "scale(1.05)" },
                    zIndex: 1,
                    "&:hover": {
                      boxShadow: "0 25px 60px rgba(0,0,0,0.1)",
                    },
                  }}
                >
                  <Box
                    sx={{
                      p: 4,
                      borderBottom: "1px solid rgba(0,0,0,0.05)",
                      background:
                        "linear-gradient(135deg, #8B3DFF 0%, #A97FFF 100%)",
                      color: "white",
                    }}
                  >
                    <Box
                      sx={{
                        display: "inline-block",
                        px: 2,
                        py: 0.5,
                        bgcolor: "rgba(255,255,255,0.2)",
                        borderRadius: "20px",
                        fontSize: "0.8rem",
                        fontWeight: "bold",
                        mb: 2,
                      }}
                    >
                      Most Popular
                    </Box>
                    <Typography
                      variant="h3"
                      sx={{
                        fontSize: "2rem",
                        fontWeight: "700",
                        mb: 1,
                      }}
                    >
                      For Teams
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: "600",
                        mb: 1,
                        fontSize: "1.1rem",
                      }}
                    >
                      Equip Your Team to Win
                    </Typography>
                  </Box>

                  <Box sx={{ p: 4, flexGrow: 1 }}>
                    <Typography
                      sx={{
                        fontSize: "1rem",
                        color: "#666",
                        mb: 4,
                        lineHeight: 1.6,
                      }}
                    >
                      Empower your team to represent your brand with consistency
                      and impact. Track performance, streamline networking at
                      events, and turn every interaction into an opportunity.
                    </Typography>

                    <Box sx={{ mb: 4 }}>
                      {[
                        "Team admin dashboard",
                        "Advanced analytics",
                        "Branded card templates",
                        "Contact management system",
                        "Custom integrations",
                      ].map((feature) => (
                        <Box
                          key={feature}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 2,
                            mb: 2,
                          }}
                        >
                          <Box
                            sx={{
                              width: "20px",
                              height: "20px",
                              borderRadius: "50%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              backgroundColor: "rgba(139, 61, 255, 0.1)",
                              color: "#8B3DFF",
                              fontSize: "0.8rem",
                              fontWeight: "bold",
                              flexShrink: 0,
                            }}
                          >
                            ✓
                          </Box>
                          <Typography
                            sx={{ color: "#444", fontSize: "0.95rem" }}
                          >
                            {feature}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  </Box>

                  <Box sx={{ p: 4, pt: 0, mt: "auto" }}>
                    <Button
                      variant="contained"
                      fullWidth
                      sx={{
                        background:
                          "linear-gradient(45deg, #8B3DFF 30%, #A97FFF 90%)",
                        boxShadow: "0 6px 20px rgba(139, 61, 255, 0.25)",
                        py: 1.5,
                        textTransform: "none",
                        fontSize: "1.1rem",
                        fontWeight: "600",
                        borderRadius: "12px",
                        "&:hover": {
                          background:
                            "linear-gradient(45deg, #7835D8 30%, #9E78F5 90%)",
                          boxShadow: "0 8px 25px rgba(139, 61, 255, 0.3)",
                          transform: "translateY(-3px)",
                        },
                        transition: "all 0.3s ease",
                      }}
                      onClick={handleOpenContactUs}
                    >
                      Contact Sales
                    </Button>
                    <Typography
                      sx={{
                        fontSize: "0.85rem",
                        color: "#666",
                        textAlign: "center",
                        mt: 2,
                      }}
                    >
                      Custom pricing based on team size
                    </Typography>
                  </Box>
                </Box>
              </motion.div>

              {/* Enterprise Plan */}
              <motion.div
                whileHover={{ y: -12 }}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{
                  duration: 0.3,
                  delay: 0.2,
                  hover: { duration: 0.3 },
                }}
                viewport={{ once: true, amount: 0.3 }}
              >
                <Box
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    borderRadius: "24px",
                    background:
                      "linear-gradient(135deg, #0f0f23 0%, #1a1a3a 100%)",
                    overflow: "hidden",
                    boxShadow: "0 15px 50px rgba(0,0,0,0.15)",
                    transition: "all 0.3s ease",
                    "&:hover": {
                      boxShadow: "0 25px 60px rgba(0,0,0,0.2)",
                    },
                  }}
                >
                  <Box
                    sx={{
                      p: 4,
                      borderBottom: "1px solid rgba(255,255,255,0.1)",
                    }}
                  >
                    <Typography
                      variant="h3"
                      sx={{
                        fontSize: "2rem",
                        fontWeight: "700",
                        color: "white",
                        mb: 1,
                      }}
                    >
                      For Enterprises
                    </Typography>
                    <Typography
                      sx={{
                        color: "#dc004e",
                        fontWeight: "600",
                        mb: 1,
                        fontSize: "1.1rem",
                      }}
                    >
                      Scalable Organization Solutions
                    </Typography>
                  </Box>

                  <Box sx={{ p: 4, flexGrow: 1 }}>
                    <Typography
                      sx={{
                        fontSize: "1rem",
                        color: "rgba(255,255,255,0.8)",
                        mb: 4,
                        lineHeight: 1.6,
                      }}
                    >
                      Take control with a secure, customizable platform designed
                      for large organizations. Manage teams, track ROI, and
                      integrate seamlessly with your existing CRM to drive
                      measurable results.
                    </Typography>

                    <Box sx={{ mb: 4 }}>
                      {[
                        "Enterprise admin portal",
                        "Advanced security features",
                        "White-label options",
                        "Priority support",
                      ].map((feature) => (
                        <Box
                          key={feature}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 2,
                            mb: 2,
                          }}
                        >
                          <Box
                            sx={{
                              width: "20px",
                              height: "20px",
                              borderRadius: "50%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              backgroundColor: "rgba(255, 255, 255, 0.1)",
                              color: "white",
                              fontSize: "0.8rem",
                              fontWeight: "bold",
                              flexShrink: 0,
                            }}
                          >
                            ✓
                          </Box>
                          <Typography
                            sx={{
                              color: "rgba(255,255,255,0.8)",
                              fontSize: "0.95rem",
                            }}
                          >
                            {feature}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  </Box>

                  <Box sx={{ p: 4, pt: 0, mt: "auto" }}>
                    <Button
                      variant="contained"
                      fullWidth
                      sx={{
                        backgroundColor: "white",
                        color: "#0f0f23",
                        py: 1.5,
                        textTransform: "none",
                        fontSize: "1.1rem",
                        fontWeight: "600",
                        borderRadius: "12px",
                        "&:hover": {
                          backgroundColor: "rgba(255,255,255,0.9)",
                          transform: "translateY(-3px)",
                        },
                        transition: "all 0.3s ease",
                      }}
                      onClick={handleOpenContactUs}
                    >
                      Contact Sales
                    </Button>
                    <Typography
                      sx={{
                        fontSize: "0.85rem",
                        color: "rgba(255,255,255,0.6)",
                        textAlign: "center",
                        mt: 2,
                      }}
                    >
                      Custom enterprise-grade solutions
                    </Typography>
                  </Box>
                </Box>
              </motion.div>
            </Box>
          </motion.div>
        </Container>
      </Box>

      {/* Enhanced Stats Section */}
      <Box
        sx={{
          py: { xs: 12, md: 16 },
          background: "white",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <Container maxWidth="lg">
          <Box sx={{ textAlign: "center", mb: 8 }}>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.7 }}
              viewport={{ once: true, amount: 0.3 }}
            >
              <Typography
                variant="h6"
                sx={{
                  color: "#dc004e",
                  fontWeight: "600",
                  textTransform: "uppercase",
                  letterSpacing: "1.5px",
                  mb: 2,
                }}
              >
                Global Impact
              </Typography>
              <Typography
                variant="h2"
                sx={{
                  fontSize: { xs: "2.5rem", md: "3.5rem" },
                  fontWeight: "800",
                  mb: 3,
                  background: "linear-gradient(90deg, #0f0f23 30%, #333 100%)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
              >
                Who's Using BappaCards?
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  color: "#666",
                  maxWidth: "800px",
                  mx: "auto",
                  fontSize: "1.1rem",
                  lineHeight: 1.6,
                }}
              >
                Join professionals across the globe who use BappaCards to make
                meaningful connections and drive real business results.
              </Typography>
            </motion.div>
          </Box>

          {/* Stats Cards */}
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: { xs: "1fr", sm: "repeat(3, 1fr)" },
              gap: 4,
            }}
          >
            {[
              {
                label: "Countries",
                value: "30+",
                icon: "🌍",
                color: "#4CAF50",
              },
              { label: "Cities", value: "200+", icon: "🏙️", color: "#2196F3" },
              {
                label: "Daily Taps",
                value: "500+",
                icon: "👆",
                color: "#dc004e",
              },
            ].map((stat, index) => (
              <motion.div
                key={stat.label}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                viewport={{ once: true, amount: 0.3 }}
              >
                <Box
                  sx={{
                    textAlign: "center",
                    p: 5,
                    borderRadius: "20px",
                    height: "100%",
                    background: "linear-gradient(145deg, #ffffff, #f5f5f5)",
                    boxShadow: "8px 8px 30px #e6e6e6, -8px -8px 30px #ffffff",
                    transition: "all 0.3s ease",
                    "&:hover": {
                      transform: "translateY(-10px)",
                      boxShadow:
                        "12px 12px 40px #e0e0e0, -12px -12px 40px #ffffff",
                    },
                  }}
                >
                  <Box
                    sx={{
                      fontSize: "3rem",
                      mb: 3,
                    }}
                  >
                    {stat.icon}
                  </Box>
                  <Typography
                    variant="h3"
                    sx={{
                      fontWeight: "bold",
                      fontSize: "3.5rem",
                      color: stat.color,
                      mb: 1,
                    }}
                  >
                    {stat.value}
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#666",
                      fontWeight: "500",
                    }}
                  >
                    {stat.label}
                  </Typography>
                </Box>
              </motion.div>
            ))}
          </Box>

          {/* Testimonials or Additional Stats */}
          <Box sx={{ mt: 10 }}>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
              viewport={{ once: true, amount: 0.3 }}
            >
              <Box
                sx={{
                  p: 5,
                  borderRadius: "24px",
                  background:
                    "linear-gradient(135deg, rgba(15, 15, 35, 0.02) 0%, rgba(15, 15, 35, 0.05) 100%)",
                  border: "1px solid rgba(15, 15, 35, 0.05)",
                  textAlign: "center",
                }}
              >
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: "600",
                    mb: 5,
                    color: "#0f0f23",
                    fontSize: { xs: "1.5rem", md: "2rem" },
                  }}
                >
                  Join thousands of professionals growing their networks with
                  BappaCards
                </Typography>
                <Button
                  variant="contained"
                  size="large"
                  sx={{
                    background:
                      "linear-gradient(45deg, #dc004e 30%, #ff3378 90%)",
                    boxShadow: "0 8px 25px rgba(220, 0, 78, 0.25)",
                    "&:hover": {
                      background:
                        "linear-gradient(45deg, #c80046 30%, #e92e6c 90%)",
                      boxShadow: "0 8px 30px rgba(220, 0, 78, 0.35)",
                      transform: "translateY(-3px)",
                    },
                    padding: "12px 40px",
                    fontSize: "1.1rem",
                    fontWeight: "600",
                    borderRadius: "12px",
                    transition: "all 0.3s ease",
                    textTransform: "none",
                  }}
                  onClick={() => {
                    window.location.href =
                      "https://shop.bappacards.com/collections/all";
                  }}
                >
                  Get Started Today
                </Button>
              </Box>
            </motion.div>
          </Box>
        </Container>
      </Box>

      <ContactUsModal open={contactUsOpen} onClose={handleCloseContactUs} />
    </>
  );
};

export default Lander;
