import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Typography,
  IconButton,
  TextField,
  InputAdornment,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/system";
import SearchIcon from "@mui/icons-material/Search";
import GroupIcon from "@mui/icons-material/Group";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import * as action from "../service/action";
import { useUserContext } from "../pages/Login/userContext";

const StyledCard = styled(Card)(({ theme }) => ({
  height: "100%",
  boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
  borderRadius: theme.shape.borderRadius,
  transition: "transform 0.3s ease, box-shadow 0.3s ease",
  "&:hover": {
    transform: "translateY(-5px)",
    boxShadow: "0 8px 25px rgba(0, 0, 0, 0.15)",
  },
}));

// Helper function to validate color
const isValidColor = (color) => {
  if (!color) return false;

  // Check if it's a valid hex color
  const hexRegex = /^#([A-Fa-f0-9]{3}){1,2}$/;
  if (hexRegex.test(color)) return true;

  // Check if it's a valid rgb/rgba color
  const rgbRegex = /^rgb\(\s*\d+\s*,\s*\d+\s*,\s*\d+\s*\)$/;
  const rgbaRegex = /^rgba\(\s*\d+\s*,\s*\d+\s*,\s*\d+\s*,\s*[\d.]+\s*\)$/;
  if (rgbRegex.test(color) || rgbaRegex.test(color)) return true;

  // Check if it's a valid named color
  const tempElement = document.createElement("div");
  tempElement.style.color = color;
  return tempElement.style.color !== "";
};

// Helper function to get a lighter version of a color for hover effects
const getLighterColor = (color) => {
  if (!color || !isValidColor(color)) return "rgba(0, 0, 0, 0.04)";

  try {
    // For hex colors
    if (color.startsWith("#")) {
      let hex = color.replace("#", "");

      // Convert 3-digit hex to 6-digit
      if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
      }

      // Convert to RGB
      const r = parseInt(hex.substring(0, 2), 16);
      const g = parseInt(hex.substring(2, 4), 16);
      const b = parseInt(hex.substring(4, 6), 16);

      // Return lighter rgba color
      return `rgba(${r}, ${g}, ${b}, 0.15)`;
    }

    // For other color formats, return a generic light color
    return "rgba(0, 0, 0, 0.04)";
  } catch (e) {
    console.error("Error creating lighter color:", e);
    return "rgba(0, 0, 0, 0.04)";
  }
};

const GroupsList = () => {
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const { getUser } = useUserContext();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        setLoading(true);
        const token = (await getUser()?.getIdToken()) || "token";
        const groupsData = await action.getGroups(token);

        if (groupsData && Array.isArray(groupsData)) {
          setGroups(groupsData);
        } else {
          setGroups([]);
          console.warn("Groups data is not in expected format:", groupsData);
        }

        setLoading(false);
      } catch (err) {
        console.error("Error fetching groups:", err);
        setError("Failed to load groups. Please try again later.");
        setLoading(false);
      }
    };

    fetchGroups();
  }, [getUser]);

  const handleNavigateToGroup = (groupName) => {
    navigate(`/group/${groupName}`);
  };

  const filteredGroups = groups.filter(
    (group) =>
      group.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      group.description?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="200px"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="200px"
      >
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <StyledCard>
      <CardHeader
        title={
          <Typography variant="h6" fontWeight={600}>
            Groups
          </Typography>
        }
        avatar={<GroupIcon color="primary" />}
        action={
          <TextField
            placeholder="Search groups..."
            variant="outlined"
            size="small"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" />
                </InputAdornment>
              ),
            }}
            sx={{ width: { xs: "100%", sm: 200 } }}
          />
        }
      />
      <Divider />
      <CardContent sx={{ p: 0, maxHeight: 500, overflow: "auto" }}>
        {filteredGroups.length === 0 ? (
          <Box p={3} textAlign="center">
            <Typography variant="body2" color="textSecondary">
              {searchQuery
                ? "No groups match your search."
                : "No groups available."}
            </Typography>
          </Box>
        ) : (
          <List disablePadding>
            {filteredGroups.map((group) => {
              const primaryColor = isValidColor(group.primary_color)
                ? group.primary_color
                : undefined;

              const lighterColor = getLighterColor(primaryColor);

              return (
                <ListItem
                  key={group.id || group.name}
                  divider
                  sx={{
                    transition: "background-color 0.2s ease",
                    "&:hover": {
                      backgroundColor: lighterColor,
                    },
                  }}
                  secondaryAction={
                    <Tooltip title="Open group">
                      <IconButton
                        edge="end"
                        size="small"
                        onClick={() => handleNavigateToGroup(group.name)}
                        sx={{
                          color: primaryColor,
                          "&:hover": {
                            backgroundColor: lighterColor,
                          },
                        }}
                      >
                        <OpenInNewIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  }
                >
                  <ListItemAvatar>
                    <Avatar
                      src={group.photo_url}
                      alt={group.name}
                      sx={{
                        width: 40,
                        height: 40,
                        bgcolor: !group.photo_url
                          ? primaryColor || "primary.main"
                          : undefined,
                        border: primaryColor
                          ? `2px solid ${primaryColor}`
                          : undefined,
                      }}
                    >
                      {!group.photo_url && (group.name?.[0] || "G")}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Typography
                        variant="body2"
                        fontWeight={500}
                        sx={{
                          color: primaryColor || "inherit",
                        }}
                      >
                        {group.name}
                      </Typography>
                    }
                    secondary={
                      <Typography
                        variant="caption"
                        color="textSecondary"
                        noWrap
                        sx={{ maxWidth: "250px" }}
                      >
                        {group.description || "No description available"}
                      </Typography>
                    }
                  />
                </ListItem>
              );
            })}
          </List>
        )}
      </CardContent>
    </StyledCard>
  );
};

export default GroupsList;
