import React, { useState, useEffect, useCallback } from "react";
import {
  CssBaseline,
  Container,
  Paper,
  Typography,
  createTheme,
  ThemeProvider,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Card,
  CardContent,
  CardHeader,
  Avatar,
  Divider,
  Button,
  TextField,
  InputAdornment,
  Chip,
  Tooltip,
  IconButton,
  useTheme,
  useMediaQuery,
  Tabs,
  Tab,
  CircularProgress,
  Alert,
} from "@mui/material";
import { styled } from "@mui/system";
import * as action from "../../service/action";
import { useParams } from "react-router-dom";
import { GetColorName } from "hex-color-to-color-name";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import { useUserContext } from "../Login/userContext";
import ManageGroup from "./ManageGroup";
import ProfilePreview from "./ProfilePreview";
import { SketchPicker } from "react-color";
import { Upload, message } from "antd";
import ImgCrop from "antd-img-crop";

// Icons
import GroupIcon from "@mui/icons-material/Group";
import PersonIcon from "@mui/icons-material/Person";
import TodayIcon from "@mui/icons-material/Today";
import HistoryIcon from "@mui/icons-material/History";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import SearchIcon from "@mui/icons-material/Search";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import SettingsIcon from "@mui/icons-material/Settings";
import BarChartIcon from "@mui/icons-material/BarChart";
import PeopleIcon from "@mui/icons-material/People";
import HomeIcon from "@mui/icons-material/Home";

// Styled components
const PageContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: theme.palette.background.default,
  minHeight: "100vh",
}));

const StyledCard = styled(Card)(({ theme }) => ({
  height: "100%",
  boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
  borderRadius: theme.shape.borderRadius,
  transition: "transform 0.3s ease, box-shadow 0.3s ease",
  "&:hover": {
    transform: "translateY(-5px)",
    boxShadow: "0 8px 25px rgba(0, 0, 0, 0.15)",
  },
}));

const AppearanceCard = styled(StyledCard)(({ theme }) => ({
  minHeight: "800px",
  overflow: "visible",
}));

const StatValue = styled(Typography)(({ theme }) => ({
  fontSize: "2rem",
  fontWeight: 600,
  color: theme.palette.primary.main,
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.5rem",
  },
}));

const StatLabel = styled(Typography)(({ theme }) => ({
  fontSize: "0.875rem",
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}));

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  maxHeight: "400px",
  borderRadius: theme.shape.borderRadius,
  boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
  "& .MuiTableCell-head": {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontWeight: 600,
  },
  "& .MuiTableRow-root:hover": {
    backgroundColor: alpha(theme.palette.primary.main, 0.05),
    cursor: "pointer",
  },
}));

// Add this styled component for the color picker popover
const ColorPickerPopover = styled(Box)(({ theme }) => ({
  position: "absolute",
  zIndex: 2,
  marginTop: theme.spacing(1),
  boxShadow: "0 8px 16px rgba(0, 0, 0, 0.15)",
  borderRadius: theme.shape.borderRadius,
}));

// Add this styled component for a better color swatch
const ColorSwatch = styled(Box)(({ color, theme }) => ({
  width: "100%",
  height: "50px",
  backgroundColor: color || theme.palette.primary.main,
  borderRadius: theme.shape.borderRadius,
  cursor: "pointer",
  boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
  border: "2px solid #fff",
  transition: "all 0.2s ease",
  "&:hover": {
    transform: "scale(1.02)",
    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)",
  },
}));

// Helper function to create alpha colors
function alpha(color, opacity) {
  return (
    color +
    Math.round(opacity * 255)
      .toString(16)
      .padStart(2, "0")
  );
}

function createData(user) {
  return {
    name:
      `${user.firstname || ""} ${user.lastname || ""}`.trim() || "Unnamed User",
    taps_today: user.amount_scanned_today || 0,
    taps_this_week: user.amount_scanned_this_week || 0,
    taps_this_month: user.amount_scanned_this_month || 0,
    total_taps: user.amount_scanned || 0,
    profile_id: user.profile_id,
    email: user.email || "No email",
    avatar: user.profile_photo || "",
  };
}

export default function GroupProfile() {
  const { groupname } = useParams();

  const { getUser } = useUserContext();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [groupData, setGroupData] = useState(undefined);
  const [color, setColor] = useState("#101522");
  const [secondaryColor, setSecondaryColor] = useState("#ffffff");
  const [isEditing, setIsEditing] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [tabValue, setTabValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [groupName, setGroupName] = useState("");
  const [totalTaps, setTotalTaps] = useState(0);
  const [todayTaps, setTodayTaps] = useState(0);
  const [showPrimaryPicker, setShowPrimaryPicker] = useState(false);
  const [showSecondaryPicker, setShowSecondaryPicker] = useState(false);
  const [profileFileList, setProfileFileList] = useState([]);
  const [backgroundFileList, setBackgroundFileList] = useState([]);
  const [companyPhotoFileList, setCompanyPhotoFileList] = useState([]);
  const [profilePhotoPreview, setProfilePhotoPreview] = useState("");
  const [backgroundPhotoPreview, setBackgroundPhotoPreview] = useState("");
  const [companyPhotoPreview, setCompanyPhotoPreview] = useState("");

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const token = (await getUser()?.getIdToken()) || "token";
      const groupDataResponse = await action.getGroup(token, groupname);
      setGroupData(groupDataResponse);
      setColor(groupDataResponse?.primary_color || "#101522");
      setSecondaryColor(groupDataResponse?.secondary_color || "#ffffff");
      setGroupName(
        groupname && groupname[0].toUpperCase() + groupname.slice(1)
      );

      // Initialize file lists if group data has photos
      if (groupDataResponse?.profile_photo) {
        setProfilePhotoPreview(groupDataResponse.profile_photo);
        setProfileFileList([
          {
            uid: "-1",
            name: "profile-photo.jpg",
            status: "done",
            url: groupDataResponse.profile_photo,
          },
        ]);
      }

      if (groupDataResponse?.background_photo) {
        setBackgroundPhotoPreview(groupDataResponse.background_photo);
        setBackgroundFileList([
          {
            uid: "-1",
            name: "background-photo.jpg",
            status: "done",
            url: groupDataResponse.background_photo,
          },
        ]);
      }

      if (groupDataResponse?.company_photo) {
        setCompanyPhotoPreview(groupDataResponse.company_photo);
        setCompanyPhotoFileList([
          {
            uid: "-1",
            name: "company-photo.jpg",
            status: "done",
            url: groupDataResponse.company_photo,
          },
        ]);
      }

      // Calculate total taps
      let total = 0;
      let today = 0;
      groupDataResponse?.users?.forEach((user) => {
        total += user.amount_scanned || 0;
        today += user.amount_scanned_today || 0;
      });
      setTotalTaps(total);
      setTodayTaps(today);
    } catch (error) {
      console.error("Error fetching group data:", error);
    } finally {
      setLoading(false);
    }
  }, [groupname, getUser]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleProfileChange = ({ fileList: newFileList }) => {
    if (newFileList.length > 0) {
      const currentFile = newFileList[newFileList.length - 1];

      // Create preview URL from the file object
      let previewUrl;
      if (currentFile.originFileObj) {
        previewUrl = URL.createObjectURL(currentFile.originFileObj);
        currentFile.url = previewUrl; // Update the URL in the file list
      } else {
        previewUrl = currentFile.url;
      }

      setProfileFileList(newFileList);
      setProfilePhotoPreview(previewUrl);
    } else {
      setProfileFileList([]);
      setProfilePhotoPreview("");
    }
  };

  const handleBackgroundChange = ({ fileList: newFileList }) => {
    if (newFileList.length > 0) {
      const currentFile = newFileList[newFileList.length - 1];

      // Create preview URL from the file object
      let previewUrl;
      if (currentFile.originFileObj) {
        previewUrl = URL.createObjectURL(currentFile.originFileObj);
        currentFile.url = previewUrl; // Update the URL in the file list
      } else {
        previewUrl = currentFile.url;
      }

      setBackgroundFileList(newFileList);
      setBackgroundPhotoPreview(previewUrl);
    } else {
      setBackgroundFileList([]);
      setBackgroundPhotoPreview("");
    }
  };

  const handleCompanyPhotoChange = ({ fileList: newFileList }) => {
    if (newFileList.length > 0) {
      const currentFile = newFileList[newFileList.length - 1];

      // Create preview URL from the file object
      let previewUrl;
      if (currentFile.originFileObj) {
        previewUrl = URL.createObjectURL(currentFile.originFileObj);
        currentFile.url = previewUrl; // Update the URL in the file list
      } else {
        previewUrl = currentFile.url;
      }

      setCompanyPhotoFileList(newFileList);
      setCompanyPhotoPreview(previewUrl);
    } else {
      setCompanyPhotoFileList([]);
      setCompanyPhotoPreview("");
    }
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG files!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must be smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const handleSaveColors = async () => {
    setLoading(true);
    try {
      const token = (await getUser()?.getIdToken()) || "token";

      // First update colors if needed
      await action.updateGroup(token, groupname, {
        primary_color: color,
        secondary_color: secondaryColor,
      });

      // Handle profile photo update
      if (profileFileList.length > 0 && profileFileList[0].originFileObj) {
        const photoData = {
          photoType: "profile",
          photo: profileFileList[0].originFileObj,
        };
        await action.updateGroupPhoto(token, groupname, photoData);
      }

      // Handle background photo update
      if (
        backgroundFileList.length > 0 &&
        backgroundFileList[0].originFileObj
      ) {
        const photoData = {
          photoType: "background",
          photo: backgroundFileList[0].originFileObj,
        };
        await action.updateGroupPhoto(token, groupname, photoData);
      }

      // Handle company photo update
      if (
        companyPhotoFileList.length > 0 &&
        companyPhotoFileList[0].originFileObj
      ) {
        const photoData = {
          photoType: "company",
          photo: companyPhotoFileList[0].originFileObj,
        };
        await action.updateGroupPhoto(token, groupname, photoData);
      }

      setIsEditing(false);
      message.success("Group appearance updated successfully!");
      fetchData();
    } catch (error) {
      console.error("Error saving group settings:", error);
      message.error("Failed to update group appearance");
    } finally {
      setLoading(false);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const rows =
    groupData?.users
      ?.filter((user) => {
        const fullName = `${user.firstname || ""} ${
          user.lastname || ""
        }`.toLowerCase();
        const email = (user.email || "").toLowerCase();
        return (
          fullName.includes(searchQuery.toLowerCase()) ||
          email.includes(searchQuery.toLowerCase())
        );
      })
      .sort((a, b) => {
        // Sort by total taps by default
        return b.amount_scanned - a.amount_scanned;
      })
      .map((user) => createData(user)) || [];

  if (loading || !groupData) {
    return <LoadingScreen />;
  }

  return (
    <ThemeProvider
      theme={createTheme({
        palette: {
          primary: {
            main: color || "#101522",
          },
          secondary: {
            main: secondaryColor || "#ffffff",
          },
        },
      })}
    >
      <CssBaseline />
      <PageContainer maxWidth="xl">
        <Box mb={4}>
          <Typography
            variant="h4"
            gutterBottom
            sx={{
              fontWeight: 700,
              color: theme.palette.text.primary,
              textAlign: "center",
              mb: 3,
            }}
          >
            {groupName} Group Management
          </Typography>

          <Paper
            elevation={0}
            sx={{
              p: 1,
              display: "flex",
              justifyContent: "center",
              backgroundColor: theme.palette.background.paper,
              borderRadius: 2,
              mb: 4,
            }}
          >
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              variant={isMobile ? "scrollable" : "fullWidth"}
              scrollButtons={isMobile ? "auto" : false}
              indicatorColor="primary"
              textColor="primary"
              sx={{
                "& .MuiTab-root": {
                  minHeight: "48px",
                  fontWeight: 500,
                },
              }}
            >
              <Tab
                icon={<HomeIcon />}
                label={isMobile ? "" : "Home"}
                iconPosition="start"
              />
              <Tab
                icon={<ColorLensIcon />}
                label={isMobile ? "" : "Appearance"}
                iconPosition="start"
              />
              <Tab
                icon={<SettingsIcon />}
                label={isMobile ? "" : "Settings"}
                iconPosition="start"
              />
            </Tabs>
          </Paper>
        </Box>

        {tabValue === 0 ? (
          <>
            <Grid container spacing={3} mb={4}>
              <Grid item xs={12} sm={6} md={4}>
                <StyledCard>
                  <CardContent sx={{ textAlign: "center", py: 3 }}>
                    <Avatar
                      sx={{
                        bgcolor: theme.palette.primary.main,
                        width: 56,
                        height: 56,
                        margin: "0 auto",
                        mb: 2,
                      }}
                    >
                      <PeopleIcon fontSize="large" />
                    </Avatar>
                    <StatLabel>Total Members</StatLabel>
                    <StatValue>{groupData?.users?.length || 0}</StatValue>
                  </CardContent>
                </StyledCard>
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <StyledCard>
                  <CardContent sx={{ textAlign: "center", py: 3 }}>
                    <Avatar
                      sx={{
                        bgcolor: theme.palette.secondary.main,
                        width: 56,
                        height: 56,
                        margin: "0 auto",
                        mb: 2,
                      }}
                    >
                      <HistoryIcon fontSize="large" />
                    </Avatar>
                    <StatLabel>Total Taps</StatLabel>
                    <StatValue>{totalTaps}</StatValue>
                  </CardContent>
                </StyledCard>
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <StyledCard>
                  <CardContent sx={{ textAlign: "center", py: 3 }}>
                    <Avatar
                      sx={{
                        bgcolor: "#4caf50",
                        width: 56,
                        height: 56,
                        margin: "0 auto",
                        mb: 2,
                      }}
                    >
                      <TodayIcon fontSize="large" />
                    </Avatar>
                    <StatLabel>Today's Taps</StatLabel>
                    <StatValue>{todayTaps}</StatValue>
                  </CardContent>
                </StyledCard>
              </Grid>
            </Grid>

            <StyledCard>
              <CardHeader
                title={
                  <Typography variant="h6" fontWeight={600}>
                    Group Members
                  </Typography>
                }
                avatar={<PersonIcon color="primary" />}
                action={
                  <TextField
                    placeholder="Search members..."
                    variant="outlined"
                    size="small"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon fontSize="small" />
                        </InputAdornment>
                      ),
                    }}
                    sx={{ width: { xs: "100%", sm: 200 } }}
                  />
                }
              />
              <Divider />
              <CardContent sx={{ p: 0 }}>
                <StyledTableContainer>
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell>Member</TableCell>
                        <TableCell align="right">Today</TableCell>
                        {!isMobile && (
                          <>
                            <TableCell align="right">This Week</TableCell>
                            <TableCell align="right">This Month</TableCell>
                          </>
                        )}
                        <TableCell align="right">Total Taps</TableCell>
                        <TableCell align="right">Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.length > 0 ? (
                        rows.map((row) => (
                          <TableRow
                            key={row.profile_id}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              <Box
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Avatar
                                  src={row.avatar}
                                  sx={{ width: 32, height: 32, mr: 1 }}
                                >
                                  {row.name.charAt(0)}
                                </Avatar>
                                <Box>
                                  <Typography variant="body2" fontWeight={500}>
                                    {row.name}
                                  </Typography>
                                  <Typography
                                    variant="caption"
                                    color="textSecondary"
                                  >
                                    {row.email}
                                  </Typography>
                                </Box>
                              </Box>
                            </TableCell>
                            <TableCell align="right">
                              <Chip
                                size="small"
                                label={row.taps_today}
                                color={
                                  row.taps_today > 0 ? "primary" : "default"
                                }
                                variant={
                                  row.taps_today > 0 ? "filled" : "outlined"
                                }
                              />
                            </TableCell>
                            {!isMobile && (
                              <>
                                <TableCell align="right">
                                  <Chip
                                    size="small"
                                    label={row.taps_this_week}
                                    color={
                                      row.taps_this_week > 0
                                        ? "primary"
                                        : "default"
                                    }
                                    variant={
                                      row.taps_this_week > 0
                                        ? "filled"
                                        : "outlined"
                                    }
                                  />
                                </TableCell>
                                <TableCell align="right">
                                  <Chip
                                    size="small"
                                    label={row.taps_this_month}
                                    color={
                                      row.taps_this_month > 0
                                        ? "primary"
                                        : "default"
                                    }
                                    variant={
                                      row.taps_this_month > 0
                                        ? "filled"
                                        : "outlined"
                                    }
                                  />
                                </TableCell>
                              </>
                            )}
                            <TableCell align="right">
                              <Typography
                                variant="body2"
                                fontWeight={600}
                                color="primary"
                              >
                                {row.total_taps}
                              </Typography>
                            </TableCell>
                            <TableCell align="right">
                              <Tooltip title="View Profile">
                                <IconButton
                                  size="small"
                                  onClick={() =>
                                    window.open(
                                      `${window.location.origin}/profile/${row.profile_id}?group=${groupname}`,
                                      "_blank"
                                    )
                                  }
                                >
                                  <OpenInNewIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={isMobile ? 4 : 6} align="center">
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              py={3}
                            >
                              No members found
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </StyledTableContainer>
              </CardContent>
            </StyledCard>
          </>
        ) : tabValue === 1 ? (
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <AppearanceCard>
                <CardHeader
                  title={
                    <Typography variant="h6" fontWeight={600}>
                      Group Appearance
                    </Typography>
                  }
                  avatar={<ColorLensIcon color="primary" />}
                  action={
                    <IconButton onClick={() => setIsEditing(!isEditing)}>
                      {isEditing ? <SaveIcon /> : <EditIcon />}
                    </IconButton>
                  }
                />
                <Divider />
                <CardContent sx={{ overflow: "visible", pb: 4 }}>
                  <Box mb={4}>
                    <Typography
                      variant="subtitle1"
                      fontWeight={600}
                      gutterBottom
                    >
                      Profile Preview
                    </Typography>
                    <Typography variant="body2" color="textSecondary" mb={2}>
                      This is how profiles in your group will appear to visitors
                    </Typography>

                    <Box
                      display="flex"
                      justifyContent="center"
                      width="100%"
                      mb={3}
                    >
                      <ProfilePreview
                        formData={{
                          firstName: "Sample",
                          lastName: "User",
                          title: `${groupName} Member`,
                          cardTheme: color,
                          linkColor: secondaryColor,
                          profilePhoto:
                            profilePhotoPreview || groupData?.profile_photo,
                          coverPhoto:
                            backgroundPhotoPreview ||
                            groupData?.background_photo,
                          companyPhoto:
                            companyPhotoPreview || groupData?.company_photo,
                          matchLinkIcons: false,
                          socials: {
                            website: [
                              { name: groupName, url: "yourcompany.com" },
                            ],
                            instagram: ["yourcompany"],
                            linkedin: [""],
                            twitter: [""],
                            facebook: [""],
                            snapchat: [""],
                            tiktok: [""],
                            youtube: [""],
                            whatsapp: [""],
                            cashapp: [""],
                            venmo: [""],
                            paypal: [""],
                          },
                          socialOrder: ["website", "instagram"],
                          profileUrl: window.location.origin,
                          links: {
                            email: "contact@example.com",
                            phone: "",
                            company: groupName,
                            jobTitle: "Team Member",
                          },
                        }}
                        preferences={{
                          font: "Roboto",
                          showSaveContactButton: true,
                          showExchangeContactButton: true,
                        }}
                        redirectEnabled={false}
                        redirectLink=""
                        isProfilePage={false}
                      />
                    </Box>
                  </Box>

                  <Divider sx={{ my: 3 }} />

                  <Typography variant="subtitle1" fontWeight={600} gutterBottom>
                    Group Photos
                  </Typography>

                  <Grid container spacing={3} mb={4}>
                    <Grid item xs={12} sm={6}>
                      <Typography
                        variant="subtitle2"
                        fontWeight={600}
                        gutterBottom
                      >
                        Profile Photo
                      </Typography>
                      <Typography variant="body2" color="textSecondary" mb={1}>
                        This will be used as the default profile photo for group
                        members
                      </Typography>
                      <ImgCrop rotationSlider>
                        <Upload
                          action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                          listType="picture-card"
                          fileList={profileFileList}
                          onChange={handleProfileChange}
                          onPreview={onPreview}
                          beforeUpload={beforeUpload}
                          accept=".jpg,.jpeg,.png"
                          disabled={!isEditing}
                        >
                          {profileFileList.length < 1 &&
                            (isEditing ? "+ Upload" : null)}
                        </Upload>
                      </ImgCrop>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Typography
                        variant="subtitle2"
                        fontWeight={600}
                        gutterBottom
                      >
                        Background Photo
                      </Typography>
                      <Typography variant="body2" color="textSecondary" mb={1}>
                        This will be used as the default cover photo for group
                        members
                      </Typography>
                      <ImgCrop rotationSlider aspect={16 / 9} zoom>
                        <Upload
                          action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                          listType="picture-card"
                          fileList={backgroundFileList}
                          onChange={handleBackgroundChange}
                          onPreview={onPreview}
                          beforeUpload={beforeUpload}
                          accept=".jpg,.jpeg,.png"
                          disabled={!isEditing}
                        >
                          {backgroundFileList.length < 1 &&
                            (isEditing ? "+ Upload" : null)}
                        </Upload>
                      </ImgCrop>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Typography
                        variant="subtitle2"
                        fontWeight={600}
                        gutterBottom
                      >
                        Company Logo
                      </Typography>
                      <Typography variant="body2" color="textSecondary" mb={1}>
                        This will be used as the default company logo for group
                        members
                      </Typography>
                      <ImgCrop rotationSlider>
                        <Upload
                          action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                          listType="picture-card"
                          fileList={companyPhotoFileList}
                          onChange={handleCompanyPhotoChange}
                          onPreview={onPreview}
                          beforeUpload={beforeUpload}
                          accept=".jpg,.jpeg,.png"
                          disabled={!isEditing}
                        >
                          {companyPhotoFileList.length < 1 &&
                            (isEditing ? "+ Upload" : null)}
                        </Upload>
                      </ImgCrop>
                    </Grid>
                  </Grid>

                  <Divider sx={{ my: 3 }} />

                  <Typography variant="subtitle1" fontWeight={600} gutterBottom>
                    Color Settings
                  </Typography>

                  <Grid
                    container
                    spacing={3}
                    sx={{ mb: 4, position: "relative", zIndex: 10 }}
                  >
                    <Grid item xs={12} md={6}>
                      <Typography
                        variant="subtitle2"
                        fontWeight={600}
                        gutterBottom
                      >
                        Primary Color
                      </Typography>
                      <Box position="relative" sx={{ mb: 4 }}>
                        <ColorSwatch
                          color={color}
                          onClick={() =>
                            isEditing &&
                            setShowPrimaryPicker(!showPrimaryPicker)
                          }
                          sx={{
                            opacity: isEditing ? 1 : 0.8,
                            height: "60px",
                          }}
                        >
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            height="100%"
                          >
                            <Typography
                              variant="body2"
                              color={isLightColor(color) ? "black" : "white"}
                              fontWeight={500}
                            >
                              {GetColorName(color)}
                            </Typography>
                          </Box>
                        </ColorSwatch>

                        {isEditing && showPrimaryPicker && (
                          <ColorPickerPopover
                            sx={{
                              position: "absolute",
                              zIndex: 1000,
                              left: { xs: 0, sm: "auto" },
                              right: { xs: 0, sm: "auto" },
                              maxWidth: { xs: "100%", sm: "220px" },
                              "& .sketch-picker": {
                                boxSizing: "border-box",
                                width: "100% !important",
                              },
                            }}
                          >
                            <SketchPicker
                              color={color}
                              onChange={(colorResult) => {
                                setColor(colorResult.hex);
                              }}
                              disableAlpha
                              presetColors={[
                                "#101522",
                                "#1976d2",
                                "#9c27b0",
                                "#e91e63",
                                "#f44336",
                                "#ff9800",
                                "#ffeb3b",
                                "#4caf50",
                                "#009688",
                                "#00bcd4",
                              ]}
                            />
                            <Box
                              display="flex"
                              justifyContent="flex-end"
                              p={1}
                              bgcolor="#fff"
                              borderRadius="0 0 4px 4px"
                            >
                              <Button
                                size="small"
                                onClick={() => setShowPrimaryPicker(false)}
                                variant="outlined"
                              >
                                Close
                              </Button>
                            </Box>
                          </ColorPickerPopover>
                        )}
                      </Box>
                      {isEditing && !showPrimaryPicker && (
                        <Typography
                          variant="caption"
                          color="textSecondary"
                          sx={{ display: "block", mt: 1 }}
                        >
                          Click on the color to edit
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography
                        variant="subtitle2"
                        fontWeight={600}
                        gutterBottom
                      >
                        Secondary Color
                      </Typography>
                      <Box position="relative" sx={{ mb: 4 }}>
                        <ColorSwatch
                          color={secondaryColor}
                          onClick={() =>
                            isEditing &&
                            setShowSecondaryPicker(!showSecondaryPicker)
                          }
                          sx={{
                            opacity: isEditing ? 1 : 0.8,
                            height: "60px",
                          }}
                        >
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            height="100%"
                          >
                            <Typography
                              variant="body2"
                              color={
                                isLightColor(secondaryColor) ? "black" : "white"
                              }
                              fontWeight={500}
                            >
                              {GetColorName(secondaryColor)}
                            </Typography>
                          </Box>
                        </ColorSwatch>

                        {isEditing && showSecondaryPicker && (
                          <ColorPickerPopover
                            sx={{
                              position: "absolute",
                              zIndex: 1000,
                              left: { xs: 0, sm: "auto" },
                              right: { xs: 0, sm: "auto" },
                              maxWidth: { xs: "100%", sm: "220px" },
                              "& .sketch-picker": {
                                boxSizing: "border-box",
                                width: "100% !important",
                              },
                            }}
                          >
                            <SketchPicker
                              color={secondaryColor}
                              onChange={(colorResult) => {
                                setSecondaryColor(colorResult.hex);
                              }}
                              disableAlpha
                              presetColors={[
                                "#ffffff",
                                "#f5f5f5",
                                "#e0e0e0",
                                "#9e9e9e",
                                "#616161",
                                "#212121",
                                "#2196f3",
                                "#4caf50",
                                "#ff9800",
                                "#f44336",
                              ]}
                            />
                            <Box
                              display="flex"
                              justifyContent="flex-end"
                              p={1}
                              bgcolor="#fff"
                              borderRadius="0 0 4px 4px"
                            >
                              <Button
                                size="small"
                                onClick={() => setShowSecondaryPicker(false)}
                                variant="outlined"
                              >
                                Close
                              </Button>
                            </Box>
                          </ColorPickerPopover>
                        )}
                      </Box>
                      {isEditing && !showSecondaryPicker && (
                        <Typography
                          variant="caption"
                          color="textSecondary"
                          sx={{ display: "block", mt: 1 }}
                        >
                          Click on the color to edit
                        </Typography>
                      )}
                    </Grid>
                  </Grid>

                  {isEditing && (
                    <Box
                      display="flex"
                      justifyContent="flex-end"
                      mt={2}
                      position="relative"
                      zIndex={5}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSaveColors}
                        disabled={loading}
                        startIcon={loading && <CircularProgress size={20} />}
                      >
                        Save Changes
                      </Button>
                    </Box>
                  )}
                </CardContent>
              </AppearanceCard>
            </Grid>
          </Grid>
        ) : tabValue === 2 ? (
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <StyledCard>
                <CardHeader
                  title={
                    <Typography variant="h6" fontWeight={600}>
                      Group Settings
                    </Typography>
                  }
                  avatar={<SettingsIcon color="primary" />}
                />
                <Divider />
                <CardContent>
                  <Typography variant="body2" color="textSecondary" mb={3}>
                    Group settings will be available here. You can manage group
                    permissions, notifications, and other settings.
                  </Typography>

                  <Alert severity="info" sx={{ mb: 2 }}>
                    Group appearance settings have been moved to the Appearance
                    tab.
                  </Alert>
                </CardContent>
              </StyledCard>
            </Grid>

            <Grid item xs={12} md={6}>
              <StyledCard>
                <CardHeader
                  title={
                    <Typography variant="h6" fontWeight={600}>
                      Group Management
                    </Typography>
                  }
                  avatar={<GroupIcon color="primary" />}
                />
                <Divider />
                <CardContent>
                  <Box mb={3}>
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<AddIcon />}
                      fullWidth
                      sx={{ mb: 2 }}
                    >
                      Add New Member
                    </Button>

                    <Button
                      variant="outlined"
                      color="primary"
                      startIcon={<DeleteIcon />}
                      fullWidth
                    >
                      Remove Selected Members
                    </Button>
                  </Box>

                  <Divider sx={{ my: 2 }} />

                  <Typography variant="subtitle1" fontWeight={600} gutterBottom>
                    Group Information
                  </Typography>

                  <Box sx={{ mb: 2 }}>
                    <TextField
                      label="Group Name"
                      value={groupName}
                      onChange={(e) => setGroupName(e.target.value)}
                      fullWidth
                      disabled={!isEditing}
                      margin="normal"
                    />
                  </Box>
                </CardContent>
              </StyledCard>

              {/* Group Analytics Card */}
              <StyledCard sx={{ mt: 3 }}>
                <CardHeader
                  title={
                    <Typography variant="h6" fontWeight={600}>
                      Group Analytics
                    </Typography>
                  }
                  avatar={<BarChartIcon color="primary" />}
                />
                <Divider />
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Box
                        textAlign="center"
                        p={2}
                        bgcolor={alpha(color, 0.1)}
                        borderRadius={1}
                      >
                        <Typography variant="body2" color="textSecondary">
                          Average Taps per Member
                        </Typography>
                        <Typography
                          variant="h5"
                          fontWeight={600}
                          color="primary"
                        >
                          {groupData?.users?.length
                            ? Math.round(totalTaps / groupData.users.length)
                            : 0}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box
                        textAlign="center"
                        p={2}
                        bgcolor={alpha(color, 0.1)}
                        borderRadius={1}
                      >
                        <Typography variant="body2" color="textSecondary">
                          Today's Activity
                        </Typography>
                        <Typography
                          variant="h5"
                          fontWeight={600}
                          color="primary"
                        >
                          {todayTaps > 0 ? `+${todayTaps}` : 0}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box
                        textAlign="center"
                        p={2}
                        mt={1}
                        bgcolor={alpha(color, 0.05)}
                        borderRadius={1}
                      >
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          gutterBottom
                        >
                          Most Active Member
                        </Typography>
                        {rows.length > 0 ? (
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                          >
                            <Avatar
                              src={rows[0].avatar}
                              sx={{ width: 32, height: 32, mr: 1 }}
                            >
                              {rows[0].name.charAt(0)}
                            </Avatar>
                            <Box>
                              <Typography variant="body2" fontWeight={500}>
                                {rows[0].name}
                              </Typography>
                              <Typography variant="caption" color="primary">
                                {rows[0].total_taps} total taps
                              </Typography>
                            </Box>
                          </Box>
                        ) : (
                          <Typography variant="body2">
                            No active members yet
                          </Typography>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
              </StyledCard>
            </Grid>
          </Grid>
        ) : null}
      </PageContainer>
    </ThemeProvider>
  );
}

function isLightColor(color) {
  const hex = color.replace("#", "");
  const r = parseInt(hex.substr(0, 2), 16);
  const g = parseInt(hex.substr(2, 2), 16);
  const b = parseInt(hex.substr(4, 2), 16);
  const brightness = (r * 299 + g * 587 + b * 114) / 1000;
  return brightness > 128;
}
