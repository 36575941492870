import React, { useContext, useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import { AuthContext } from "./Auth";
import * as action from "../service/action";
import LoadingScreen from "./LoadingScreen/LoadingScreen";
import { ADMIN_EMAILS } from "./AdminRoute";

/**
 * GroupAdminRoute component that restricts access to group admin pages.
 * Only users who are admins of their specific group (userData.group.admin = true)
 * for the group matching the URL parameter (userData.group.name) can access.
 */
const GroupAdminRoute = ({ redirectPath = "/dashboard", children }) => {
  const { currentUser } = useContext(AuthContext);
  const { groupname } = useParams();
  const [loading, setLoading] = useState(true);
  const [isGroupAdmin, setIsGroupAdmin] = useState(false);

  useEffect(() => {
    const checkGroupAdminStatus = async () => {
      if (!currentUser) {
        setLoading(false);
        return;
      }

      try {
        // Get the user data including group information
        const token = await currentUser.getIdToken();
        const userData = await action.getMe(token);

        // Check if user is an admin of the specified group or is in ADMIN_EMAILS
        const hasAdminAccess =
          (userData?.group_info?.name === groupname &&
            userData?.group_info?.admin === true) ||
          ADMIN_EMAILS.includes(currentUser.email);

        setIsGroupAdmin(hasAdminAccess);
      } catch (error) {
        console.error("Error checking group admin status:", error);
        setIsGroupAdmin(false);
      } finally {
        setLoading(false);
      }
    };

    checkGroupAdminStatus();
  }, [currentUser, groupname]);

  if (loading) {
    return <LoadingScreen />;
  }

  if (!currentUser || !isGroupAdmin) {
    return <Navigate to={redirectPath} replace />;
  }

  return children;
};

export default GroupAdminRoute;
