import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import * as action from "../../service/action";
import LoadingButton from "@mui/lab/LoadingButton";
import Alert from "@mui/material/Alert";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ExchangeContact = ({ open, handleClose, profileId, profile }) => {
  const [loading, setLoading] = useState(false);
  const [showSuccess, setShowSuccess] = React.useState(false);
  const [showError, setShowError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");

  const [fullName, setFullName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [notes, setNotes] = useState("");

  const submitContact = async () => {
    setLoading(true);
    const body = {
      full_name: fullName,
      phone_number: phoneNumber,
      email: email,
      notes: notes,
    };

    const response = await action.createContact(profileId, body);

    if (response.status !== 200) {
      setErrorMessage(response.data.error);
      setShowError(true);
    } else {
      setShowSuccess(true);
      setTimeout(() => {
        handleClose();
      }, 500);
    }
    setLoading(false);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          align="center"
          style={{ paddingBottom: 20 }}
        >
          {`Exchange your information back with ${profile.firstname}`}
        </Typography>
        <Stack direction="column" spacing={2}>
          <TextField
            value={fullName}
            id="filled-error-helper-text"
            label="Full Name"
            required
            onChange={(e) => setFullName(e.target.value)}
            variant="filled"
          />

          <TextField
            value={phoneNumber}
            id="filled-error-helper-text"
            label="Phone Number"
            required
            onChange={(e) => setPhoneNumber(e.target.value)}
            variant="filled"
          />

          <TextField
            value={email}
            id="filled-error-helper-text"
            label="Email"
            onChange={(e) => setEmail(e.target.value)}
            variant="filled"
          />

          <TextField
            // error
            value={notes}
            id="filled-error-helper-text"
            label="Notes"
            multiline
            onChange={(e) => setNotes(e.target.value)}
            variant="filled"
          />
          <LoadingButton
            loading={loading}
            disabled={!fullName || !phoneNumber}
            variant="contained"
            onClick={submitContact}
          >
            Submit
          </LoadingButton>
          <Alert
            severity="success"
            onClose={() => handleClose}
            hidden={!showSuccess}
          >
            Your information has been submitted!
          </Alert>
          <Alert
            severity="error"
            onClose={() => setShowError(false)}
            hidden={!showError}
          >
            {errorMessage}
          </Alert>
        </Stack>
      </Box>
    </Modal>
  );
};
export default ExchangeContact;
