import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useUserContext } from "../Login/userContext";
import * as action from "../../service/action";
import { Box, Typography, Container, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ProfilePreview from "./ProfilePreview";
import ExchangeContact from "./ExchangeContact";

function Profile() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [profile, setProfile] = useState(undefined);
  const { getUser } = useUserContext();
  const [exchangeContactOpen, setExchangeContactOpen] = useState(false);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));

  const fetchData = useCallback(async () => {
    const token = (await getUser()?.getIdToken()) || "token";
    const profileResponse = await action.getProfile(id, token);

    if (profileResponse.status === 404) {
      navigate("/home");
    }
    setProfile(profileResponse.data);
  }, [id, navigate, getUser]);

  useEffect(() => {
    fetchData();
  }, [fetchData, id, navigate, getUser]);

  if (profile === undefined) {
    return (
      <Container
        maxWidth="lg"
        sx={{
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography>Loading...</Typography>
      </Container>
    );
  }

  // Assuming user object contains all necessary information
  const formData = {
    firstName: profile.firstname,
    lastName: profile.lastname,
    title: profile.title,
    font: profile.preferences.font,
    cardTheme: profile.preferences.card_theme,
    linkColor: profile.preferences.link_color,
    profilePhoto: profile.profile_photo,
    coverPhoto: profile.background_photo,
    companyPhoto: profile.company_photo,
    profileUrl: profile.profile_url,
    matchLinkIcons: profile.match_link_icons,
    socials: {
      website: profile.links.websites,
      instagram: profile.links.instagram_usernames,
      twitter: profile.links.twitter_usernames,
      linkedin: profile.links.linkedin_urls,
      snapchat: profile.links.snapchat_usernames,
      tiktok: profile.links.tiktok_usernames,
      facebook: profile.links.facebook_urls,
      youtube: profile.links.youtube_urls,
      whatsapp: profile.links.whatsapp_numbers,
      cashapp: profile.links.cashapp_usernames,
      venmo: profile.links.venmo_usernames,
      paypal: profile.links.paypal_usernames,
    },
    socialOrder:
      profile.links.social_order && profile.links.social_order.length > 0
        ? profile.links.social_order
        : [
            "website",
            "instagram",
            "twitter",
            "linkedin",
            "snapchat",
            "tiktok",
            "facebook",
            "youtube",
            "whatsapp",
            "cashapp",
            "venmo",
            "paypal",
          ],
    links: profile.links || [], // This is used for the downloadVcard function
  };

  const preferences = {
    showSaveContactButton: profile.preferences.showSaveContactButton || false,
    showExchangeContactButton:
      profile.preferences.showExchangeContactButton || false,
    font: profile.preferences.font || "DEFAULT",
  };

  const handleOpenExchangeContact = () => {
    setExchangeContactOpen(true);
  };

  const handleCloseExchangeContact = () => {
    setExchangeContactOpen(false);
  };

  const openExchangeContact = (message) => {
    setExchangeContactOpen(true);
  };

  return (
    <Container
      maxWidth="lg"
      disableGutters={isSmallScreen}
      sx={{
        width: "100%",
        maxWidth: isSmallScreen ? "100%" : isMediumScreen ? "80%" : "1200px",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: isSmallScreen ? theme.spacing(2) : theme.spacing(4),
      }}
    >
      <Box
        maxWidth="lg"
        sx={{
          width: "100%",
          maxWidth: isSmallScreen ? "100%" : 800,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <ProfilePreview
          formData={formData}
          preferences={preferences}
          redirectEnabled={profile.preferences.redirectEnabled || false}
          redirectLink={profile.preferences.redirectLink || ""}
          onExchangeContact={handleOpenExchangeContact}
          openExchangeContact={openExchangeContact}
          isProfilePage={true}
          sx={{
            width: "100%",
            height: "auto",
            minHeight: isSmallScreen ? "auto" : "80vh",
          }}
        />
      </Box>
      <ExchangeContact
        open={exchangeContactOpen}
        handleClose={handleCloseExchangeContact}
        profileId={profile.profile_id}
        profile={profile}
      />
    </Container>
  );
}

export default Profile;
