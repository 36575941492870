import React, { useState, useEffect, useMemo, useRef } from "react";
import {
  Box,
  Container,
  Typography,
  Paper,
  Grid,
  Card,
  CardContent,
  CardHeader,
  Tabs,
  Tab,
  Avatar,
  Chip,
  Divider,
  useTheme,
  useMediaQuery,
  CircularProgress,
  Fade,
  Tooltip,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  styled,
  LinearProgress,
  Button,
} from "@mui/material";
import {
  TrendingUp,
  LocationOn,
  Today,
  DateRange,
  CalendarMonth,
  History,
  Explore,
  Public,
  TapAndPlay,
  BarChart,
  DonutLarge,
  Timeline,
  InsertChart,
  PieChart,
  ShowChart,
  MoreHoriz,
  Lock as LockIcon,
  Star as StarIcon,
  CheckCircle as CheckCircleIcon,
  InfoOutlined,
} from "@mui/icons-material";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import "leaflet.markercluster";
import { useUserContext } from "../Login/userContext";
import * as action from "../../service/action";
import { alpha } from "@mui/material/styles";
import { Link } from "react-router-dom";

// Fix for default marker icon
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

// Create a function to generate colored marker icons
const createColoredIcon = (color) => {
  // Create a custom icon with the specified color using SVG for a location pin
  return new L.DivIcon({
    className: "custom-map-marker",
    html: `
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 36" width="24" height="36" style="filter: drop-shadow(0 2px 2px rgba(0,0,0,0.5));">
        <path fill="${color}" d="M12 0C5.4 0 0 5.4 0 12c0 7.2 12 24 12 24s12-16.8 12-24c0-6.6-5.4-12-12-12zm0 18c-3.3 0-6-2.7-6-6s2.7-6 6-6 6 2.7 6 6-2.7 6-6 6z"/>
        <circle fill="white" cx="12" cy="12" r="4"/>
      </svg>
    `,
    iconSize: [24, 36],
    iconAnchor: [12, 36],
    popupAnchor: [0, -34],
  });
};

// Styled components
const AnalyticsContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: theme.palette.background.default,
  minHeight: "100vh",
}));

const StyledCard = styled(Card)(({ theme }) => ({
  height: "100%",
  boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
  borderRadius: theme.shape.borderRadius,
  transition: "transform 0.3s ease, box-shadow 0.3s ease",
  "&:hover": {
    transform: "translateY(-5px)",
    boxShadow: "0 8px 25px rgba(0, 0, 0, 0.15)",
  },
}));

const StatValue = styled(Typography)(({ theme }) => ({
  fontSize: "2rem",
  fontWeight: 600,
  color: theme.palette.primary.main,
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.5rem",
  },
}));

const StatLabel = styled(Typography)(({ theme }) => ({
  fontSize: "0.875rem",
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}));

const CityChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0.5),
  backgroundColor: theme.palette.background.paper,
  boxShadow: "0 2px 5px rgba(0, 0, 0, 0.08)",
  "& .MuiChip-label": {
    fontWeight: 500,
  },
}));

const StyledMapContainer = styled(Box)(({ theme }) => ({
  height: "400px",
  width: "100%",
  marginBottom: theme.spacing(3),
  borderRadius: theme.shape.borderRadius,
  overflow: "hidden",
  boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
}));

const LoadingContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100vh",
  gap: theme.spacing(2),
}));

const ChartContainer = styled(Box)(({ theme }) => ({
  height: "auto",
  maxHeight: 400,
  padding: theme.spacing(2),
  overflowY: "auto",
  "&::-webkit-scrollbar": {
    width: "8px",
  },
  "&::-webkit-scrollbar-track": {
    background: theme.palette.background.paper,
    borderRadius: "4px",
  },
  "&::-webkit-scrollbar-thumb": {
    background: alpha(theme.palette.primary.main, 0.3),
    borderRadius: "4px",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    background: alpha(theme.palette.primary.main, 0.5),
  },
}));

const BarChartItem = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  width: "100%",
  transition: "transform 0.2s ease, box-shadow 0.2s ease",
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  "&:hover": {
    transform: "translateX(5px)",
    backgroundColor: alpha(theme.palette.background.paper, 0.7),
    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.05)",
  },
}));

const BarLabel = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: theme.spacing(0.5),
  fontWeight: 500,
}));

const PieChartContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: "100%",
}));

const PieChartLegend = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  gap: theme.spacing(1),
  marginTop: theme.spacing(2),
}));

const PieChartLegendItem = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(0.5),
}));

const ColorBox = styled(Box)(({ color }) => ({
  width: 16,
  height: 16,
  backgroundColor: color,
  borderRadius: 2,
}));

const Analytics = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { getUser } = useUserContext();
  const [analyticsData, setAnalyticsData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [timeRange, setTimeRange] = useState(0);
  const [tabValue, setTabValue] = useState(0);
  const chartRef = useRef(null);
  const [userData, setUserData] = useState(null);
  const [isLoadingUserData, setIsLoadingUserData] = useState(true);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        setIsLoadingUserData(true);
        const token = await getUser()?.getIdToken();
        const response = await action.getMe(token);
        setUserData(response);
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        setIsLoadingUserData(false);
      }
    };

    fetchUserData();
  }, [getUser]);

  useEffect(() => {
    const fetchAnalytics = async () => {
      try {
        setLoading(true);
        const token = (await getUser()?.getIdToken()) || "token";
        const data = await action.getUserAnalytics(token);
        setAnalyticsData(data);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching analytics:", err);
        setError("Failed to load analytics data. Please try again later.");
        setLoading(false);
      }
    };

    fetchAnalytics();
  }, [getUser]);

  // Check if user has premium subscription
  const hasPremiumSubscription =
    userData?.subscription?.plan === "bappa-premium" &&
    userData?.subscription?.status === "active";

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setTimeRange([0, 25, 50, 100][newValue]);
  };

  const getTimeRangeTitle = (timeRange) => {
    switch (timeRange) {
      case 0:
        return "All Time";
      case 25:
        return "This Month";
      case 50:
        return "This Week";
      case 100:
        return "Today";
      default:
        return "All Time";
    }
  };

  const getTimeRangeIcon = (timeRange) => {
    switch (timeRange) {
      case 0:
        return <History />;
      case 25:
        return <CalendarMonth />;
      case 50:
        return <DateRange />;
      case 100:
        return <Today />;
      default:
        return <History />;
    }
  };

  const getTotalTaps = (timeRange) => {
    if (!analyticsData) return 0;

    if (timeRange === 0) {
      return analyticsData.totalScans || 0;
    } else if (timeRange === 25) {
      return analyticsData.totalScansThisMonth || 0;
    } else if (timeRange === 50) {
      return analyticsData.totalScansThisWeek || 0;
    } else {
      return analyticsData.totalScansToday || 0;
    }
  };

  const getCityData = (timeRange) => {
    if (!analyticsData) return [];

    if (timeRange === 0) {
      return Object.entries(analyticsData.scansByCity || {}).sort(
        (a, b) => b[1].count - a[1].count
      );
    } else if (timeRange === 25) {
      return Object.entries(analyticsData.scansByCityThisMonth || {}).sort(
        (a, b) => b[1].count - a[1].count
      );
    } else if (timeRange === 50) {
      return Object.entries(analyticsData.scansByCityThisWeek || {}).sort(
        (a, b) => b[1].count - a[1].count
      );
    } else {
      return Object.entries(analyticsData.scansByCityToday || {}).sort(
        (a, b) => b[1].count - a[1].count
      );
    }
  };

  // Prepare data for charts
  const barChartData = useMemo(() => {
    if (!analyticsData) return [];

    // Define a fixed set of colors for consistency
    const colors = [
      theme.palette.primary.main, // Primary blue
      theme.palette.secondary.main, // Secondary pink/purple
      theme.palette.success.main, // Green
      theme.palette.warning.main, // Orange/amber
      theme.palette.info.main, // Light blue
      theme.palette.error.main, // Red
      "#9c27b0", // Purple
      "#795548", // Brown
      "#607d8b", // Blue-grey
      "#009688", // Teal
    ];

    // Get city data
    const cityData = getCityData(timeRange);

    // Create a consistent color mapping for cities
    // Sort cities by count first to ensure most active cities always get the same colors
    const sortedCities = [...cityData].sort((a, b) => b[1].count - a[1].count);

    const cityColorMap = {};
    sortedCities.forEach(([city], index) => {
      cityColorMap[city] = colors[index % colors.length];
    });

    return sortedCities.slice(0, 10).map(([city, data]) => ({
      city,
      taps: data.count,
      color: cityColorMap[city],
    }));
  }, [timeRange, analyticsData, theme]);

  const pieChartData = useMemo(() => {
    if (!analyticsData) return [];

    // Get city data
    const cityData = getCityData(timeRange);

    // Sort cities by count to ensure consistency with barChartData
    const sortedCities = [...cityData]
      .sort((a, b) => b[1].count - a[1].count)
      .slice(0, 5);
    const total = sortedCities.reduce((sum, [_, data]) => sum + data.count, 0);

    // Use the same color mapping as barChartData for consistency
    const cityColorMap = {};
    barChartData.forEach((item) => {
      cityColorMap[item.city] = item.color;
    });

    let result = sortedCities.map(([city, data], index) => ({
      id: city,
      label: city,
      value: data.count,
      percentage: Math.round((data.count / total) * 100),
      color: cityColorMap[city],
    }));

    // Add "Other" category if there are more cities
    if (cityData.length > 5) {
      const otherTotal = cityData
        .slice(5)
        .reduce((sum, [_, data]) => sum + data.count, 0);

      if (otherTotal > 0) {
        result.push({
          id: "Other",
          label: "Other",
          value: otherTotal,
          percentage: Math.round((otherTotal / (total + otherTotal)) * 100),
          color: theme.palette.grey[500],
        });
      }
    }

    return result;
  }, [timeRange, analyticsData, theme, barChartData]);

  // Add the useEffect hook that depends on barChartData here
  useEffect(() => {
    // We don't need to generate CSS styles anymore since we're using DivIcons with direct styling
    // This useEffect can be removed or simplified

    // Clean up any existing styles that might have been added previously
    const existingStyle = document.getElementById("colored-marker-styles");
    if (existingStyle) {
      existingStyle.remove();
    }
  }, [barChartData]);

  // Calculate max value for bar chart
  const maxBarValue = useMemo(() => {
    if (barChartData.length === 0) return 0;
    return Math.max(...barChartData.map((item) => item.taps));
  }, [barChartData]);

  if (loading || isLoadingUserData) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="80vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <LoadingContainer>
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </LoadingContainer>
    );
  }

  // Create a preview version of the component for non-premium users
  const renderPreview = () => {
    return (
      <AnalyticsContainer maxWidth="xl">
        <Fade in={true} timeout={800}>
          <Box>
            <Box mb={4}>
              <Typography
                variant="h4"
                gutterBottom
                sx={{
                  fontWeight: 700,
                  color: theme.palette.text.primary,
                  textAlign: "center",
                  mb: 3,
                }}
              >
                Your Card Analytics
              </Typography>

              <Paper
                elevation={0}
                sx={{
                  p: 1,
                  display: "flex",
                  justifyContent: "center",
                  backgroundColor: theme.palette.background.paper,
                  borderRadius: 2,
                  mb: 4,
                }}
              >
                <Tabs
                  value={tabValue}
                  onChange={handleTabChange}
                  variant={isMobile ? "scrollable" : "fullWidth"}
                  scrollButtons={isMobile ? "auto" : false}
                  indicatorColor="primary"
                  textColor="primary"
                  sx={{
                    "& .MuiTab-root": {
                      minHeight: "48px",
                      fontWeight: 500,
                    },
                  }}
                >
                  <Tab
                    icon={<History />}
                    label={isMobile ? "" : "All Time"}
                    iconPosition="start"
                  />
                  <Tab
                    icon={<CalendarMonth />}
                    label={isMobile ? "" : "This Month"}
                    iconPosition="start"
                  />
                  <Tab
                    icon={<DateRange />}
                    label={isMobile ? "" : "This Week"}
                    iconPosition="start"
                  />
                  <Tab
                    icon={<Today />}
                    label={isMobile ? "" : "Today"}
                    iconPosition="start"
                  />
                </Tabs>
              </Paper>
            </Box>

            {/* Preview of analytics data */}
            <Grid container spacing={3} mb={4}>
              <Grid item xs={12} sm={6} md={4}>
                <StyledCard>
                  <CardContent sx={{ textAlign: "center", py: 3 }}>
                    <Avatar
                      sx={{
                        bgcolor: theme.palette.primary.main,
                        width: 56,
                        height: 56,
                        margin: "0 auto",
                        mb: 2,
                      }}
                    >
                      <TapAndPlay fontSize="large" />
                    </Avatar>
                    <StatLabel>Total Taps</StatLabel>
                    <StatValue>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <LockIcon
                          sx={{
                            mr: 1,
                            fontSize: "0.8em",
                            color: theme.palette.text.secondary,
                          }}
                        />
                        <Typography
                          component="span"
                          sx={{ color: theme.palette.text.secondary }}
                        >
                          Premium Feature
                        </Typography>
                      </Box>
                    </StatValue>
                    <Typography variant="caption" color="textSecondary">
                      {getTimeRangeTitle(timeRange)}
                    </Typography>
                  </CardContent>
                </StyledCard>
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <StyledCard>
                  <CardContent sx={{ textAlign: "center", py: 3 }}>
                    <Avatar
                      sx={{
                        bgcolor: theme.palette.secondary.main,
                        width: 56,
                        height: 56,
                        margin: "0 auto",
                        mb: 2,
                      }}
                    >
                      {getTimeRangeIcon(timeRange)}
                    </Avatar>
                    <StatLabel>Time Period</StatLabel>
                    <StatValue>{getTimeRangeTitle(timeRange)}</StatValue>
                    <Typography variant="caption" color="textSecondary">
                      Filter by different time periods
                    </Typography>
                  </CardContent>
                </StyledCard>
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <StyledCard>
                  <CardContent sx={{ textAlign: "center", py: 3 }}>
                    <Avatar
                      sx={{
                        bgcolor: theme.palette.success.main,
                        width: 56,
                        height: 56,
                        margin: "0 auto",
                        mb: 2,
                      }}
                    >
                      <LocationOn fontSize="large" />
                    </Avatar>
                    <StatLabel>Cities with Activity</StatLabel>
                    <StatValue>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <LockIcon
                          sx={{
                            mr: 1,
                            fontSize: "0.8em",
                            color: theme.palette.text.secondary,
                          }}
                        />
                        <Typography
                          component="span"
                          sx={{ color: theme.palette.text.secondary }}
                        >
                          Premium Feature
                        </Typography>
                      </Box>
                    </StatValue>
                    <Typography variant="caption" color="textSecondary">
                      Track locations of your taps
                    </Typography>
                  </CardContent>
                </StyledCard>
              </Grid>
            </Grid>

            {/* Paywall overlay */}
            <Box
              sx={{
                position: "relative",
                mt: 4,
                mb: 4,
                borderRadius: 2,
                overflow: "hidden",
              }}
            >
              {/* Blurred preview */}
              <Box
                sx={{
                  filter: "blur(8px)",
                  pointerEvents: "none",
                  opacity: 0.7,
                }}
              >
                <StyledMapContainer sx={{ height: "300px" }}>
                  <Box
                    sx={{
                      height: "100%",
                      width: "100%",
                      bgcolor: "background.paper",
                    }}
                  />
                </StyledMapContainer>

                <Grid container spacing={3} mt={2}>
                  <Grid item xs={12} md={6}>
                    <StyledCard>
                      <CardHeader
                        title={
                          <Box display="flex" alignItems="center">
                            <BarChart color="primary" sx={{ mr: 1 }} />
                            <Typography variant="h6" fontWeight={600}>
                              Top Cities by Taps
                            </Typography>
                          </Box>
                        }
                      />
                      <Divider />
                      <CardContent sx={{ height: "300px" }} />
                    </StyledCard>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <StyledCard>
                      <CardHeader
                        title={
                          <Box display="flex" alignItems="center">
                            <PieChart color="primary" sx={{ mr: 1 }} />
                            <Typography variant="h6" fontWeight={600}>
                              Tap Distribution
                            </Typography>
                          </Box>
                        }
                      />
                      <Divider />
                      <CardContent sx={{ height: "300px" }} />
                    </StyledCard>
                  </Grid>
                </Grid>
              </Box>

              {/* Upgrade overlay */}
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "rgba(0, 0, 0, 0.7)",
                  zIndex: 10,
                  p: 3,
                  borderRadius: 2,
                }}
              >
                <LockIcon
                  sx={{
                    fontSize: 60,
                    color: "white",
                    mb: 2,
                  }}
                />
                <Typography
                  variant="h5"
                  sx={{
                    color: "white",
                    fontWeight: 700,
                    mb: 2,
                    textAlign: "center",
                  }}
                >
                  Unlock Full Analytics with Premium
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    color: "white",
                    mb: 3,
                    textAlign: "center",
                    maxWidth: 600,
                  }}
                >
                  Get detailed insights about your card's performance with our
                  premium analytics. See where your card is being tapped, track
                  engagement over time, and make data-driven decisions.
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    mb: 3,
                    width: "100%",
                    maxWidth: 500,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      mb: 2,
                      width: "100%",
                    }}
                  >
                    <CheckCircleIcon
                      sx={{ color: theme.palette.success.main, mr: 2 }}
                    />
                    <Typography variant="body1" sx={{ color: "white" }}>
                      View detailed tap statistics across all time periods
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      mb: 2,
                      width: "100%",
                    }}
                  >
                    <CheckCircleIcon
                      sx={{ color: theme.palette.success.main, mr: 2 }}
                    />
                    <Typography variant="body1" sx={{ color: "white" }}>
                      See interactive maps showing where your card is being used
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      mb: 2,
                      width: "100%",
                    }}
                  >
                    <CheckCircleIcon
                      sx={{ color: theme.palette.success.main, mr: 2 }}
                    />
                    <Typography variant="body1" sx={{ color: "white" }}>
                      Track engagement trends with beautiful charts and graphs
                    </Typography>
                  </Box>
                </Box>

                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  component={Link}
                  to="/my-subscription"
                  startIcon={<StarIcon />}
                  sx={{
                    fontWeight: 600,
                    px: 4,
                    py: 1.5,
                  }}
                >
                  Upgrade to Bappa Premium
                </Button>
              </Box>
            </Box>
          </Box>
        </Fade>
      </AnalyticsContainer>
    );
  };

  return hasPremiumSubscription ? (
    <AnalyticsContainer maxWidth="xl">
      <Fade in={!loading} timeout={800}>
        <Box>
          <Box mb={4}>
            <Typography
              variant="h4"
              gutterBottom
              sx={{
                fontWeight: 700,
                color: theme.palette.text.primary,
                textAlign: "center",
                mb: 3,
              }}
            >
              Your Card Analytics
            </Typography>

            <Paper
              elevation={0}
              sx={{
                p: 1,
                display: "flex",
                justifyContent: "center",
                backgroundColor: theme.palette.background.paper,
                borderRadius: 2,
                mb: 4,
              }}
            >
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                variant={isMobile ? "scrollable" : "fullWidth"}
                scrollButtons={isMobile ? "auto" : false}
                indicatorColor="primary"
                textColor="primary"
                sx={{
                  "& .MuiTab-root": {
                    minHeight: "48px",
                    fontWeight: 500,
                  },
                }}
              >
                <Tab
                  icon={<History />}
                  label={isMobile ? "" : "All Time"}
                  iconPosition="start"
                />
                <Tab
                  icon={<CalendarMonth />}
                  label={isMobile ? "" : "This Month"}
                  iconPosition="start"
                />
                <Tab
                  icon={<DateRange />}
                  label={isMobile ? "" : "This Week"}
                  iconPosition="start"
                />
                <Tab
                  icon={<Today />}
                  label={isMobile ? "" : "Today"}
                  iconPosition="start"
                />
              </Tabs>
            </Paper>
          </Box>

          <Grid container spacing={3} mb={4}>
            <Grid item xs={12} sm={6} md={4}>
              <StyledCard>
                <CardContent sx={{ textAlign: "center", py: 3 }}>
                  <Avatar
                    sx={{
                      bgcolor: theme.palette.primary.main,
                      width: 56,
                      height: 56,
                      margin: "0 auto",
                      mb: 2,
                    }}
                  >
                    <TapAndPlay fontSize="large" />
                  </Avatar>
                  <StatLabel>Total Taps</StatLabel>
                  <StatValue>{getTotalTaps(timeRange)}</StatValue>
                  <Typography variant="caption" color="textSecondary">
                    {getTimeRangeTitle(timeRange)}
                  </Typography>
                </CardContent>
              </StyledCard>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <StyledCard>
                <CardContent sx={{ textAlign: "center", py: 3 }}>
                  <Avatar
                    sx={{
                      bgcolor: theme.palette.secondary.main,
                      width: 56,
                      height: 56,
                      margin: "0 auto",
                      mb: 2,
                    }}
                  >
                    {getTimeRangeIcon(timeRange)}
                  </Avatar>
                  <StatLabel>Time Period</StatLabel>
                  <StatValue>{getTimeRangeTitle(timeRange)}</StatValue>
                  <Typography variant="caption" color="textSecondary">
                    Tap statistics
                  </Typography>
                </CardContent>
              </StyledCard>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <StyledCard>
                <CardContent sx={{ textAlign: "center", py: 3 }}>
                  <Avatar
                    sx={{
                      bgcolor: theme.palette.success.main,
                      width: 56,
                      height: 56,
                      margin: "0 auto",
                      mb: 2,
                    }}
                  >
                    <LocationOn fontSize="large" />
                  </Avatar>
                  <StatLabel>Cities with Activity</StatLabel>
                  <StatValue>{getCityData(timeRange).length}</StatValue>
                  <Typography variant="caption" color="textSecondary">
                    Unique locations
                  </Typography>
                </CardContent>
              </StyledCard>
            </Grid>
          </Grid>

          {getCityData(timeRange).length > 0 && (
            <StyledMapContainer>
              <MapContainer
                center={[40, -95]}
                zoom={4}
                style={{ height: "100%" }}
              >
                <TileLayer
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />
                {getCityData(timeRange).map(([city, data], index) => {
                  const coordinates = data.coordinates
                    ? {
                        lat: data.coordinates.latitude,
                        lon: data.coordinates.longitude,
                      }
                    : null;

                  if (!coordinates) {
                    return null;
                  }

                  // Find the matching city in barChartData to get its color
                  const cityData = barChartData.find(
                    (item) => item.city === city
                  );
                  // Use the color from barChartData if available, otherwise use a default color
                  const markerColor = cityData
                    ? cityData.color
                    : theme.palette.primary.main;

                  return (
                    <Marker
                      key={index}
                      position={[coordinates.lat, coordinates.lon]}
                      icon={createColoredIcon(markerColor)}
                    >
                      <Popup>
                        <Typography variant="subtitle1" fontWeight={600}>
                          {city}
                        </Typography>
                        <Typography variant="body2">
                          {data.count} taps
                        </Typography>
                      </Popup>
                    </Marker>
                  );
                })}
              </MapContainer>
            </StyledMapContainer>
          )}

          {getCityData(timeRange).length > 0 && (
            <Box mb={3} sx={{ textAlign: "center" }}>
              <Typography
                variant="caption"
                color="text.secondary"
                sx={{ fontStyle: "italic" }}
              >
                <InfoOutlined
                  sx={{ fontSize: 14, verticalAlign: "text-bottom", mr: 0.5 }}
                />
                Disclaimer: Location data is approximate and may not always be
                accurate due to various factors including GPS limitations and
                privacy settings.
              </Typography>
            </Box>
          )}

          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <StyledCard>
                <CardHeader
                  title={
                    <Box display="flex" alignItems="center">
                      <BarChart color="primary" sx={{ mr: 1 }} />
                      <Typography variant="h6" fontWeight={600}>
                        Top Cities by Taps
                      </Typography>
                    </Box>
                  }
                />
                <Divider />
                <CardContent>
                  <ChartContainer>
                    {barChartData.length > 0 ? (
                      barChartData.map((item, index) => (
                        <BarChartItem key={index}>
                          <BarLabel>
                            <Typography
                              variant="body2"
                              sx={{
                                fontWeight: 500,
                                maxWidth: "70%",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {item.city}
                            </Typography>
                            <Typography
                              variant="body2"
                              sx={{
                                fontWeight: 700,
                                color: item.color,
                                backgroundColor: alpha(item.color, 0.1),
                                padding: "2px 8px",
                                borderRadius: "12px",
                                minWidth: "36px",
                                textAlign: "center",
                              }}
                            >
                              {item.taps}
                            </Typography>
                          </BarLabel>
                          <LinearProgress
                            variant="determinate"
                            value={(item.taps / maxBarValue) * 100}
                            sx={{
                              height: 12,
                              borderRadius: 6,
                              backgroundColor: alpha(item.color, 0.15),
                              "& .MuiLinearProgress-bar": {
                                borderRadius: 6,
                                backgroundColor: item.color,
                                backgroundImage: `linear-gradient(90deg, ${alpha(
                                  item.color,
                                  0.8
                                )} 0%, ${item.color} 100%)`,
                                boxShadow: `0 2px 5px ${alpha(
                                  item.color,
                                  0.3
                                )}`,
                              },
                            }}
                          />
                        </BarChartItem>
                      ))
                    ) : (
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        height="100%"
                      >
                        <Typography variant="body1" color="textSecondary">
                          No data available for this time period
                        </Typography>
                      </Box>
                    )}
                  </ChartContainer>
                </CardContent>
              </StyledCard>
            </Grid>

            <Grid item xs={12} md={6}>
              <StyledCard>
                <CardHeader
                  title={
                    <Box display="flex" alignItems="center">
                      <DonutLarge color="primary" sx={{ mr: 1 }} />
                      <Typography variant="h6" fontWeight={600}>
                        Tap Distribution by City
                      </Typography>
                    </Box>
                  }
                />
                <Divider />
                <CardContent>
                  <PieChartContainer>
                    {pieChartData.length > 0 ? (
                      <>
                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: 2,
                            mb: 2,
                          }}
                        >
                          {pieChartData.map((item, index) => (
                            <Paper
                              key={index}
                              elevation={0}
                              sx={{
                                p: 1.5,
                                flex: "1 0 calc(33% - 16px)",
                                minWidth: "120px",
                                border: "1px solid",
                                borderColor: theme.palette.divider,
                                borderRadius: 2,
                                textAlign: "center",
                                position: "relative",
                                overflow: "hidden",
                                "&::before": {
                                  content: '""',
                                  position: "absolute",
                                  top: 0,
                                  left: 0,
                                  width: "100%",
                                  height: "4px",
                                  backgroundColor: item.color,
                                },
                              }}
                            >
                              <Typography
                                variant="h6"
                                fontWeight={700}
                                color={item.color}
                              >
                                {item.percentage}%
                              </Typography>
                              <Typography
                                variant="body2"
                                noWrap
                                title={item.label}
                              >
                                {item.label.length > 12
                                  ? `${item.label.substring(0, 12)}...`
                                  : item.label}
                              </Typography>
                              <Typography
                                variant="caption"
                                color="textSecondary"
                              >
                                {item.value} taps
                              </Typography>
                            </Paper>
                          ))}
                        </Box>
                        <PieChartLegend>
                          {pieChartData.map((item, index) => (
                            <Chip
                              key={index}
                              icon={
                                <Box
                                  component="span"
                                  sx={{
                                    width: 14,
                                    height: 14,
                                    backgroundColor: item.color,
                                    borderRadius: "50%",
                                  }}
                                />
                              }
                              label={`${item.label}: ${item.percentage}%`}
                              variant="outlined"
                              size="small"
                              sx={{ m: 0.5 }}
                            />
                          ))}
                        </PieChartLegend>
                      </>
                    ) : (
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        height="100%"
                      >
                        <Typography variant="body1" color="textSecondary">
                          No data available for this time period
                        </Typography>
                      </Box>
                    )}
                  </PieChartContainer>
                </CardContent>
              </StyledCard>
            </Grid>

            <Grid item xs={12}>
              <StyledCard>
                <CardHeader
                  title={
                    <Box display="flex" alignItems="center">
                      <LocationOn color="primary" sx={{ mr: 1 }} />
                      <Typography variant="h6" fontWeight={600}>
                        {`Taps by City (${getTimeRangeTitle(timeRange)})`}
                      </Typography>
                    </Box>
                  }
                />
                <Divider />
                <CardContent sx={{ padding: theme.spacing(3) }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: 1.5,
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                    }}
                  >
                    {getCityData(timeRange).length > 0 ? (
                      getCityData(timeRange)
                        .slice(0, 20)
                        .map(([city, data], index) => {
                          // Find the matching city in barChartData to get its color
                          const cityData = barChartData.find(
                            (item) => item.city === city
                          );

                          // Use the color from barChartData if available
                          const chipColor = cityData
                            ? {
                                bgcolor: cityData.color,
                                color: theme.palette.getContrastText(
                                  cityData.color
                                ),
                              }
                            : index < 5
                            ? {
                                bgcolor: [
                                  theme.palette.primary.main,
                                  theme.palette.secondary.main,
                                  theme.palette.success.main,
                                  theme.palette.warning.main,
                                  theme.palette.info.main,
                                ][index],
                                color: theme.palette.getContrastText(
                                  [
                                    theme.palette.primary.main,
                                    theme.palette.secondary.main,
                                    theme.palette.success.main,
                                    theme.palette.warning.main,
                                    theme.palette.info.main,
                                  ][index]
                                ),
                              }
                            : null;

                          return (
                            <Chip
                              key={index}
                              icon={<LocationOn />}
                              label={`${city}: ${data.count}`}
                              color={
                                cityData || index < 5 ? undefined : "default"
                              }
                              variant={
                                cityData || index < 5 ? "filled" : "outlined"
                              }
                              sx={{
                                fontWeight: 500,
                                transition: "transform 0.2s ease",
                                "&:hover": {
                                  transform: "translateY(-3px)",
                                  boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                                },
                                ...(chipColor && {
                                  bgcolor: chipColor.bgcolor,
                                  color: chipColor.color,
                                }),
                              }}
                            />
                          );
                        })
                    ) : (
                      <Typography variant="body1" color="textSecondary">
                        No city data available for this time period
                      </Typography>
                    )}
                    {getCityData(timeRange).length > 20 && (
                      <Chip
                        icon={<MoreHoriz />}
                        label={`+${getCityData(timeRange).length - 20} more`}
                        color="secondary"
                      />
                    )}
                  </Box>
                </CardContent>
              </StyledCard>
            </Grid>
          </Grid>
        </Box>
      </Fade>
    </AnalyticsContainer>
  ) : (
    renderPreview()
  );
};

export default Analytics;
