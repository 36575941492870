import axios from "axios";

const DEV_API = "http://localhost:3000/api/v1";
const PROD_API = "https://api.bappacards.com/api/v1";

const API = PROD_API;

export const getUserAnalytics = async (token) => {
  try {
    const response = await get("users/analytics", undefined, token);
    return response.data;
  } catch (e) {
    return { status: 500, message: `Could not get user analytics: ${e}` };
  }
};

export const sendContactUs = async (data) => {
  try {
    const response = await post(data, "support/contact-us");
    return response;
  } catch (e) {
    return { status: 500, message: `Could not send contact us: ${e}` };
  }
};

export const createCheckoutProduct = async (data) => {
  try {
    const response = await post(data, "stripe/create-checkout-product");
    return response;
  } catch (e) {
    return { status: 500, message: `Could not create checkout product: ${e}` };
  }
};

export const createCheckoutSession = async (token, data) => {
  try {
    const response = await post(data, "stripe/create-checkout-session", token);
    return response;
  } catch (e) {
    return { status: 500, message: `Could not create checkout session: ${e}` };
  }
};

export const getCheckoutSession = async (sessionId) => {
  try {
    const response = await get(`stripe/checkout-session/${sessionId}`);
    return response;
  } catch (e) {
    return { status: 500, message: `Could not get checkout session: ${e}` };
  }
};

export const updateV3Contact = async (token, contactId, data) => {
  try {
    const response = await post(data, `contacts/${contactId}`, token);
    return response;
  } catch (e) {
    return { status: 500, message: `Could not update contact: ${e}` };
  }
};

export const deleteContact = async (token, contactId) => {
  try {
    const response = await deleteRequest(`contacts/${contactId}`, token);
    return response;
  } catch (e) {
    return { status: 500, message: `Could not delete contact: ${e}` };
  }
};

export const createV3Contact = async (token, data) => {
  try {
    const response = await post(data, "contacts", token);
    return response;
  } catch (e) {
    return { status: 500, message: `Could not create contact: ${e}` };
  }
};

export const createGmailUser = async (email) => {
  try {
    const response = await post({ email: email }, "users/gmail");
    return response;
  } catch (e) {
    return { status: 500, message: `Could not create gmail user: ${e}` };
  }
};

export const createUser = async (email, password, fullName = "") => {
  try {
    const response = await post(
      { email: email, password: password, fullName: fullName },
      "users"
    );
    return response;
  } catch (e) {
    return { status: 500, message: `Could not create user: ${e}` };
  }
};

export const createContact = async (profileId, body) => {
  try {
    const response = await post(body, `profiles/${profileId}/contacts`);
    return response;
  } catch (e) {
    return { status: 500, message: `Could not create contact: ${e}` };
  }
};

export const createCard = async (token) => {
  const response = await post(undefined, "cards", token);
  return response.data;
};

export const getMe = async (token) => {
  try {
    const response = await get("users/me", undefined, token);
    return response.data;
  } catch (e) {
    return { status: 404, message: `Could not get user: ${e}` };
  }
};

export const getProfile = async (profileId, token) => {
  try {
    const response = await get(`profiles/${profileId}`, undefined, token);
    return response;
  } catch (e) {
    return { status: 404, message: `Could not get profile: ${e}` };
  }
};

export const getProfiles = async (token) => {
  try {
    const response = await get(`profiles`, undefined, token);
    return response;
  } catch (e) {
    return { status: 404, message: `Could not get profiles: ${e}` };
  }
};

export const registerCard = async (cardId, token) => {
  try {
    const response = post({ card_id: cardId }, "cards/register", token);
    return response;
  } catch (e) {
    return { status: 403, message: `Could not register card: ${e}` };
  }
};

export const updateContact = async (token, data) => {
  try {
    const response = await post(data, "users/contacts", token);
    return response;
  } catch (e) {
    return { status: 500, message: `Could not update contact: ${e}` };
  }
};

export const getGroups = async (token) => {
  const response = await get("groups", undefined, token);
  return response.data;
};

export const getGroup = async (token, groupName) => {
  const response = await get(`groups/${groupName}`, undefined, token);
  return response.data;
};

export const updateGroup = async (token, groupName, data) => {
  const response = await post(data, `groups/${groupName}`, token);
  return response;
};

export const updateProfile = async (token, data) => {
  const response = await post(data, "users/links", token);
  return response;
};

export const updatePhoto = async (token, data) => {
  const response = await post(data, "users/links/photo", token, true);
  return response;
};

export const updateGroupPhoto = async (token, groupName, data) => {
  const response = await post(data, `groups/${groupName}/photo`, token, true);
  return response;
};

export const post = async (
  body,
  slug,
  token,
  isPhoto = false,
  isBinary = false
) => {
  let config = {};

  if (token && isPhoto) {
    config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Content-Type": "multipart/form-data",
      },
    };
  } else if (token && isBinary) {
    config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      responseType: "arraybuffer",
    };
  } else if (token) {
    config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
  } else {
    config = { headers: { "Content-Type": "application/json" } };
  }

  return axios
    .post(`${API}/${slug}`, body, config)
    .then((response) => {
      // For binary responses, create a Blob from the array buffer
      if (isBinary && response.data) {
        const blob = new Blob([response.data], {
          type: "application/vnd.apple.pkpass",
        });
        return {
          ...response,
          data: blob,
        };
      }
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};

export const deleteRequest = async (slug, token) => {
  const config = { headers: { Authorization: `Bearer ${token}` } };
  return axios.delete(`${API}/${slug}`, config);
};

export const get = async (slug, query, token, isBinary = false) => {
  let config = {};

  if (token && isBinary) {
    config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: "arraybuffer",
    };
  } else if (token) {
    config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  }

  try {
    let url = `${API}/${slug}`;
    if (query) {
      url = `${url}?${query}`;
    }

    const response = await axios.get(url, config);

    // For binary responses, create a Blob from the array buffer
    if (isBinary && response.data) {
      const blob = new Blob([response.data], {
        type: "application/vnd.apple.pkpass",
      });
      return {
        ...response,
        data: blob,
      };
    }

    return response;
  } catch (error) {
    // Return a properly formatted error response even when error.response is undefined
    return (
      error.response || {
        status: 500,
        message: `Request failed: ${error.message || "Connection error"}`,
      }
    );
  }
};

export const someAction = (payload) => ({
  type: "SOME_ACTION",
  payload,
});

export const createCustomerPortalSession = async (token) => {
  try {
    const response = await post({}, "stripe/create-portal-session", token);
    return response;
  } catch (e) {
    return {
      status: 500,
      message: `Could not create customer portal session: ${e}`,
    };
  }
};

export const generateAppleWalletPass = async (token) => {
  try {
    // Use the updated API that returns a URL
    const response = await get("passes/generate", undefined, token);

    console.log("Raw API response:", response);

    // Make sure we're returning a properly formatted response
    if (response && response.data) {
      return {
        status: response.status || 200,
        data: response.data,
      };
    }

    // If we have a response but no data, return a formatted error
    return {
      status: response.status || 500,
      message: response.message || "No data received from server",
    };
  } catch (e) {
    console.error("Error in generateAppleWalletPass:", e);
    return {
      status: 500,
      message: `Could not generate Apple Wallet pass: ${e}`,
    };
  }
};
