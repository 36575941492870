import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import QRCode from "qrcode";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const QrCodeModal = ({ open, handleClose, url }) => {
  const [qrCodeDataURL, setQRCodeDataURL] = useState("");
  useEffect(() => {
    const generateQRCode = async () => {
      try {
        if (!url) {
          return;
        }
        const dataURL = await QRCode.toDataURL(url);
        setQRCodeDataURL(dataURL);
      } catch (error) {
        console.error("Error generating QR code:", error);
      }
    };
    generateQRCode();
  }, [url]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          {qrCodeDataURL && (
            <img
              src={qrCodeDataURL}
              alt="QR Code"
              style={{ maxWidth: "100%" }}
            />
          )}
        </div>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          align="center"
          style={{
            paddingBottom: 20,
            fontWeight: "bold",
          }}
        >
          Scan the QR Code
        </Typography>

        <Typography
          id="modal-modal-title"
          variant="h8"
          component="h4"
          align="center"
          style={{
            paddingBottom: 20,
            fontWeight: "bold",
          }}
        >
          to view my Card on other devices
        </Typography>
      </Box>
    </Modal>
  );
};
export default QrCodeModal;
