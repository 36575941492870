import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Typography,
  Chip,
  CircularProgress,
  Snackbar,
  Alert,
  Paper,
  IconButton,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import * as action from "../../service/action";
import { motion } from "framer-motion";

import { useUserContext } from "../../pages/Login/userContext";
import ContactUs from "../../components/ContactUs";

const plansFormatted = {
  "bappa-premium": "Bappa Premium",
};

// Custom styled components
const StyledCard = styled(Card)(({ theme, featured }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  position: "relative",
  transition: "all 0.3s ease",
  backgroundColor: featured
    ? "linear-gradient(135deg, #0f0f23 0%, #1a1a3a 100%)"
    : "white",
  color: featured ? "white" : "inherit",
  borderRadius: "24px",
  boxShadow: featured
    ? "0 15px 50px rgba(0,0,0,0.15)"
    : "0 15px 50px rgba(0,0,0,0.05)",
  border: featured ? "none" : "1px solid rgba(0,0,0,0.03)",
  overflow: "hidden",
  transform: featured ? "scale(1.05)" : "scale(1)",
  zIndex: featured ? 1 : 0,
  "&:hover": {
    boxShadow: featured
      ? "0 25px 60px rgba(0,0,0,0.2)"
      : "0 25px 60px rgba(0,0,0,0.1)",
    transform: featured ? "scale(1.07)" : "scale(1.02)",
  },
}));

const plans = [
  {
    id: "bappa-standard",
    title: "Bappa Standard",
    subtitle: "For individuals getting started",
    description: "For individuals getting started with digital business cards",
    price: "Free",
    period: "forever",
    buttonText: "Get Started Now",
    headerBg:
      "linear-gradient(135deg, rgba(0,0,0,0.01) 0%, rgba(0,0,0,0.02) 100%)",
    buttonBg: "linear-gradient(45deg, #dc004e 30%, #ff3378 90%)",
    buttonHoverBg: "linear-gradient(45deg, #c80046 30%, #e92e6c 90%)",
    iconBg: "rgba(220, 0, 78, 0.1)",
    iconColor: "#dc004e",
    features: [
      "Basic Customization",
      "Share via Link or QR Code",
      "Standard Support",
    ],
  },
  {
    id: "bappa-premium",
    title: "Bappa Premium",
    subtitle: "For professionals who need more",
    description: "For professionals who need advanced features",
    price: "$5",
    period: "per month",
    buttonText: "Subscribe Now",
    featured: true,
    headerBg: "linear-gradient(135deg, #8B3DFF 0%, #A97FFF 100%)",
    buttonBg: "linear-gradient(45deg, #8B3DFF 30%, #A97FFF 90%)",
    buttonHoverBg: "linear-gradient(45deg, #7835D8 30%, #9E78F5 90%)",
    iconBg: "rgba(139, 61, 255, 0.1)",
    iconColor: "#8B3DFF",
    features: [
      "Advanced Customization",
      "Custom Branding",
      "Detailed Analytics",
      "Standard Support",
    ],
  },
  {
    id: "bappa-enterprise",
    title: "Bappa Enterprise",
    subtitle: "For organizations requiring custom solutions",
    description: "For organizations requiring custom solutions",
    price: "Contact Us",
    period: "for custom pricing",
    buttonText: "Contact Us",
    headerBg: "linear-gradient(135deg, #0f0f23 0%, #1a1a3a 100%)",
    buttonBg: "white",
    buttonHoverBg: "rgba(255,255,255,0.9)",
    iconBg: "rgba(255, 255, 255, 0.1)",
    iconColor: "white",
    darkBg: true,
    features: [
      "Discounted Business Cards",
      "Advanced Analytics",
      "Custom Integration",
      "Team Management",
      "Priority Support",
    ],
  },
];

// Component to display subscription management options
const ManageSubscription = ({ subscription, onManageClick, isLoading }) => {
  // Move all useState hooks to the top level of the component
  const [contactModalOpen, setContactModalOpen] = useState(false);

  if (!subscription) return null;

  const formatDate = (timestamp) => {
    if (!timestamp) return "N/A";
    const date = new Date(timestamp * 1000);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "active":
        return "success.main";
      case "past_due":
        return "warning.main";
      case "canceled":
        return "error.main";
      default:
        return "text.secondary";
    }
  };

  // Premium features
  const premiumFeatures = [
    "Advanced Customization",
    "Custom Branding",
    "Detailed Analytics",
    "Standard Support",
  ];

  return (
    <Box>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.4 }}
      >
        <Paper
          elevation={0}
          sx={{
            p: 4,
            borderRadius: "24px",
            background: "white",
            boxShadow: "0 15px 50px rgba(0,0,0,0.05)",
            border: "1px solid rgba(0,0,0,0.03)",
            mb: 4,
            overflow: "hidden",
          }}
        >
          <Typography
            variant="h4"
            sx={{
              mb: 3,
              fontWeight: "bold",
              background: "linear-gradient(90deg, #0f0f23 30%, #333 100%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            Your Subscription
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              gap: 4,
              mb: 4,
            }}
          >
            <Box sx={{ flex: 1 }}>
              <Typography
                variant="subtitle2"
                sx={{ color: "#666", mb: 1, fontWeight: 600 }}
              >
                Current Plan
              </Typography>
              <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
                {plansFormatted[subscription.plan] || subscription.plan}
              </Typography>
              <Chip
                label={subscription.status || "Active"}
                sx={{
                  bgcolor: getStatusColor(subscription.status || "active"),
                  color: "white",
                  fontWeight: "bold",
                  px: 1,
                  borderRadius: "12px",
                }}
              />
            </Box>

            <Box sx={{ flex: 1 }}>
              <Typography
                variant="subtitle2"
                sx={{ color: "#666", mb: 1, fontWeight: 600 }}
              >
                Billing Period
              </Typography>
              <Typography variant="body1" sx={{ mb: 3 }}>
                {subscription.current_period_start ? (
                  <>
                    {formatDate(subscription.current_period_start)} -{" "}
                    {formatDate(subscription.current_period_end)}
                  </>
                ) : (
                  "Monthly"
                )}
              </Typography>

              <Typography
                variant="subtitle2"
                sx={{ color: "#666", mb: 1, fontWeight: 600 }}
              >
                Next Payment
              </Typography>
              <Typography variant="body1">
                {subscription.current_period_end
                  ? formatDate(subscription.current_period_end)
                  : "N/A"}
              </Typography>
            </Box>
          </Box>

          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button
              variant="contained"
              onClick={onManageClick}
              disabled={isLoading}
              sx={{
                background: "linear-gradient(45deg, #8B3DFF 30%, #A97FFF 90%)",
                color: "white",
                py: 1.5,
                px: 3,
                textTransform: "none",
                fontSize: "1rem",
                fontWeight: 600,
                borderRadius: "12px",
                boxShadow: "0 6px 20px rgba(139, 61, 255, 0.25)",
                "&:hover": {
                  background:
                    "linear-gradient(45deg, #7835D8 30%, #9E78F5 90%)",
                  boxShadow: "0 8px 25px rgba(139, 61, 255, 0.3)",
                  transform: "translateY(-3px)",
                },
                transition: "all 0.3s ease",
              }}
            >
              {isLoading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Manage Subscription"
              )}
            </Button>
          </Box>
        </Paper>
      </motion.div>

      {/* Premium Benefits Section */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.4, delay: 0.1 }}
      >
        <Paper
          elevation={0}
          sx={{
            p: 4,
            borderRadius: "24px",
            background: "linear-gradient(135deg, #8B3DFF 0%, #A97FFF 100%)",
            color: "white",
            mb: 4,
            overflow: "hidden",
            boxShadow: "0 15px 50px rgba(139, 61, 255, 0.15)",
            position: "relative",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: "-50px",
              right: "-50px",
              width: "200px",
              height: "200px",
              borderRadius: "50%",
              background: "rgba(255, 255, 255, 0.1)",
              filter: "blur(30px)",
            }}
          />

          <Typography
            variant="h5"
            sx={{ mb: 3, fontWeight: "bold", position: "relative" }}
          >
            Your Premium Benefits
          </Typography>

          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: { xs: "1fr", md: "repeat(2, 1fr)" },
              gap: 3,
              position: "relative",
            }}
          >
            {premiumFeatures.map((feature) => (
              <Box
                key={feature}
                sx={{ display: "flex", alignItems: "center", gap: 2 }}
              >
                <Box
                  sx={{
                    width: "20px",
                    height: "20px",
                    borderRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "rgba(255, 255, 255, 0.2)",
                    fontSize: "0.8rem",
                    fontWeight: "bold",
                    flexShrink: 0,
                  }}
                >
                  ✓
                </Box>
                <Typography>{feature}</Typography>
              </Box>
            ))}
          </Box>
        </Paper>
      </motion.div>

      {/* Help Section */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.4, delay: 0.2 }}
      >
        <Paper
          elevation={0}
          sx={{
            p: 4,
            borderRadius: "24px",
            bgcolor: "white",
            boxShadow: "0 15px 50px rgba(0,0,0,0.05)",
            border: "1px solid rgba(0,0,0,0.03)",
          }}
        >
          <Typography variant="h5" sx={{ mb: 3, fontWeight: "bold" }}>
            Need Help?
          </Typography>
          <Typography
            variant="body1"
            sx={{ mb: 2, color: "#666", lineHeight: 1.6 }}
          >
            If you have any questions about your subscription or need
            assistance, our support team is here to help.
          </Typography>
          <Button
            variant="outlined"
            onClick={() => setContactModalOpen(true)}
            sx={{
              borderColor: "#dc004e",
              color: "#dc004e",
              textTransform: "none",
              fontWeight: 600,
              borderRadius: "12px",
              py: 1,
              "&:hover": {
                borderColor: "#e64a2e",
                bgcolor: "rgba(220, 0, 78, 0.04)",
                transform: "translateY(-3px)",
              },
              transition: "all 0.3s ease",
            }}
          >
            Contact Support
          </Button>

          {/* Use ContactUs component directly without wrapping it in another Dialog */}
          <ContactUs
            open={contactModalOpen}
            handleClose={() => setContactModalOpen(false)}
          />
        </Paper>
      </motion.div>
    </Box>
  );
};

// Component to display checkout response data
const CheckoutResponseDisplay = ({ data, onClose, userInfo }) => {
  if (!data) return null;

  return (
    <motion.div
      initial={{ opacity: 0, y: 50, scale: 0.9 }}
      animate={{ opacity: 1, y: 0, scale: 1 }}
      transition={{ duration: 0.4 }}
    >
      <Paper
        elevation={0}
        sx={{
          position: "fixed",
          bottom: 20,
          right: 20,
          zIndex: 1000,
          p: 4,
          maxWidth: 400,
          bgcolor: "white",
          borderRadius: "24px",
          boxShadow: "0 15px 50px rgba(0,0,0,0.15)",
          border: "1px solid rgba(0,0,0,0.03)",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "-50px",
            right: "-50px",
            width: "200px",
            height: "200px",
            borderRadius: "50%",
            background: "rgba(139, 61, 255, 0.05)",
            filter: "blur(30px)",
            zIndex: 0,
          }}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
            position: "relative",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontWeight: "bold",
              background: "linear-gradient(45deg, #8B3DFF 30%, #A97FFF 90%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            Subscription Confirmed!
          </Typography>
          <IconButton
            size="small"
            onClick={onClose}
            sx={{
              color: "#666",
              "&:hover": {
                color: "#333",
              },
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        {data.subscription && (
          <Box sx={{ mb: 3, position: "relative" }}>
            <Typography variant="body1" sx={{ mb: 2 }}>
              Thank you for subscribing to{" "}
              <strong>{plansFormatted[data.subscription.plan]}</strong> 🎉
            </Typography>
            <Typography variant="body2" sx={{ color: "#666" }}>
              Your subscription is now active and you can start enjoying all
              premium features right away.
            </Typography>
          </Box>
        )}

        {userInfo?.email && (
          <Box sx={{ mb: 2, position: "relative" }}>
            <Typography variant="body2" sx={{ color: "#666" }}>
              A confirmation email has been sent to {userInfo.email}
            </Typography>
          </Box>
        )}

        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={onClose}
          sx={{
            mt: 1,
            background: "linear-gradient(45deg, #8B3DFF 30%, #A97FFF 90%)",
            color: "white",
            textTransform: "none",
            fontWeight: 600,
            borderRadius: "12px",
            py: 1.5,
            boxShadow: "0 6px 20px rgba(139, 61, 255, 0.25)",
            "&:hover": {
              background: "linear-gradient(45deg, #7835D8 30%, #9E78F5 90%)",
              boxShadow: "0 8px 25px rgba(139, 61, 255, 0.3)",
              transform: "translateY(-3px)",
            },
            transition: "all 0.3s ease",
          }}
        >
          Start Exploring
        </Button>
      </Paper>
    </motion.div>
  );
};

const MySubscription = () => {
  const [selectedPlan, setSelectedPlan] = useState("standard");
  const [isLoading, setIsLoading] = useState(false);
  const [checkoutResponse, setCheckoutResponse] = useState(null);
  const [showResponseData, setShowResponseData] = useState(false);
  const [userData, setUserData] = useState(undefined);
  const [isManagingSubscription, setIsManagingSubscription] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [portalNotification, setPortalNotification] = useState({
    show: false,
    message: "",
    type: "info",
  });
  const [errorNotification, setErrorNotification] = useState({
    show: false,
    message: "",
  });
  const [contactModalOpen, setContactModalOpen] = useState(false);

  // Use the UserContext to get the user
  const { getUser } = useUserContext();
  const currentUser = getUser();

  const fetchData = useCallback(async () => {
    try {
      setIsPageLoading(true);
      const token = (await getUser()?.getIdToken()) || "token";
      const userDataResponse = await action.getMe(token);
      setUserData(userDataResponse);
    } catch (error) {
      console.error("Error fetching user data:", error);
    } finally {
      setIsPageLoading(false);
    }
  }, [getUser]);

  // Get the current user ID from the UserContext
  const getUserId = () => {
    const user = getUser();
    return user?.uid || "anonymous"; // Firebase uses uid instead of id
  };

  //Check for return status from Stripe on page load
  useEffect(() => {
    // Check if this is a redirect back from Stripe checkout
    const handleInitialLoad = async () => {
      if (userData === undefined) {
        await fetchData();
      }

      const query = new URLSearchParams(window.location.search);
      const redirectStatus = "succeeded"; //query.get("redirect_status");
      const sessionId = query.get("session_id");
      const portalReturn = query.get("portal_return");

      if (
        redirectStatus === "succeeded" &&
        sessionId &&
        userData?.subscription
      ) {
        // Fetch session details from your backend
        const fetchSessionDetails = async () => {
          try {
            const response = await action.getCheckoutSession(sessionId);

            if (response && response.data) {
              setCheckoutResponse({
                subscription: userData.subscription,
              });

              setShowResponseData(true);

              // Clean up the URL to remove query parameters
              window.history.replaceState(
                {},
                document.title,
                window.location.pathname
              );
            }
          } catch (error) {
            console.error("Error fetching session details:", error);
          }
        };

        fetchSessionDetails();
      } else if (portalReturn) {
        // Handle return from customer portal
        let message = "Your subscription has been updated.";
        let type = "success";

        if (portalReturn === "canceled") {
          message = "Your subscription has been canceled.";
          type = "info";
        } else if (portalReturn === "updated") {
          message = "Your subscription has been updated successfully.";
        }

        setPortalNotification({
          show: true,
          message,
          type,
        });

        // Clean up the URL to remove query parameters
        window.history.replaceState(
          {},
          document.title,
          window.location.pathname
        );

        // Refresh user data to get updated subscription status
        fetchData();
      }
    };

    handleInitialLoad();
  }, [userData, fetchData]);

  const handlePlanSelect = (planId) => {
    setSelectedPlan(planId);
  };

  const handleSubscribe = async (planId) => {
    if (planId === "bappa-premium") {
      try {
        setIsLoading(true);

        // Get the current user information
        const userId = getUserId();
        const user = getUser();
        const token = (await getUser()?.getIdToken()) || "token";

        // Create checkout session and redirect to Stripe
        const response = await action.createCheckoutSession(token, {
          product: "bappa-premium",
          mode: "subscription",
          email: user?.email || "",
          success_url:
            window.location.origin +
            window.location.pathname +
            "?redirect_status=succeeded&session_id={CHECKOUT_SESSION_ID}",
          cancel_url: window.location.origin + window.location.pathname,
          client_reference_id: userId,
          metadata: {
            planId: planId,
            source: "subscription_page",
          },
        });

        if (response && response.data && response.data.url) {
          // Redirect to Stripe Checkout
          window.location.href = response.data.url;
        } else {
          // Handle error response
          console.error("Failed to create checkout session:", response);
          const errorMessage =
            response?.data?.error ||
            "Failed to create checkout session. Please try again.";
          setErrorNotification({
            show: true,
            message: errorMessage,
          });
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error creating checkout session:", error);
        setErrorNotification({
          show: true,
          message: "An unexpected error occurred. Please try again later.",
        });
        setIsLoading(false);
      }
    } else if (planId === "bappa-enterprise") {
      // Open the contact modal for enterprise plan
      setContactModalOpen(true);
    }
  };

  const handleManageSubscription = async () => {
    try {
      setIsManagingSubscription(true);

      // Get the current user token
      const token = (await getUser()?.getIdToken()) || "token";

      // Create customer portal session and redirect to Stripe
      const response = await action.createCustomerPortalSession(token);

      if (response && response.data && response.data.url) {
        // Redirect to Stripe Customer Portal
        window.location.href = response.data.url;
      } else {
        console.error("Failed to create customer portal session:", response);
        setIsManagingSubscription(false);
      }
    } catch (error) {
      console.error("Error creating customer portal session:", error);
      setIsManagingSubscription(false);
    }
  };

  // Handle closing the response data display
  const handleCloseResponseData = () => {
    setShowResponseData(false);
  };

  // Handle closing the portal notification
  const handleClosePortalNotification = () => {
    setPortalNotification({ ...portalNotification, show: false });
  };

  // Handle closing the error notification
  const handleCloseErrorNotification = () => {
    setErrorNotification({ ...errorNotification, show: false });
  };

  // Check if user has an active subscription
  const hasActiveSubscription =
    userData?.subscription?.plan === "bappa-premium";

  // Loading state for the entire page
  if (isPageLoading) {
    return (
      <Box
        sx={{
          bgcolor: "#f5f5f5",
          py: 8,
          minHeight: "80vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "linear-gradient(135deg, #f6f6f8 0%, #f0f0f5 100%)",
        }}
      >
        <motion.div
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.4 }}
        >
          <Box sx={{ textAlign: "center" }}>
            <CircularProgress
              size={60}
              sx={{
                color: "#8B3DFF",
                mb: 3,
              }}
            />
            <Typography
              variant="h5"
              sx={{
                fontWeight: "medium",
                color: "#333",
                background: "linear-gradient(90deg, #0f0f23 30%, #333 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              Loading subscription information...
            </Typography>
          </Box>
        </motion.div>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        bgcolor: "#f5f5f5",
        py: 8,
        background: "linear-gradient(135deg, #f6f6f8 0%, #f0f0f5 100%)",
        position: "relative",
        overflow: "hidden",
      }}
    >
      {/* Decorative background elements */}
      <Box
        sx={{
          position: "absolute",
          top: "20%",
          right: "-10%",
          width: "400px",
          height: "400px",
          borderRadius: "50%",
          background:
            "radial-gradient(circle, rgba(15, 15, 35, 0.04) 0%, rgba(15, 15, 35, 0) 70%)",
          filter: "blur(50px)",
          zIndex: 0,
        }}
      />
      <Box
        sx={{
          position: "absolute",
          bottom: "-5%",
          left: "-5%",
          width: "300px",
          height: "300px",
          borderRadius: "50%",
          background:
            "radial-gradient(circle, rgba(220, 0, 78, 0.05) 0%, rgba(220, 0, 78, 0) 70%)",
          filter: "blur(60px)",
          zIndex: 0,
        }}
      />

      <Container maxWidth="lg" sx={{ position: "relative", zIndex: 1 }}>
        <Typography
          variant="h2"
          sx={{
            fontSize: { xs: "3rem", md: "4.5rem" },
            fontWeight: "bold",
            mb: 8,
            background: "linear-gradient(90deg, #0f0f23 30%, #333 100%)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            "& .dot": {
              color: "#dc004e",
            },
          }}
        >
          {hasActiveSubscription
            ? "Manage Your Subscription"
            : "Plans for Professionals at Every Scale"}
          <span className="dot">.</span>
        </Typography>

        {hasActiveSubscription ? (
          <ManageSubscription
            subscription={userData.subscription}
            onManageClick={handleManageSubscription}
            isLoading={isManagingSubscription}
          />
        ) : (
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: { xs: "1fr", md: "repeat(3, 1fr)" },
              gap: 4,
            }}
          >
            {plans.map((plan, index) => (
              <motion.div
                key={plan.id}
                whileHover={{ y: -12 }}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{
                  duration: 0.3,
                  delay: index * 0.1,
                  hover: { duration: 0.3 },
                }}
              >
                <Box
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    borderRadius: "24px",
                    background: plan.darkBg
                      ? "linear-gradient(135deg, #0f0f23 0%, #1a1a3a 100%)"
                      : "white",
                    overflow: "hidden",
                    boxShadow: plan.darkBg
                      ? "0 15px 50px rgba(0,0,0,0.15)"
                      : "0 15px 50px rgba(0,0,0,0.05)",
                    transition: "all 0.3s ease",
                    border: plan.darkBg ? "none" : "1px solid rgba(0,0,0,0.03)",
                    transform: plan.featured
                      ? { xs: "scale(1)", md: "scale(1.05)" }
                      : "scale(1)",
                    zIndex: plan.featured ? 1 : 0,
                    "&:hover": {
                      boxShadow: plan.darkBg
                        ? "0 25px 60px rgba(0,0,0,0.2)"
                        : "0 25px 60px rgba(0,0,0,0.1)",
                    },
                  }}
                  onClick={() => handlePlanSelect(plan.id)}
                >
                  <Box
                    sx={{
                      p: 4,
                      borderBottom: plan.darkBg
                        ? "1px solid rgba(255,255,255,0.1)"
                        : "1px solid rgba(0,0,0,0.05)",
                      background: plan.headerBg || "transparent",
                      color: plan.featured || plan.darkBg ? "white" : "inherit",
                    }}
                  >
                    {plan.featured && (
                      <Box
                        sx={{
                          display: "inline-block",
                          px: 2,
                          py: 0.5,
                          bgcolor: "rgba(255,255,255,0.2)",
                          borderRadius: "20px",
                          fontSize: "0.8rem",
                          fontWeight: "bold",
                          mb: 2,
                        }}
                      >
                        Most Popular
                      </Box>
                    )}
                    <Typography
                      variant="h3"
                      sx={{
                        fontSize: "2rem",
                        fontWeight: "700",
                        color:
                          plan.featured || plan.darkBg ? "white" : "#0f0f23",
                        mb: 1,
                      }}
                    >
                      {plan.title}
                    </Typography>
                    <Typography
                      sx={{
                        color: plan.featured ? "white" : "#dc004e",
                        fontWeight: "600",
                        mb: 1,
                        fontSize: "1.1rem",
                      }}
                    >
                      {plan.subtitle}
                    </Typography>
                  </Box>

                  <Box sx={{ p: 4, flexGrow: 1 }}>
                    <Typography
                      sx={{
                        fontSize: "1rem",
                        color: plan.darkBg ? "rgba(255,255,255,0.8)" : "#666",
                        mb: 4,
                        lineHeight: 1.6,
                      }}
                    >
                      {plan.description}
                    </Typography>

                    <Box sx={{ mb: 4 }}>
                      {plan.features.map((feature) => (
                        <Box
                          key={feature}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 2,
                            mb: 2,
                          }}
                        >
                          <Box
                            sx={{
                              width: "20px",
                              height: "20px",
                              borderRadius: "50%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              backgroundColor:
                                plan.iconBg || "rgba(220, 0, 78, 0.1)",
                              color: plan.iconColor || "#dc004e",
                              fontSize: "0.8rem",
                              fontWeight: "bold",
                              flexShrink: 0,
                            }}
                          >
                            ✓
                          </Box>
                          <Typography
                            sx={{
                              color: plan.darkBg
                                ? "rgba(255,255,255,0.8)"
                                : "#444",
                              fontSize: "0.95rem",
                            }}
                          >
                            {feature}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  </Box>

                  <Box sx={{ p: 4, pt: 0, mt: "auto" }}>
                    <Box sx={{ textAlign: "center", mb: 4 }}>
                      <Typography
                        variant="h3"
                        sx={{
                          fontSize: "2.5rem",
                          fontWeight: "bold",
                          color: plan.darkBg ? "white" : "#0f0f23",
                        }}
                      >
                        {plan.price}
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          color: plan.darkBg ? "rgba(255,255,255,0.6)" : "#666",
                        }}
                      >
                        {plan.period}
                      </Typography>
                    </Box>

                    <Button
                      variant="contained"
                      fullWidth
                      onClick={(e) => {
                        e.stopPropagation();
                        handleSubscribe(plan.id);
                      }}
                      disabled={
                        (isLoading && plan.id === "bappa-premium") ||
                        userData?.subscription?.plan === plan.id ||
                        (!userData?.subscription &&
                          plan.id === "bappa-standard")
                      }
                      sx={{
                        background: plan.buttonBg || "#dc004e",
                        color:
                          plan.darkBg || plan.featured ? "#0f0f23" : "white",
                        boxShadow: `0 6px 20px ${
                          plan.darkBg
                            ? "rgba(255,255,255,0.15)"
                            : plan.featured
                            ? "rgba(139, 61, 255, 0.25)"
                            : "rgba(220, 0, 78, 0.25)"
                        }`,
                        py: 1.5,
                        textTransform: "none",
                        fontSize: "1.1rem",
                        fontWeight: "600",
                        borderRadius: "12px",
                        "&:hover": {
                          background: plan.buttonHoverBg || "#e64a2e",
                          boxShadow: `0 8px 25px ${
                            plan.darkBg
                              ? "rgba(255,255,255,0.2)"
                              : plan.featured
                              ? "rgba(139, 61, 255, 0.3)"
                              : "rgba(220, 0, 78, 0.3)"
                          }`,
                          transform: "translateY(-3px)",
                        },
                        transition: "all 0.3s ease",
                        "&.Mui-disabled": {
                          bgcolor:
                            plan.id === "bappa-standard" &&
                            !userData?.subscription
                              ? "rgba(139, 61, 255, 0.7)"
                              : "rgba(220, 0, 78, 0.3)",
                          color: "white",
                          boxShadow:
                            plan.id === "bappa-standard" &&
                            !userData?.subscription
                              ? "0 6px 20px rgba(139, 61, 255, 0.25)"
                              : "none",
                        },
                      }}
                    >
                      {isLoading && plan.id === "bappa-premium" ? (
                        <CircularProgress size={24} color="inherit" />
                      ) : userData?.subscription?.plan === plan.id ? (
                        "Current Plan"
                      ) : !userData?.subscription &&
                        plan.id === "bappa-standard" ? (
                        "Current Plan"
                      ) : (
                        plan.buttonText
                      )}
                    </Button>

                    {plan.id === "bappa-standard" && (
                      <Typography
                        sx={{
                          fontSize: "0.85rem",
                          color: "#666",
                          textAlign: "center",
                          mt: 2,
                        }}
                      >
                        {!userData?.subscription
                          ? "Your current free plan"
                          : "Forever free"}
                      </Typography>
                    )}

                    {plan.id === "bappa-premium" && (
                      <Typography
                        sx={{
                          fontSize: "0.85rem",
                          color: "#666",
                          textAlign: "center",
                          mt: 2,
                        }}
                      >
                        Premium features and support
                      </Typography>
                    )}

                    {plan.id === "bappa-enterprise" && (
                      <Typography
                        sx={{
                          fontSize: "0.85rem",
                          color: plan.darkBg ? "rgba(255,255,255,0.6)" : "#666",
                          textAlign: "center",
                          mt: 2,
                        }}
                      >
                        Custom enterprise-grade solutions
                      </Typography>
                    )}
                  </Box>
                </Box>
              </motion.div>
            ))}
          </Box>
        )}
      </Container>

      {/* Checkout Response Display */}
      {showResponseData && checkoutResponse && (
        <CheckoutResponseDisplay
          data={checkoutResponse}
          onClose={handleCloseResponseData}
          userInfo={currentUser}
        />
      )}

      {/* Contact Us Modal */}
      <ContactUs
        open={contactModalOpen}
        handleClose={() => setContactModalOpen(false)}
      />

      {/* Portal Return Notification */}
      <Snackbar
        open={portalNotification.show}
        autoHideDuration={6000}
        onClose={handleClosePortalNotification}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleClosePortalNotification}
          severity={portalNotification.type}
          sx={{
            width: "100%",
            borderRadius: "12px",
            boxShadow: "0 8px 25px rgba(0,0,0,0.1)",
          }}
        >
          {portalNotification.message}
        </Alert>
      </Snackbar>

      {/* Error Notification */}
      <Snackbar
        open={errorNotification.show}
        autoHideDuration={6000}
        onClose={handleCloseErrorNotification}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseErrorNotification}
          severity="error"
          sx={{
            width: "100%",
            borderRadius: "12px",
            boxShadow: "0 8px 25px rgba(0,0,0,0.1)",
          }}
        >
          {errorNotification.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default MySubscription;
