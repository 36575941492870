import React, { useState, useEffect } from "react";
import { useUserContext } from "../Login/userContext";
import "antd/es/modal/style";
import "antd/es/slider/style";

import {
  TextField,
  Button,
  Container,
  Grid,
  Typography,
  Box,
  Switch,
  FormControlLabel,
  Select,
  MenuItem,
  Paper,
  IconButton,
  Stack,
  useTheme,
  useMediaQuery,
  Snackbar,
  Alert,
  Fab,
  Dialog,
  DialogContent,
  DialogTitle,
  Tabs,
  Tab,
  Card,
  CardContent,
  Tooltip,
  alpha,
  Divider,
  FormHelperText,
} from "@mui/material";

import { CirclePicker } from "react-color";
import ImgCrop from "antd-img-crop";
import { Upload } from "antd";
import ProfilePreview from "./ProfilePreview";
import RegisterCard from "../Dashboard/RegisterCard";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import LanguageIcon from "@mui/icons-material/Language";
import * as action from "../../service/action";
import LoadingButton from "@mui/lab/LoadingButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CloseIcon from "@mui/icons-material/Close";
import { Slide } from "@mui/material";
import { message } from "antd";
import PersonIcon from "@mui/icons-material/Person";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import ShareIcon from "@mui/icons-material/Share";
import SettingsIcon from "@mui/icons-material/Settings";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import SaveIcon from "@mui/icons-material/Save";
import BusinessIcon from "@mui/icons-material/Business";
import AppleIcon from "@mui/icons-material/Apple";
import AppleWalletModal from "./AppleWalletModal";
import AppleWalletButton from "../../components/AppleWalletButton";

// Import icons
import instagramIcon from "../../images/instagram-color-icon.png";
import twitterIcon from "../../images/twitter-color-icon.png";
import youtubeIcon from "../../images/youtube-color-icon.png";
import linkedinIcon from "../../images/linkedin-app-icon.png";
import snapchatIcon from "../../images/snapchat-color-icon.png";
import tiktokIcon from "../../images/tiktok-color-icon.png";
import facebookIcon from "../../images/facebook-round-color-icon.png";
import whatsappIcon from "../../images/whatsapp-round-color-icon.png";
import cashappIcon from "../../images/cash-app-icon.png";
import venmoIcon from "../../images/venmo-icon.png";
import paypalIcon from "../../images/paypal-icon.png";

// Define SortableItem as a top-level component
const SortableItem = ({
  platform,
  index,
  formData,
  handleSocialChange,
  removeSocialField,
  addSocialField,
  socialIcons,
  activeId,
  validationErrors,
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: platform });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.6 : 1,
    zIndex: isDragging ? 1 : 0,
    position: "relative",
  };

  return (
    <Card
      ref={setNodeRef}
      style={style}
      variant="outlined"
      sx={{
        mb: 3,
        borderRadius: 2,
        boxShadow: isDragging ? 3 : 0,
        borderColor: isDragging ? theme.palette.primary.main : "inherit",
      }}
    >
      <CardContent sx={{ p: isMobile ? 2 : 3 }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            mb: 2,
          }}
        >
          <div
            {...attributes}
            {...listeners}
            style={{
              cursor: "grab",
              padding: isMobile ? "8px" : "4px",
              marginRight: isMobile ? "4px" : "0",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              touchAction: "none", // Important for mobile drag
            }}
          >
            <DragIndicatorIcon
              color="action"
              sx={{
                fontSize: isMobile ? 28 : 24,
              }}
            />
          </div>
          {typeof socialIcons[platform] === "object" ? (
            socialIcons[platform]
          ) : (
            <img
              src={socialIcons[platform]}
              alt={platform}
              style={{
                width: 24,
                height: 24,
                marginRight: 8,
                marginLeft: 8,
              }}
            />
          )}
          <Typography variant="subtitle1" sx={{ ml: 1, fontWeight: "medium" }}>
            {platform === "website"
              ? "Website"
              : platform.charAt(0).toUpperCase() + platform.slice(1)}
          </Typography>
        </Box>

        {formData.socials[platform].map((value, fieldIndex) => (
          <Box
            key={`${platform}-${fieldIndex}`}
            sx={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              alignItems: isMobile ? "stretch" : "center",
              mb: 2,
            }}
          >
            {platform === "website" ? (
              <>
                <TextField
                  key={`${platform}-${fieldIndex}-name`}
                  sx={{
                    mr: isMobile ? 0 : 1,
                    mb: isMobile ? 1 : 0,
                    width: isMobile ? "100%" : "auto",
                    flex: isMobile ? "none" : 1,
                  }}
                  label={`Name ${fieldIndex + 1}`}
                  value={value.name}
                  onChange={(e) =>
                    handleSocialChange(
                      platform,
                      fieldIndex,
                      "name",
                      e.target.value
                    )
                  }
                  fullWidth={!isMobile}
                  variant="outlined"
                  size="small"
                />
                <TextField
                  key={`${platform}-${fieldIndex}-url`}
                  draggable={true}
                  sx={{
                    mr: isMobile ? 0 : 1,
                    mb: isMobile ? 1 : 0,
                    width: isMobile ? "100%" : "auto",
                    flex: isMobile ? "none" : 1,
                  }}
                  label={`URL ${fieldIndex + 1}`}
                  value={value.url}
                  onChange={(e) =>
                    handleSocialChange(
                      platform,
                      fieldIndex,
                      "url",
                      e.target.value
                    )
                  }
                  fullWidth={!isMobile}
                  variant="outlined"
                  size="small"
                />
              </>
            ) : (
              <TextField
                key={`${platform}-${fieldIndex}-value`}
                fullWidth
                label={`${platform} ${
                  platform === "whatsapp"
                    ? "number"
                    : platform === "youtube"
                    ? "url"
                    : platform === "facebook"
                    ? "username"
                    : platform === "instagram"
                    ? "username"
                    : platform === "twitter"
                    ? "username"
                    : platform === "linkedin"
                    ? "url"
                    : platform === "tiktok"
                    ? "username"
                    : ""
                }`}
                value={value}
                onChange={(e) =>
                  handleSocialChange(platform, fieldIndex, null, e.target.value)
                }
                variant="outlined"
                size="small"
                sx={{
                  mb: isMobile ? 1 : 0,
                  flex: 1,
                }}
                error={validationErrors[platform]?.[fieldIndex] !== undefined}
                helperText={validationErrors[platform]?.[fieldIndex]}
              />
            )}
            <Tooltip title="Remove">
              <IconButton
                onClick={() => removeSocialField(platform, fieldIndex)}
                color="error"
                size="small"
                sx={{
                  ml: isMobile ? 0 : 1,
                  alignSelf: isMobile ? "flex-end" : "center",
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Box>
        ))}
        <Button
          startIcon={<AddIcon />}
          onClick={() => addSocialField(platform)}
          variant="outlined"
          size="small"
          sx={{ mt: 1 }}
        >
          Add another {platform === "website" ? "website" : platform}
        </Button>
      </CardContent>
    </Card>
  );
};

// Tab panel component for the tabbed interface
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`profile-tabpanel-${index}`}
      aria-labelledby={`profile-tab-${index}`}
      {...other}
      style={{ padding: isMobile ? "12px 0" : "16px 0" }}
    >
      {value === index && children}
    </div>
  );
}

const ManageProfile = ({ userData }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [activeTab, setActiveTab] = useState(0);

  // Check if user is on iOS device
  const [isIOS, setIsIOS] = useState(false);

  useEffect(() => {
    // Detect iOS devices
    const userAgent = window.navigator.userAgent.toLowerCase();
    setIsIOS(/iphone|ipad|ipod|macintosh/.test(userAgent));
  }, []);

  const { getUser } = useUserContext();

  const [profileFileList, setProfileFileList] = useState(
    userData.links.profile_photo
      ? [
          {
            uid: "-1",
            name: "profile.jpg",
            status: "done",
            url: userData.links.profile_photo,
          },
        ]
      : []
  );

  const [coverFileList, setCoverFileList] = useState(
    userData.links.background_photo
      ? [
          {
            uid: "-1",
            name: "cover.jpg",
            status: "done",
            url: userData.links.background_photo,
          },
        ]
      : []
  );

  const [companyPhotoFileList, setCompanyPhotoFileList] = useState(
    userData.links.company_photo
      ? [
          {
            uid: "-1",
            name: "company_logo.jpg",
            status: "done",
            url: userData.links.company_photo,
          },
        ]
      : []
  );

  const [formData, setFormData] = useState({
    cardName: "",
    firstName: "",
    lastName: "",
    title: "",
    email: "",
    phone: "",
    cardTheme: "#4caf50",
    linkColor: "#000000",
    matchLinkIcons: false,
    font: "DEFAULT",
    profilePhoto: undefined,
    coverPhoto: undefined,
    companyPhoto: undefined,
    links: userData.links || {},
    socials: {
      website: [{ name: "", url: "" }], // Updated structure for websites
      instagram: [""],
      twitter: [""],
      linkedin: [""],
      snapchat: [""],
      tiktok: [""],
      facebook: [""],
      youtube: [""],
      whatsapp: [""],
      cashapp: [""],
      paypal: [""],
      venmo: [""],
    },
    socialOrder: [
      "website",
      "instagram",
      "twitter",
      "linkedin",
      "snapchat",
      "tiktok",
      "facebook",
      "youtube",
      "whatsapp",
      "cashapp",
      "paypal",
      "venmo",
    ],
  });

  const [profilePhoto, setProfilePhoto] = useState(undefined);
  const [coverPhoto, setCoverPhoto] = useState(undefined);
  const [companyPhoto, setCompanyPhoto] = useState(undefined);
  const [openRegisterCard, setOpenRegisterCard] = useState(false);
  const [preferences, setPreferences] = useState(userData.links.preferences);
  const [redirectEnabled, setRedirectEnabled] = useState(
    userData.links.custom_redirect != null &&
      userData.subscription?.status === "active"
  );

  //   const [customRedirect, setCustomRedirect] = useState(
  //     userData.links.custom_redirect
  //   );

  const [redirectLink, setRedirectLink] = useState(
    userData.links.custom_redirect
  );

  const [isUpdating, setIsUpdating] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const [previewOpen, setPreviewOpen] = useState(false);
  const [appleWalletModalOpen, setAppleWalletModalOpen] = useState(false);

  const socialIcons = {
    website: <LanguageIcon />, // Use a generic icon for websites
    instagram: instagramIcon,
    twitter: twitterIcon,
    linkedin: linkedinIcon,
    snapchat: snapchatIcon,
    tiktok: tiktokIcon,
    facebook: facebookIcon,
    youtube: youtubeIcon,
    whatsapp: whatsappIcon,
    cashapp: cashappIcon,
    venmo: venmoIcon,
    paypal: paypalIcon,
  };

  const [validationErrors, setValidationErrors] = useState({});

  useEffect(() => {
    if (userData) {
      setFormData({
        id: userData.profile_id,
        cardName: userData.cardName || "",
        firstName: userData.links.firstname || "",
        lastName: userData.links.lastname || "",
        title: userData.links.title || "",
        email: userData.links.email || "",
        phone: userData.links.phone_number || "",
        cardTheme: userData?.links?.preferences?.card_theme || "#4caf50",
        linkColor: userData?.links?.preferences?.link_color || "#000000",
        matchLinkIcons: userData.matchLinkIcons || false,
        font: userData?.links?.preferences?.font || "DEFAULT",
        profilePhoto: userData.links.profile_photo,
        coverPhoto: userData.links.background_photo,
        companyPhoto: userData.links.company_photo,
        profileUrl: "",
        links: userData.links || {},
        socials: {
          website: userData.links.websites || [""], // Add websites to socials
          instagram: userData.links.instagram_usernames || [""],
          twitter: userData.links.twitter_usernames || [""],
          linkedin: userData.links.linkedin_urls || [""],
          snapchat: userData.links.snapchat_usernames || [""],
          tiktok: userData.links.tiktok_usernames || [""],
          facebook: userData.links.facebook_urls || [""],
          youtube: userData.links.youtube_urls || [""],
          whatsapp: userData.links.whatsapp_numbers || [""],
          cashapp: userData.links.cashapp_usernames || [""],
          paypal: userData.links.paypal_usernames || [""],
          venmo: userData.links.venmo_usernames || [""],
        },
        socialOrder:
          userData.links.social_order && userData.links.social_order.length > 0
            ? userData.links.social_order
            : [
                "website",
                "instagram",
                "twitter",
                "linkedin",
                "snapchat",
                "tiktok",
                "facebook",
                "youtube",
                "whatsapp",
                "cashapp",
                "paypal",
                "venmo",
              ],
      });
    }
  }, [userData]);

  const handleProfileChange = ({ fileList: newFileList }) => {
    if (newFileList.length > 0) {
      const currentFile = newFileList[newFileList.length - 1];

      // Create preview URL from the file object
      let previewUrl;
      if (currentFile.originFileObj) {
        previewUrl = URL.createObjectURL(currentFile.originFileObj);
        currentFile.url = previewUrl; // Update the URL in the file list
      } else {
        previewUrl = currentFile.url;
      }

      setProfileFileList(newFileList);
      setProfilePhoto(previewUrl);
      setFormData((prevData) => ({
        ...prevData,
        profilePhoto: currentFile.originFileObj || currentFile,
      }));
    } else {
      setProfileFileList([]);
      setProfilePhoto(undefined);
      setFormData((prevData) => ({
        ...prevData,
        profilePhoto: undefined,
      }));
    }
  };

  const handleCoverChange = ({ fileList: newFileList }) => {
    if (newFileList.length > 0) {
      const currentFile = newFileList[newFileList.length - 1];

      // Create preview URL from the file object
      let previewUrl;
      if (currentFile.originFileObj) {
        previewUrl = URL.createObjectURL(currentFile.originFileObj);
        currentFile.url = previewUrl; // Update the URL in the file list
      } else {
        previewUrl = currentFile.url;
      }

      setCoverFileList(newFileList);
      setCoverPhoto(previewUrl);
      setFormData((prevData) => ({
        ...prevData,
        coverPhoto: currentFile.originFileObj || currentFile,
      }));
    } else {
      setCoverFileList([]);
      setCoverPhoto(undefined);
      setFormData((prevData) => ({
        ...prevData,
        coverPhoto: undefined,
      }));
    }
  };

  const handleCompanyPhotoChange = ({ fileList: newFileList }) => {
    // Check if user has an active subscription
    if (userData.subscription?.status !== "active") {
      // Show subscription required message
      setSnackbar({
        open: true,
        message: "Company photo upload requires Bappa Premium subscription",
        severity: "info",
      });
      return;
    }

    if (newFileList.length > 0) {
      const currentFile = newFileList[newFileList.length - 1];

      // Create preview URL from the file object
      let previewUrl;
      if (currentFile.originFileObj) {
        previewUrl = URL.createObjectURL(currentFile.originFileObj);
        currentFile.url = previewUrl; // Update the URL in the file list
      } else {
        previewUrl = currentFile.url;
      }

      setCompanyPhotoFileList(newFileList);
      setCompanyPhoto(previewUrl);
      setFormData((prevData) => ({
        ...prevData,
        companyPhoto: currentFile.originFileObj || currentFile,
      }));
    } else {
      setCompanyPhotoFileList([]);
      setCompanyPhoto(undefined);
      setFormData((prevData) => ({
        ...prevData,
        companyPhoto: undefined,
      }));
    }
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const validateUrl = (url, platform) => {
    if (!url || url.trim() === "") return true; // Allow empty values

    try {
      // First try to create a URL object to check basic URL validity
      const urlObj = new URL(url);

      // Ensure the URL has a valid protocol
      // if (!["http:", "https:"].includes(urlObj.protocol)) {
      //   return false;
      // }

      // Platform-specific validation
      if (platform === "youtube") {
        // Check for valid YouTube domains
        const validYouTubeDomains = [
          "youtube.com",
          "www.youtube.com",
          "youtu.be",
        ];
        return validYouTubeDomains.some(
          (domain) =>
            urlObj.hostname === domain || urlObj.hostname.endsWith("." + domain)
        );
      } else if (platform === "linkedin") {
        // Check for valid LinkedIn domains and path structure
        const validLinkedInDomains = ["linkedin.com", "www.linkedin.com"];
        const isValidDomain = validLinkedInDomains.some(
          (domain) =>
            urlObj.hostname === domain || urlObj.hostname.endsWith("." + domain)
        );

        // LinkedIn URLs typically have a structure like linkedin.com/in/username
        // or other valid paths like /company/, /school/, etc.
        const hasValidPath = urlObj.pathname.length > 1; // At least something after the /

        return isValidDomain && hasValidPath;
      }

      return true;
    } catch (e) {
      // If URL parsing fails, it's not a valid URL
      return false;
    }
  };

  const handleUpdateProfile = async () => {
    // Validate all URLs before submitting
    let hasErrors = false;
    const newErrors = {};

    // Validate YouTube URLs
    formData.socials.youtube.forEach((url, index) => {
      if (url && !validateUrl(url, "youtube")) {
        if (!newErrors.youtube) newErrors.youtube = {};
        newErrors.youtube[index] =
          "Please enter a valid YouTube URL (e.g., https://youtube.com/...)";
        hasErrors = true;
      }
    });

    // Validate LinkedIn URLs
    formData.socials.linkedin.forEach((url, index) => {
      if (url && !validateUrl(url, "linkedin")) {
        if (!newErrors.linkedin) newErrors.linkedin = {};
        newErrors.linkedin[index] =
          "Please enter a valid LinkedIn URL (e.g., https://linkedin.com/in/...)";
        hasErrors = true;
      }
    });

    // Check for website URLs in the website field
    formData.socials.website.forEach((website, index) => {
      if (website.url && !validateUrl(website.url)) {
        if (!newErrors.website) newErrors.website = {};
        newErrors.website[index] =
          "Please enter a valid URL including http:// or https://";
        hasErrors = true;
      }
    });

    setValidationErrors(newErrors);

    if (hasErrors) {
      setSnackbar({
        open: true,
        message: "Please fix the validation errors before saving",
        severity: "error",
      });
      return;
    }

    setIsUpdating(true);

    try {
      const data = {
        firstname: formData.firstName,
        lastname: formData.lastName,
        title: formData.title,
        email: formData.email,
        phone_number: formData.phone,
        instagram_usernames: formData.socials.instagram,
        twitter_usernames: formData.socials.twitter,
        snapchat_usernames: formData.socials.snapchat,
        tiktok_usernames: formData.socials.tiktok,
        facebook_urls: formData.socials.facebook,
        youtube_urls: formData.socials.youtube,
        linkedin_urls: formData.socials.linkedin,
        whatsapp_numbers: formData.socials.whatsapp,
        cashapp_usernames: formData.socials.cashapp,
        paypal_usernames: formData.socials.paypal,
        venmo_usernames: formData.socials.venmo,
        websites: formData.socials.website,
        custom_redirect: redirectLink,
        //preferences: formData.preferences,
        social_order: formData.socialOrder,
        preferences: {
          ...preferences,
          card_theme: formData.cardTheme,
          link_color: formData.linkColor,
        },
      };

      let response = await action.updateProfile(
        await getUser()?.getIdToken(),
        data
      );

      if (profilePhoto) {
        let photoData = {
          photoType: "profile",
          photo: formData.profilePhoto,
        };
        response = await action.updatePhoto(
          await getUser()?.getIdToken(),
          photoData
        );
      }

      if (coverPhoto) {
        let photoData = {
          photoType: "background",
          photo: formData.coverPhoto,
        };
        response = await action.updatePhoto(
          await getUser()?.getIdToken(),
          photoData
        );
      }

      if (companyPhoto) {
        // Check if user has an active subscription before uploading company photo
        if (userData.subscription?.status !== "active") {
          setSnackbar({
            open: true,
            message: "Company photo upload requires Bappa Premium subscription",
            severity: "info",
          });
        } else {
          let photoData = {
            photoType: "company",
            photo: formData.companyPhoto,
          };
          response = await action.updatePhoto(
            await getUser()?.getIdToken(),
            photoData
          );
        }
      }

      //throw error if response is not 200
      if (response.status !== 200) {
        throw new Error("Error updating profile. Please try again.");
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        open: true,
        message: "Error updating profile. Please try again.",
        severity: "error",
      });
    } finally {
      setSnackbar({
        open: true,
        message: "Profile updated successfully!",
        severity: "success",
      });
      setIsUpdating(false);
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCustomRedirect = (event) => {
    // Only allow enabling if user has an active subscription
    if (event.target.checked && userData.subscription?.status !== "active") {
      // Don't enable for non-premium users
      return;
    }

    setRedirectEnabled(event.target.checked);

    if (!event.target.checked) {
      setRedirectLink("");
    }
  };

  const handleColorChange = (color, type) => {
    setFormData({ ...formData, [type]: color.hex });
  };

  const handleSwitchChange = (e) => {
    setFormData({ ...formData, matchLinkIcons: e.target.checked });
  };

  const colorOptions = [
    "#f44336",
    "#e91e63",
    "#9c27b0",
    "#673ab7",
    "#3f51b5",
    "#2196f3",
    "#03a9f4",
    "#00bcd4",
    "#009688",
    "#4caf50",
    "#8bc34a",
    "#cddc39",
    "#ffeb3b",
    "#ffc107",
    "#ff9800",
    "#ff5722",
    "#795548",
    "#607d8b",
    "#0f0f23",
    "#000000",
  ];

  const handleOpenRegisterCard = () => setOpenRegisterCard(true);
  const handleCloseRegisterCard = () => setOpenRegisterCard(false);

  const handleSocialChange = (platform, index, field, value) => {
    setFormData((prevFormData) => {
      const newSocials = { ...prevFormData.socials };

      if (platform === "website") {
        newSocials[platform] = [...newSocials[platform]];
        newSocials[platform][index] = {
          ...newSocials[platform][index],
          [field]: value,
        };
      } else {
        newSocials[platform] = [...newSocials[platform]];
        newSocials[platform][index] = value;
      }

      return { ...prevFormData, socials: newSocials };
    });

    // Validate URL fields for YouTube and LinkedIn
    if ((platform === "youtube" || platform === "linkedin") && !field) {
      setValidationErrors((prev) => {
        const newErrors = { ...prev };

        if (!newErrors[platform]) {
          newErrors[platform] = {};
        }

        if (!validateUrl(value, platform)) {
          newErrors[platform][index] =
            platform === "youtube"
              ? "Please enter a valid YouTube URL (e.g., https://youtube.com/...)"
              : "Please enter a valid LinkedIn URL (e.g., https://linkedin.com/in/...)";
        } else {
          delete newErrors[platform][index];
          // Clean up empty platform objects
          if (Object.keys(newErrors[platform]).length === 0) {
            delete newErrors[platform];
          }
        }

        return newErrors;
      });
    }

    // Also validate website URLs
    if (platform === "website" && field === "url") {
      setValidationErrors((prev) => {
        const newErrors = { ...prev };

        if (!newErrors[platform]) {
          newErrors[platform] = {};
        }

        if (value && !validateUrl(value)) {
          newErrors[platform][index] =
            "Please enter a valid URL including http:// or https://";
        } else {
          delete newErrors[platform][index];
          // Clean up empty platform objects
          if (Object.keys(newErrors[platform]).length === 0) {
            delete newErrors[platform];
          }
        }

        return newErrors;
      });
    }
  };

  const addSocialField = (platform) => {
    setFormData((prevFormData) => {
      const newSocials = { ...prevFormData.socials };
      if (platform === "website") {
        newSocials[platform] = [...newSocials[platform], { name: "", url: "" }];
      } else {
        newSocials[platform] = [...newSocials[platform], ""];
      }
      return { ...prevFormData, socials: newSocials };
    });
  };

  const removeSocialField = (platform, index) => {
    setFormData((prevFormData) => {
      const newSocials = { ...prevFormData.socials };
      newSocials[platform] = newSocials[platform].filter((_, i) => i !== index);
      return { ...prevFormData, socials: newSocials };
    });
  };

  const [activeId, setActiveId] = useState(null);

  const onDragStart = (event) => {
    const { active } = event;
    setActiveId(active.id);
  };

  const onDragEnd = (event) => {
    const { active, over } = event;
    setActiveId(null);

    if (active.id !== over.id) {
      const oldIndex = formData.socialOrder.indexOf(active.id);
      const newIndex = formData.socialOrder.indexOf(over.id);

      setFormData({
        ...formData,
        socialOrder: arrayMove(formData.socialOrder, oldIndex, newIndex),
      });
    }
  };

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 150,
        tolerance: 5,
      },
    })
  );

  const handlePreviewOpen = () => setPreviewOpen(true);
  const handlePreviewClose = () => setPreviewOpen(false);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG files!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must be smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const openAppleWalletModal = () => setAppleWalletModalOpen(true);
  const closeAppleWalletModal = () => setAppleWalletModalOpen(false);

  return (
    <Container maxWidth="xl" disableGutters={isMobile}>
      <Box sx={{ mb: 4 }}>
        <Typography
          variant="h4"
          component="h1"
          gutterBottom
          sx={{ fontWeight: "bold", color: theme.palette.primary.main }}
        >
          Manage Your Profile
        </Typography>
        <Typography variant="subtitle1" color="text.secondary" gutterBottom>
          Customize how your profile appears to others and manage your contact
          information
        </Typography>
      </Box>

      <Grid container spacing={isMobile ? 2 : 4}>
        <Grid item xs={12} md={8}>
          <Paper
            elevation={3}
            sx={{
              p: 0,
              borderRadius: 2,
              overflow: "hidden",
              boxShadow: "0 4px 20px rgba(0,0,0,0.08)",
            }}
          >
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              variant={isMobile ? "scrollable" : "fullWidth"}
              scrollButtons={isMobile ? "auto" : false}
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                backgroundColor: alpha(theme.palette.primary.main, 0.05),
              }}
            >
              <Tab
                icon={<PersonIcon />}
                label="Personal Info"
                sx={{ textTransform: "none", py: 2 }}
              />
              <Tab
                icon={<PhotoCameraIcon />}
                label="Photos"
                sx={{ textTransform: "none", py: 2 }}
              />
              <Tab
                icon={<ColorLensIcon />}
                label="Appearance"
                sx={{ textTransform: "none", py: 2 }}
              />
              <Tab
                icon={<ShareIcon />}
                label="Social Media"
                sx={{ textTransform: "none", py: 2 }}
              />
              <Tab
                icon={<SettingsIcon />}
                label="Settings"
                sx={{ textTransform: "none", py: 2 }}
              />
            </Tabs>

            <Box sx={{ p: isMobile ? 2 : 4 }}>
              {/* Personal Info Tab */}
              <TabPanel value={activeTab} index={0}>
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{ mb: 3, fontWeight: "medium" }}
                >
                  Personal Information
                </Typography>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="First Name"
                      name="firstName"
                      value={formData.firstName}
                      onChange={handleInputChange}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Last Name"
                      name="lastName"
                      value={formData.lastName}
                      onChange={handleInputChange}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Title"
                      name="title"
                      multiline
                      rows={2}
                      value={formData.title}
                      onChange={handleInputChange}
                      variant="outlined"
                      placeholder="Your professional title or short bio"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Email"
                      name="email"
                      type="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Phone"
                      name="phone"
                      type="tel"
                      value={formData.phone}
                      onChange={handleInputChange}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </TabPanel>

              {/* Photos Tab */}
              <TabPanel value={activeTab} index={1}>
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{ mb: 3, fontWeight: "medium" }}
                >
                  Profile Images
                </Typography>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={6}>
                    <Card variant="outlined" sx={{ height: "100%" }}>
                      <CardContent>
                        <Typography
                          variant="subtitle1"
                          gutterBottom
                          fontWeight="medium"
                        >
                          Profile Picture
                        </Typography>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          paragraph
                        >
                          This will be displayed as your main profile image
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            mt: 2,
                          }}
                        >
                          <ImgCrop rotationSlider>
                            <Upload
                              action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                              listType="picture-card"
                              fileList={profileFileList}
                              onChange={handleProfileChange}
                              onPreview={onPreview}
                              beforeUpload={beforeUpload}
                              accept=".jpg,.jpeg,.png"
                            >
                              {profileFileList.length < 1 && "+ Upload"}
                            </Upload>
                          </ImgCrop>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Card variant="outlined" sx={{ height: "100%" }}>
                      <CardContent>
                        <Typography
                          variant="subtitle1"
                          gutterBottom
                          fontWeight="medium"
                        >
                          Cover Photo
                        </Typography>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          paragraph
                        >
                          This will appear as the background at the top of your
                          profile
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            mt: 2,
                          }}
                        >
                          <ImgCrop rotationSlider aspect={16 / 9}>
                            <Upload
                              action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                              listType="picture-card"
                              fileList={coverFileList}
                              onChange={handleCoverChange}
                              onPreview={onPreview}
                              beforeUpload={beforeUpload}
                              accept=".jpg,.jpeg,.png"
                            >
                              {coverFileList.length < 1 && "+ Upload"}
                            </Upload>
                          </ImgCrop>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Card variant="outlined" sx={{ height: "100%" }}>
                      <CardContent>
                        <Typography
                          variant="subtitle1"
                          gutterBottom
                          fontWeight="medium"
                        >
                          Company Logo
                        </Typography>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          paragraph
                        >
                          This will appear as a small logo on your profile photo
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            mt: 2,
                            position: "relative",
                          }}
                        >
                          <ImgCrop rotationSlider>
                            <Upload
                              action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                              listType="picture-card"
                              fileList={companyPhotoFileList}
                              onChange={handleCompanyPhotoChange}
                              onPreview={onPreview}
                              beforeUpload={beforeUpload}
                              accept=".jpg,.jpeg,.png"
                              disabled={
                                userData.subscription?.status !== "active"
                              }
                            >
                              {companyPhotoFileList.length < 1 && "+ Upload"}
                            </Upload>
                          </ImgCrop>
                        </Box>
                      </CardContent>

                      {userData.subscription?.status !== "active" && (
                        <Box
                          sx={{
                            position: "relative",
                            mt: 2,
                            p: 3,
                            backgroundColor: alpha(
                              theme.palette.primary.main,
                              0.05
                            ),
                            borderTop: 1,
                            borderColor: "divider",
                            borderRadius: "0 0 8px 8px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <BusinessIcon
                                sx={{
                                  color: theme.palette.primary.main,
                                  mr: 2,
                                  fontSize: 24,
                                }}
                              />
                              <Box>
                                <Typography
                                  variant="subtitle2"
                                  fontWeight="medium"
                                >
                                  BappaPremium Feature
                                </Typography>
                                <Typography
                                  variant="body2"
                                  color="text.secondary"
                                >
                                  Upgrade to Bappa Premium to add your company
                                  logo
                                </Typography>
                              </Box>
                            </Box>
                            <Button
                              variant="contained"
                              size="small"
                              href="/my-subscription"
                              sx={{ textTransform: "none" }}
                            >
                              Upgrade
                            </Button>
                          </Box>
                        </Box>
                      )}
                    </Card>
                  </Grid>
                </Grid>
              </TabPanel>

              {/* Appearance Tab */}
              <TabPanel value={activeTab} index={2}>
                <Box position="relative">
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ mb: 3, fontWeight: "medium" }}
                  >
                    Customize Appearance
                  </Typography>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <Card variant="outlined">
                        <CardContent>
                          <Typography
                            variant="subtitle1"
                            gutterBottom
                            fontWeight="medium"
                          >
                            Card Theme
                          </Typography>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            paragraph
                          >
                            Choose the main color for your profile card
                          </Typography>
                          <Box
                            sx={{
                              mt: 2,
                              opacity:
                                userData.subscription?.status === "active"
                                  ? 1
                                  : 0.6,
                            }}
                          >
                            <CirclePicker
                              color={formData.cardTheme}
                              onChange={(color) =>
                                userData.subscription?.status === "active" &&
                                handleColorChange(color, "cardTheme")
                              }
                              colors={colorOptions}
                              width="100%"
                            />
                          </Box>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Card variant="outlined">
                        <CardContent>
                          <Typography
                            variant="subtitle1"
                            gutterBottom
                            fontWeight="medium"
                          >
                            Link Color
                          </Typography>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            paragraph
                          >
                            Choose the color for your profile links
                          </Typography>
                          <Box
                            sx={{
                              mt: 2,
                              opacity:
                                userData.subscription?.status === "active"
                                  ? 1
                                  : 0.6,
                            }}
                          >
                            <CirclePicker
                              color={formData.linkColor}
                              onChange={(color) =>
                                userData.subscription?.status === "active" &&
                                handleColorChange(color, "linkColor")
                              }
                              colors={colorOptions}
                              width="100%"
                            />
                          </Box>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={12}>
                      <Card variant="outlined">
                        <CardContent>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={formData.matchLinkIcons}
                                onChange={(e) =>
                                  userData.subscription?.status === "active" &&
                                  handleSwitchChange(e)
                                }
                                name="matchLinkIcons"
                                color="primary"
                                disabled={
                                  userData.subscription?.status !== "active"
                                }
                              />
                            }
                            label="Match Link Icons to Card Theme"
                          />
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={12}>
                      <Card variant="outlined">
                        <CardContent>
                          <Typography
                            variant="subtitle1"
                            gutterBottom
                            fontWeight="medium"
                          >
                            Font Style
                          </Typography>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            paragraph
                          >
                            Choose the font for your profile
                          </Typography>
                          <Select
                            fullWidth
                            value={preferences.font}
                            onChange={(e) =>
                              userData.subscription?.status === "active" &&
                              setPreferences({
                                ...preferences,
                                font: e.target.value,
                              })
                            }
                            name="font"
                            disabled={
                              userData.subscription?.status !== "active"
                            }
                          >
                            <MenuItem value="default">Default</MenuItem>
                            <MenuItem
                              value="Arial"
                              sx={{ fontFamily: "Arial" }}
                            >
                              Arial
                            </MenuItem>
                            <MenuItem
                              value="Montserrat"
                              sx={{ fontFamily: "Montserrat" }}
                            >
                              Montserrat
                            </MenuItem>
                            <MenuItem
                              value="Poppins"
                              sx={{ fontFamily: "Poppins" }}
                            >
                              Poppins
                            </MenuItem>
                            <MenuItem value="Lato" sx={{ fontFamily: "Lato" }}>
                              Lato
                            </MenuItem>
                            <MenuItem
                              value="Inter"
                              sx={{ fontFamily: "Inter" }}
                            >
                              Inter
                            </MenuItem>
                            <MenuItem
                              value="Raleway"
                              sx={{ fontFamily: "Raleway" }}
                            >
                              Raleway
                            </MenuItem>
                            <MenuItem
                              value="Playfair Display"
                              sx={{ fontFamily: "Playfair Display" }}
                            >
                              Playfair Display
                            </MenuItem>
                            <MenuItem
                              value="Merriweather"
                              sx={{ fontFamily: "Merriweather" }}
                            >
                              Merriweather
                            </MenuItem>
                            <MenuItem
                              value="Georgia"
                              sx={{ fontFamily: "Georgia" }}
                            >
                              Georgia
                            </MenuItem>
                            <MenuItem
                              value="Garamond"
                              sx={{ fontFamily: "Garamond" }}
                            >
                              Garamond
                            </MenuItem>
                            <MenuItem
                              value="Bebas Neue"
                              sx={{ fontFamily: "Bebas Neue" }}
                            >
                              Bebas Neue
                            </MenuItem>
                            <MenuItem
                              value="Anton"
                              sx={{ fontFamily: "Anton" }}
                            >
                              Anton
                            </MenuItem>
                            <MenuItem
                              value="Oswald"
                              sx={{ fontFamily: "Oswald" }}
                            >
                              Oswald
                            </MenuItem>
                            <MenuItem
                              value="Impact"
                              sx={{ fontFamily: "Impact" }}
                            >
                              Impact
                            </MenuItem>
                            <MenuItem
                              value="Pacifico"
                              sx={{ fontFamily: "Pacifico" }}
                            >
                              Pacifico
                            </MenuItem>
                            <MenuItem
                              value="Dancing Script"
                              sx={{ fontFamily: "Dancing Script" }}
                            >
                              Dancing Script
                            </MenuItem>
                            <MenuItem
                              value="Lobster"
                              sx={{ fontFamily: "Lobster" }}
                            >
                              Lobster
                            </MenuItem>
                            <MenuItem
                              value="Caveat"
                              sx={{ fontFamily: "Caveat" }}
                            >
                              Caveat
                            </MenuItem>
                            <MenuItem
                              value="Orbitron"
                              sx={{ fontFamily: "Orbitron" }}
                            >
                              Orbitron
                            </MenuItem>
                            <MenuItem
                              value="Roboto Mono"
                              sx={{ fontFamily: "Roboto Mono" }}
                            >
                              Roboto Mono
                            </MenuItem>
                            <MenuItem value="Exo" sx={{ fontFamily: "Exo" }}>
                              Exo
                            </MenuItem>
                            <MenuItem
                              value="Space Grotesk"
                              sx={{ fontFamily: "Space Grotesk" }}
                            >
                              Space Grotesk
                            </MenuItem>
                            <MenuItem
                              value="Press Start 2P"
                              sx={{ fontFamily: "Press Start 2P" }}
                            >
                              Press Start 2P
                            </MenuItem>
                            <MenuItem
                              value="Rubik"
                              sx={{ fontFamily: "Rubik" }}
                            >
                              Rubik
                            </MenuItem>
                            <MenuItem
                              value="Vast Shadow"
                              sx={{ fontFamily: "Vast Shadow" }}
                            >
                              Vast Shadow
                            </MenuItem>
                            <MenuItem
                              value="Courier New"
                              sx={{ fontFamily: "Courier New" }}
                            >
                              Courier New
                            </MenuItem>
                            <MenuItem
                              value="Times New Roman"
                              sx={{ fontFamily: "Times New Roman" }}
                            >
                              Times New Roman
                            </MenuItem>
                          </Select>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>

                  {userData.subscription?.status !== "active" && (
                    <Box
                      sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "rgba(255, 255, 255, 0.7)",
                        backdropFilter: "blur(2px)",
                        zIndex: 10,
                        borderRadius: 2,
                      }}
                    >
                      <Box
                        sx={{
                          backgroundColor: "white",
                          p: 4,
                          borderRadius: 2,
                          textAlign: "center",
                          maxWidth: 500,
                          boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
                        }}
                      >
                        <ColorLensIcon
                          sx={{
                            fontSize: 60,
                            color: theme.palette.primary.main,
                            opacity: 0.7,
                            mb: 2,
                          }}
                        />
                        <Typography
                          variant="h5"
                          gutterBottom
                          fontWeight="medium"
                        >
                          Unlock Profile Customization
                        </Typography>
                        <Typography
                          variant="body1"
                          color="text.secondary"
                          paragraph
                          sx={{ mb: 3 }}
                        >
                          Upgrade to Bappa Premium to customize your profile's
                          colors, fonts, and appearance settings.
                        </Typography>
                        <Button
                          variant="contained"
                          color="primary"
                          size="large"
                          href="/my-subscription"
                          sx={{
                            px: 4,
                            py: 1.5,
                            borderRadius: 2,
                            textTransform: "none",
                            fontWeight: "bold",
                          }}
                        >
                          Upgrade Now
                        </Button>
                      </Box>
                    </Box>
                  )}
                </Box>
              </TabPanel>

              {/* Social Media Tab */}
              <TabPanel value={activeTab} index={3}>
                <Box sx={{ px: isMobile ? 1 : 2 }}>
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ mb: 3, fontWeight: "medium" }}
                  >
                    Social Media & Websites
                  </Typography>
                  <Typography variant="body2" color="text.secondary" paragraph>
                    Add your social media accounts and websites. Drag to reorder
                    how they appear on your profile.
                  </Typography>

                  {isMobile && (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        mb: 2,
                        p: 2,
                        bgcolor: alpha(theme.palette.info.light, 0.1),
                        borderRadius: 1,
                      }}
                    >
                      <DragIndicatorIcon sx={{ mr: 1, color: "info.main" }} />
                      <Typography variant="body2" color="info.main">
                        Tap and hold the drag handle to reorder items
                      </Typography>
                    </Box>
                  )}

                  <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragEnd={onDragEnd}
                    onDragStart={onDragStart}
                    modifiers={[]}
                  >
                    <SortableContext
                      items={formData.socialOrder}
                      strategy={verticalListSortingStrategy}
                    >
                      <Box
                        sx={{
                          maxWidth: isMobile ? "100%" : "800px",
                          mx: "auto",
                        }}
                      >
                        {formData.socialOrder.map((platform) => (
                          <SortableItem
                            key={platform}
                            platform={platform}
                            index={formData.socialOrder.indexOf(platform)}
                            formData={formData}
                            handleSocialChange={handleSocialChange}
                            removeSocialField={removeSocialField}
                            addSocialField={addSocialField}
                            socialIcons={socialIcons}
                            activeId={activeId}
                            validationErrors={validationErrors}
                          />
                        ))}
                      </Box>
                    </SortableContext>
                  </DndContext>
                </Box>
              </TabPanel>

              {/* Settings Tab */}
              <TabPanel value={activeTab} index={4}>
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{ mb: 3, fontWeight: "medium" }}
                >
                  Profile Settings
                </Typography>

                <Card variant="outlined" sx={{ mb: 3 }}>
                  <CardContent>
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      fontWeight="medium"
                    >
                      Button Display Options
                    </Typography>
                    <Stack spacing={2} direction="column" sx={{ mt: 2 }}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={preferences.showSaveContactButton}
                            onChange={(e) =>
                              setPreferences({
                                ...preferences,
                                showSaveContactButton: e.target.checked,
                              })
                            }
                            color="primary"
                          />
                        }
                        label="Display Save Contact Button"
                      />
                      <FormControlLabel
                        control={
                          <Switch
                            checked={preferences.showExchangeContactButton}
                            onChange={(e) =>
                              setPreferences({
                                ...preferences,
                                showExchangeContactButton: e.target.checked,
                              })
                            }
                            color="primary"
                          />
                        }
                        label="Display Exchange Contact Button"
                      />
                    </Stack>
                  </CardContent>
                </Card>

                <Card variant="outlined" sx={{ mb: 3 }}>
                  <CardContent>
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      fontWeight="medium"
                    >
                      Apple Wallet
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      paragraph
                    >
                      Generate a digital card for your Apple Wallet with your
                      profile information and QR code
                    </Typography>
                    {isIOS ? (
                      <AppleWalletButton onClick={openAppleWalletModal} />
                    ) : (
                      <Typography variant="body2" color="text.secondary">
                        Apple Wallet is only available on iOS devices.
                      </Typography>
                    )}
                  </CardContent>
                </Card>

                <Card variant="outlined">
                  <CardContent>
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      fontWeight="medium"
                    >
                      Custom Redirect
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      paragraph
                    >
                      Automatically redirect visitors to another URL when they
                      tap your card
                    </Typography>

                    <FormControlLabel
                      control={
                        <Switch
                          checked={redirectEnabled}
                          onChange={handleCustomRedirect}
                          color="primary"
                          disabled={userData.subscription?.status !== "active"}
                        />
                      }
                      label="Enable Custom Redirect"
                    />

                    {redirectEnabled && (
                      <TextField
                        fullWidth
                        label="Redirect Link"
                        value={redirectLink}
                        onChange={(e) => setRedirectLink(e.target.value)}
                        margin="normal"
                        variant="outlined"
                        placeholder="https://example.com"
                        helperText="Visitors will be automatically redirected to this URL"
                        disabled={userData.subscription?.status !== "active"}
                      />
                    )}
                  </CardContent>

                  {userData.subscription?.status !== "active" && (
                    <Box
                      sx={{
                        position: "relative",
                        mt: 2,
                        p: 3,
                        backgroundColor: alpha(
                          theme.palette.primary.main,
                          0.05
                        ),
                        borderTop: 1,
                        borderColor: "divider",
                        borderRadius: "0 0 8px 8px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <ShareIcon
                            sx={{
                              color: theme.palette.primary.main,
                              mr: 2,
                              fontSize: 24,
                            }}
                          />
                          <Box>
                            <Typography variant="subtitle2" fontWeight="medium">
                              BappaPremium Feature
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              Upgrade to Bappa Premium to use custom redirects
                            </Typography>
                          </Box>
                        </Box>
                        <Button
                          variant="contained"
                          size="small"
                          href="/my-subscription"
                          sx={{ textTransform: "none" }}
                        >
                          Upgrade
                        </Button>
                      </Box>
                    </Box>
                  )}
                </Card>
              </TabPanel>
            </Box>

            <Box
              sx={{
                p: 3,
                borderTop: 1,
                borderColor: "divider",
                display: "flex",
                justifyContent: "flex-end",
                flexDirection: isMobile ? "column" : "row",
                gap: isMobile ? 2 : 0,
              }}
            >
              <LoadingButton
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                onClick={handleUpdateProfile}
                loading={isUpdating}
                loadingIndicator="Updating..."
                startIcon={<SaveIcon />}
                fullWidth={isMobile}
              >
                Save Changes
              </LoadingButton>
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={12} md={4}>
          {!isMobile && (
            <Box
              sx={{
                position: isMobile ? "static" : "sticky",
                top: 20,
                mt: isMobile ? 2 : 0,
                display: "flex",
                justifyContent: "center", // Center horizontally
                alignItems: "center", // Center vertically
              }}
            >
              <Paper
                elevation={3}
                sx={{
                  p: isMobile ? 2 : 4,
                  width: isMobile ? "100%" : "auto", // Full width on mobile
                  maxWidth: "500px", // Limit maximum width
                  borderRadius: 2,
                  boxShadow: "0 4px 20px rgba(0,0,0,0.08)",
                }}
              >
                <Typography
                  variant="h6"
                  align="center"
                  gutterBottom
                  sx={{ fontWeight: "medium", mb: 3 }}
                >
                  Live Preview
                </Typography>
                <ProfilePreview
                  formData={{
                    ...formData,
                    profilePhoto: profilePhoto || formData.profilePhoto,
                    coverPhoto: coverPhoto || formData.coverPhoto,
                    companyPhoto: companyPhoto || formData.companyPhoto,
                  }}
                  preferences={preferences}
                  redirectEnabled={redirectEnabled}
                  redirectLink={redirectLink}
                />
              </Paper>
            </Box>
          )}
          <Box
            sx={{
              position: isMobile ? "static" : "sticky",
              top: 20,
              mt: isMobile ? 2 : 0,
            }}
          >
            <Paper
              elevation={3}
              sx={{
                p: isMobile ? 2 : 4,
                mt: 2,
                borderRadius: 2,
                boxShadow: "0 4px 20px rgba(0,0,0,0.08)",
              }}
            >
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: "medium", mb: 3 }}
              >
                Card Management
              </Typography>
              <Button
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                sx={{ mb: 2 }}
                onClick={handleOpenRegisterCard}
              >
                Register Card
              </Button>
              <RegisterCard
                open={openRegisterCard}
                handleClose={handleCloseRegisterCard}
              />
              <Button
                variant="outlined"
                color="primary"
                size="large"
                fullWidth
                onClick={() => {
                  window.location.href = `/profile/${formData.id}`;
                }}
              >
                View Public Profile
              </Button>
            </Paper>
          </Box>
        </Grid>
      </Grid>

      {isMobile && (
        <>
          <Fab
            color="primary"
            aria-label="preview"
            onClick={handlePreviewOpen}
            sx={{
              position: "fixed",
              bottom: theme.spacing(2),
              right: theme.spacing(2),
            }}
          >
            <VisibilityIcon />
          </Fab>

          <Dialog
            fullScreen
            open={previewOpen}
            onClose={handlePreviewClose}
            TransitionComponent={Transition}
          >
            <DialogTitle>
              Profile Preview
              <IconButton
                aria-label="close"
                onClick={handlePreviewClose}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <ProfilePreview
                formData={{
                  ...formData,
                  profilePhoto: profilePhoto || formData.profilePhoto,
                  coverPhoto: coverPhoto || formData.coverPhoto,
                  companyPhoto: companyPhoto || formData.companyPhoto,
                }}
                preferences={preferences}
                redirectEnabled={redirectEnabled}
                redirectLink={redirectLink}
              />
            </DialogContent>
          </Dialog>
        </>
      )}

      <AppleWalletModal
        open={appleWalletModalOpen}
        handleClose={closeAppleWalletModal}
      />

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default ManageProfile;
