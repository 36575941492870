import React, { useEffect, useState, useCallback } from "react";
import * as action from "../../service/action";
import { useUserContext } from "../Login/userContext";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TextField from "@mui/material/TextField";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Download";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import FileSaver from "file-saver";
import Papa from "papaparse";
import { generatVCardForContact } from "../../service/vcard.js";
import Avatar from "@mui/material/Avatar";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import SearchIcon from "@mui/icons-material/Search";
import { Map as MapIcon, FileDownload } from "@mui/icons-material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Popover from "@mui/material/Popover";
import EditIcon from "@mui/icons-material/Edit";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import { ADMIN_EMAILS } from "../../components/AdminRoute";

import DeleteContactDialog from "./DeleteContact";
import UpdateContactDialog from "./UpdateContact";
import MapView from "../../components/MapView";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import TableSortLabel from "@mui/material/TableSortLabel";

// Add this function at the top of your file, outside of the component
const getColorForLetter = (letter) => {
  const colors = [
    "#F44336",
    "#E91E63",
    "#9C27B0",
    "#673AB7",
    "#3F51B5",
    "#2196F3",
    "#03A9F4",
    "#00BCD4",
    "#009688",
    "#4CAF50",
    "#8BC34A",
    "#CDDC39",
    "#FFEB3B",
    "#FFC107",
    "#FF9800",
    "#FF5722",
    "#795548",
    "#9E9E9E",
    "#607D8B",
    "#3F51B5",
    "#2196F3",
    "#03A9F4",
    "#00BCD4",
    "#009688",
    "#4CAF50",
    "#8BC34A",
  ];
  const charCode = letter.toUpperCase().charCodeAt(0) - 65; // A = 0, B = 1, etc.
  return colors[charCode % colors.length];
};

// Utility function to get color for follow-up priority
const getPriorityColor = (priority) => {
  switch (priority) {
    case "high":
      return "#f44336"; // Red
    case "medium":
      return "#ff9800"; // Orange
    case "low":
      return "#4caf50"; // Green
    default:
      return "#9e9e9e"; // Grey for no priority
  }
};

// Utility function to get label for follow-up priority
const getPriorityLabel = (priority) => {
  switch (priority) {
    case "high":
      return "High";
    case "medium":
      return "Medium";
    case "low":
      return "Low";
    default:
      return "None";
  }
};

const headCells = [
  {
    id: "select",
    numeric: false,
    disablePadding: false,
    label: "Select all",
  },
  {
    id: "contact",
    numeric: false,
    disablePadding: false,
    label: "Contact",
  },
  {
    id: "tag",
    numeric: false,
    disablePadding: false,
    label: "Tag",
  },
  {
    id: "connectedWith",
    numeric: false,
    disablePadding: false,
    label: "Connected with",
  },
  {
    id: "type",
    numeric: false,
    disablePadding: false,
    label: "Type",
  },
  {
    id: "date",
    numeric: false,
    disablePadding: false,
    label: "Date",
  },
  {
    id: "export",
    numeric: false,
    disablePadding: false,
    label: "Export",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    numSelected,
    rowCount,
    onRequestSort,
    order,
    orderBy,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all contacts",
            }}
          />
        </TableCell>
        <TableCell>
          {props.isMobile ? (
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <TableSortLabel
                active={orderBy === "full_name"}
                direction={orderBy === "full_name" ? order : "asc"}
                onClick={createSortHandler("full_name")}
              >
                Name
              </TableSortLabel>
              <TableSortLabel
                active={orderBy === "date_met"}
                direction={orderBy === "date_met" ? order : "asc"}
                onClick={createSortHandler("date_met")}
              >
                Date
              </TableSortLabel>
            </Box>
          ) : (
            <TableSortLabel
              active={orderBy === "full_name"}
              direction={orderBy === "full_name" ? order : "asc"}
              onClick={createSortHandler("full_name")}
            >
              Contact
            </TableSortLabel>
          )}
        </TableCell>
        {!props.isMobile && (
          <>
            <TableCell>
              <TableSortLabel
                active={orderBy === "phone_number"}
                direction={orderBy === "phone_number" ? order : "asc"}
                onClick={createSortHandler("phone_number")}
              >
                Phone Number
              </TableSortLabel>
            </TableCell>
            <TableCell>Notes</TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === "location_name"}
                direction={orderBy === "location_name" ? order : "asc"}
                onClick={createSortHandler("location_name")}
              >
                Met in
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === "date_met"}
                direction={orderBy === "date_met" ? order : "asc"}
                onClick={createSortHandler("date_met")}
              >
                Date
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === "follow_up_priority"}
                direction={orderBy === "follow_up_priority" ? order : "asc"}
                onClick={createSortHandler("follow_up_priority")}
              >
                Follow-up Priority
              </TableSortLabel>
            </TableCell>
          </>
        )}
        <TableCell align="right">Actions</TableCell>
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
          align="center"
        >
          Contacts
        </Typography>
      )}
      <Tooltip
        hidden={numSelected !== 1}
        title="Delete"
        color="primary"
        style={{ width: "20px", marginRight: "20px" }}
      >
        <IconButton onClick={props.handleSaveContact}>
          <SaveIcon />
        </IconButton>
      </Tooltip>
      <Tooltip
        hidden={numSelected < 1}
        title="Delete"
        style={{ width: "20px" }}
        color="warning"
      >
        <IconButton onClick={props.handleDelete}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleSaveContact: PropTypes.func.isRequired,
};

// Create a separate component for the sorted contacts
function SortedContactsTable({
  filteredContacts,
  order,
  orderBy,
  page,
  rowsPerPage,
  selected,
  handleSelectAllClick,
  handleClick,
  handleActionClick,
  isSelected,
  isMobile,
  handleRequestSort,
  getColorForLetter,
  getPriorityColor,
  getPriorityLabel,
  parseDate,
}) {
  // Function to compare values for sorting
  const descendingComparator = (a, b, orderBy) => {
    // Special handling for follow_up_priority
    if (orderBy === "follow_up_priority") {
      const priorityOrder = {
        high: 3,
        medium: 2,
        low: 1,
        none: 0,
        "": 0,
        null: 0,
        undefined: 0,
      };
      const valueA = priorityOrder[a[orderBy] || ""] || 0;
      const valueB = priorityOrder[b[orderBy] || ""] || 0;
      return valueB - valueA;
    }

    // Special handling for date_met field
    if (orderBy === "date_met") {
      // Make sure we have the parseDate function
      if (typeof parseDate !== "function") {
        console.error("parseDate function is not available");
        return 0;
      }

      const dateA = parseDate(a[orderBy]);
      const dateB = parseDate(b[orderBy]);

      // Handle null or invalid dates
      if (!dateA && !dateB) return 0;
      if (!dateA) return 1;
      if (!dateB) return -1;

      // Compare dates
      return dateB - dateA;
    }

    // Default comparison for other fields
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  // Apply sorting to filtered contacts
  const sortedContacts = React.useMemo(() => {
    if (!filteredContacts || filteredContacts.length === 0) return [];
    return [...filteredContacts].sort(getComparator(order, orderBy));
  }, [filteredContacts, order, orderBy]);

  // Slice the sorted contacts for the current page
  const paginatedContacts = sortedContacts.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <TableContainer>
      <Table size={isMobile ? "small" : "medium"}>
        <EnhancedTableHead
          numSelected={selected.size}
          order={order}
          orderBy={orderBy}
          onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleRequestSort}
          rowCount={paginatedContacts.length}
          isMobile={isMobile}
        />
        <TableBody>
          {paginatedContacts.map((contact) => {
            const isItemSelected = isSelected(contact.id);
            return (
              <TableRow
                hover
                onClick={(event) => handleClick(event, contact)}
                role="checkbox"
                aria-checked={isItemSelected}
                tabIndex={-1}
                key={contact.id}
                selected={isItemSelected}
              >
                <TableCell padding="checkbox">
                  <Checkbox color="primary" checked={isItemSelected} />
                </TableCell>
                <TableCell>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Avatar
                      sx={{
                        mr: 2,
                        bgcolor: getColorForLetter(contact.full_name.charAt(0)),
                      }}
                    >
                      {contact.full_name.charAt(0)}
                    </Avatar>
                    <Box>
                      <Typography
                        variant="subtitle2"
                        sx={{
                          textTransform: "none",
                          fontWeight: "normal",
                        }}
                      >
                        {contact.full_name}
                      </Typography>
                      {isMobile ? (
                        <>
                          {contact.location_name && (
                            <Typography variant="body2" color="text.secondary">
                              {contact.location_name}
                            </Typography>
                          )}
                          <Typography variant="body2" color="text.secondary">
                            {contact.date_met}
                          </Typography>
                          {contact.follow_up_priority && (
                            <Typography
                              variant="body2"
                              sx={{
                                color: "white",
                                backgroundColor: getPriorityColor(
                                  contact.follow_up_priority
                                ),
                                borderRadius: "4px",
                                padding: "0 4px",
                                display: "inline-block",
                                marginTop: "4px",
                                fontSize: "0.75rem",
                              }}
                            >
                              {getPriorityLabel(contact.follow_up_priority)}
                            </Typography>
                          )}
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{
                              maxWidth: "200px",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {contact.notes}
                          </Typography>
                        </>
                      ) : (
                        <Typography variant="body2" color="text.secondary">
                          {contact.email}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </TableCell>
                {!isMobile && (
                  <>
                    <TableCell>{contact.phone_number}</TableCell>
                    <TableCell>
                      <Typography
                        variant="body2"
                        sx={{
                          maxWidth: "200px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {contact.notes}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Chip label={contact.location_name} size="small" />
                    </TableCell>
                    <TableCell>{contact.date_met}</TableCell>
                    <TableCell>
                      {contact.follow_up_priority ? (
                        <Chip
                          label={getPriorityLabel(contact.follow_up_priority)}
                          size="small"
                          style={{
                            backgroundColor: getPriorityColor(
                              contact.follow_up_priority
                            ),
                            color: "white",
                          }}
                        />
                      ) : (
                        <Chip label="None" size="small" variant="outlined" />
                      )}
                    </TableCell>
                  </>
                )}
                <TableCell align="right">
                  <IconButton
                    size="small"
                    onClick={(event) => {
                      event.stopPropagation();
                      handleActionClick(event, contact);
                    }}
                  >
                    <MoreVertIcon fontSize="small" />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default function EnhancedTable() {
  const [selected, setSelected] = React.useState(new Map());
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const { getUser } = useUserContext();
  const [userData, setUserData] = React.useState(undefined);
  const [searchQuery, setSearchQuery] = useState("");
  const [priorityFilter, setPriorityFilter] = useState("all");

  const [openDeleteContactDialog, setOpenDeleteContactDialog] =
    React.useState(false);
  const [openUpdateContactDialog, setOpenUpdateContactDialog] =
    React.useState(false);
  const [selectedContactForUpdate, setSelectedContactForUpdate] =
    React.useState(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedContact, setSelectedContact] = useState(null);

  const [showMap, setShowMap] = useState(false);
  const [openUpgradeModal, setOpenUpgradeModal] = useState(false);

  const [loading, setLoading] = useState(true);

  const [openBulkPriorityMenu, setOpenBulkPriorityMenu] = useState(null);

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(isMobile ? "date_met" : "full_name");

  // Update orderBy when mobile status changes
  React.useEffect(() => {
    setOrderBy(isMobile ? "date_met" : "full_name");
  }, [isMobile]);

  const getTomorrowDate = () => {
    const today = new Date();
    today.setDate(today.getDate() + 1); // Add one day to the current date
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  // Initialize with end date first (newest date), then start date (oldest date)
  const [endDate, setEndDate] = useState(getTomorrowDate());
  const [startDate, setStartDate] = useState("2022-01-01");

  const parseDate = (dateString) => {
    if (!dateString) return null;

    try {
      const [month, dayWithSuffix, year] = dateString.split(" ");
      if (!month || !dayWithSuffix || !year) return null;

      const day = dayWithSuffix.replace(/(st|nd|rd|th)/, "");
      const monthIndex = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ].indexOf(month);

      if (monthIndex === -1 || isNaN(parseInt(day)) || isNaN(parseInt(year))) {
        return null;
      }

      return new Date(parseInt(year), monthIndex, parseInt(day));
    } catch (error) {
      console.error("Error parsing date:", error);
      return null;
    }
  };

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const token = (await getUser()?.getIdToken()) || "token";
      const userDataResponse = await action.getMe(token);
      setUserData(userDataResponse);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }, [getUser]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleExport = () => {
    // Extracting headers from the first object in the data list
    const contacts = userData?.contacts.slice();
    delete contacts["id"];

    const filteredHeaders = [
      "full_name",
      "phone_number",
      "email",
      "date_met",
      "location_name",
      "notes",
    ];

    const fileName = `${userData?.links.firstname}_contacts.csv`;

    // Creating an array of arrays, where each inner array represents a row in the CSV
    const csvData = [
      filteredHeaders,
      ...contacts.map((item) => filteredHeaders.map((header) => item[header])),
    ];

    // Converting the array of arrays to CSV format
    const csv = Papa.unparse(csvData);

    // Creating a Blob and a download link to trigger the download
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", fileName || "export.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      alert(
        "Your browser does not support download functionality. Please try using a different browser."
      );
    }
  };

  if (loading) {
    return <LoadingScreen />;
  }

  if (!userData) {
    return <div>No data available</div>;
  }

  const contacts = userData?.contacts;

  const handleDelete = () => {
    setOpenDeleteContactDialog(true);
  };

  const handleSaveContact = async (selectedContact) => {
    selectedContact.name = selectedContact.full_name;
    selectedContact.phone = selectedContact.phone_number;
    const vcard = await generatVCardForContact(selectedContact);
    const blob = new Blob([vcard], {
      type: "text/vcard;charset=utf-8",
    });
    FileSaver.saveAs(blob, "vcard.vcf");
  };

  const handleUpdateContact = async (contactId, updatedData) => {
    try {
      const token = (await getUser()?.getIdToken()) || "token";
      const response = await action.updateV3Contact(
        token,
        contactId,
        updatedData
      );

      if (response.status !== 200) {
        alert("Could not update contact");
      } else {
        // Update the contact in state directly instead of fetching all data again
        setUserData((prevData) => {
          if (!prevData) return prevData;

          const updatedContacts = prevData.contacts.map((contact) => {
            if (contact.id === contactId) {
              // Preserve all original contact data and only update the fields that were changed
              return { ...contact, ...updatedData };
            }
            return contact;
          });

          return {
            ...prevData,
            contacts: updatedContacts,
          };
        });

        // Close the update dialog if it's open
        if (openUpdateContactDialog) {
          setOpenUpdateContactDialog(false);
        }
      }
    } catch (error) {
      console.error("Error updating contact:", error);
      alert("Could not update contact: " + error.message);
    }
  };

  const deleteContacts = async () => {
    try {
      // Get the set of IDs to delete
      const idsToDelete = Array.from(new Set(selected.keys()));
      const token = (await getUser()?.getIdToken()) || "token";

      for (const id of idsToDelete) {
        const response = await action.deleteContact(token, id);
        if (response.status !== 201) {
          throw new Error("Failed to delete contact");
        }
      }

      // Update state directly by filtering out deleted contacts
      setUserData((prevData) => {
        if (!prevData) return prevData;

        return {
          ...prevData,
          contacts: prevData.contacts.filter(
            (contact) => !idsToDelete.includes(contact.id)
          ),
        };
      });

      setSelected(new Map());
      setOpenDeleteContactDialog(false);
    } catch (error) {
      console.error("Error deleting contacts:", error);
      alert("Could not delete contact(s): " + error.message);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Update your filtering logic
  const filteredContacts = contacts.filter((contact) => {
    const contactDate = parseDate(contact.date_met);
    const start = new Date(startDate);
    const end = new Date(endDate);
    end.setDate(end.getDate() + 1); // Set to the beginning of the next day

    const matchesSearch =
      contact.full_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      contact.email.toLowerCase().includes(searchQuery.toLowerCase());

    const matchesPriority =
      priorityFilter === "all" ||
      contact.follow_up_priority === priorityFilter ||
      (priorityFilter === "none" && !contact.follow_up_priority);

    return (
      matchesSearch &&
      matchesPriority &&
      contactDate >= start &&
      contactDate < end
    );
  });

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = new Map();
      filteredContacts.forEach((n) => newSelected.set(n.id, n));
      setSelected(newSelected);
    } else {
      setSelected(new Map());
    }
  };

  const handleClick = (event, contact) => {
    const newSelected = new Map(selected);
    if (newSelected.has(contact.id)) {
      newSelected.delete(contact.id);
    } else {
      newSelected.set(contact.id, contact);
    }
    setSelected(newSelected);
  };

  const isSelected = (id) => selected.has(id);

  const handleActionClick = (event, contact) => {
    setAnchorEl(event.currentTarget);
    setSelectedContact(contact);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedContact(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleMapClick = () => {
    // Check if user is a subscriber or admin
    if (
      userData.subscription?.status === "active" ||
      ADMIN_EMAILS.includes(userData.email)
    ) {
      // If yes, toggle the map
      setShowMap(!showMap);
    } else {
      // If not a subscriber, show the upgrade modal
      setOpenUpgradeModal(true);
    }
  };

  const handleCloseUpgradeModal = () => {
    setOpenUpgradeModal(false);
  };

  const handleUpgradeSubscription = () => {
    // Redirect to subscription page
    window.location.href = "/my-subscription";
  };

  const handleOpenUpdateDialog = (contact) => {
    setSelectedContactForUpdate(contact);
    setOpenUpdateContactDialog(true);
    handleClose(); // Close the popover
  };

  const handleBulkPriorityClick = (event) => {
    setOpenBulkPriorityMenu(event.currentTarget);
  };

  const handleBulkPriorityClose = () => {
    setOpenBulkPriorityMenu(null);
  };

  const handleBulkPriorityUpdate = async (priority) => {
    try {
      const selectedIds = Array.from(selected.keys());
      const token = (await getUser()?.getIdToken()) || "token";

      for (const id of selectedIds) {
        const response = await action.updateV3Contact(token, id, {
          follow_up_priority: priority,
        });
        if (response.status !== 200) {
          throw new Error("Failed to update priority");
        }
      }

      // Update state directly for bulk priority updates
      setUserData((prevData) => {
        if (!prevData) return prevData;

        const updatedContacts = prevData.contacts.map((contact) =>
          selectedIds.includes(contact.id)
            ? { ...contact, follow_up_priority: priority }
            : contact
        );

        return {
          ...prevData,
          contacts: updatedContacts,
        };
      });

      setSelected(new Map());
      handleBulkPriorityClose();
    } catch (error) {
      console.error("Error updating priorities:", error);
      alert("Could not update priorities: " + error.message);
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  if (!contacts) {
    return <div>Loading...</div>;
  }

  return (
    <Box sx={{ width: "100%", p: 2 }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          justifyContent: "space-between",
          alignItems: isMobile ? "flex-start" : "center",
          mb: 2,
        }}
      >
        <Typography variant="h5" sx={{ mb: isMobile ? 2 : 0 }}>
          Contacts ({filteredContacts.length})
        </Typography>
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
          <Button
            startIcon={<MapIcon />}
            size={isMobile ? "small" : "medium"}
            onClick={handleMapClick}
            variant={showMap ? "outlined" : "contained"}
            color="primary"
          >
            {showMap ? "Hide Map" : "Show Map"}
          </Button>

          {!isMobile && (
            <>
              <TextField
                size="small"
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                sx={{ width: 150 }}
                label="End Date"
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                size="small"
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                sx={{ width: 150 }}
                label="Start Date"
                InputLabelProps={{ shrink: true }}
              />
              <FormControl size="small" sx={{ width: 150 }}>
                <InputLabel id="priority-filter-label">Priority</InputLabel>
                <Select
                  labelId="priority-filter-label"
                  id="priority-filter"
                  value={priorityFilter}
                  onChange={(e) => setPriorityFilter(e.target.value)}
                  label="Priority"
                >
                  <MenuItem value="all">All Priorities</MenuItem>
                  <MenuItem value="high">High</MenuItem>
                  <MenuItem value="medium">Medium</MenuItem>
                  <MenuItem value="low">Low</MenuItem>
                  <MenuItem value="none">None</MenuItem>
                </Select>
              </FormControl>
            </>
          )}
          <Button
            variant="contained"
            startIcon={<FileDownload />}
            size={isMobile ? "small" : "medium"}
            onClick={handleExport}
          >
            Export
          </Button>
        </Box>
      </Box>

      {showMap && (
        <Box position="relative">
          <MapView
            contacts={filteredContacts}
            updateContact={handleUpdateContact}
            getPriorityColor={getPriorityColor}
          />
        </Box>
      )}

      {/* Upgrade Modal */}
      <Dialog
        open={openUpgradeModal}
        onClose={handleCloseUpgradeModal}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle sx={{ m: 0, p: 2 }}>
          Unlock Map Functionality
          <IconButton
            aria-label="close"
            onClick={handleCloseUpgradeModal}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Box sx={{ textAlign: "center", py: 2 }}>
            <MapIcon
              sx={{
                fontSize: 80,
                color: theme.palette.primary.main,
                opacity: 0.7,
                mb: 2,
              }}
            />
            <Typography variant="h5" gutterBottom fontWeight="medium">
              Premium Feature: Contact Map
            </Typography>
            <Typography variant="body1" color="text.secondary" paragraph>
              Visualize your entire network on an interactive map and see
              exactly where you met each contact.
            </Typography>
            <Typography
              variant="body1"
              color="text.secondary"
              paragraph
              sx={{ mb: 3 }}
            >
              Upgrade to Bappa Premium to unlock this feature and gain powerful
              location insights to better manage your professional
              relationships.
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            px: 3,
            py: 2,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button onClick={handleCloseUpgradeModal} variant="outlined">
            Cancel
          </Button>
          <Button
            onClick={handleUpgradeSubscription}
            variant="contained"
            color="primary"
            sx={{
              px: 3,
              py: 1,
              borderRadius: 2,
              textTransform: "none",
              fontWeight: "bold",
            }}
          >
            Upgrade Now
          </Button>
        </DialogActions>
      </Dialog>

      <Paper sx={{ width: "100%", mb: 2 }}>
        <Toolbar
          sx={{
            pl: { sm: 2 },
            pr: { xs: 1, sm: 1 },
            ...(selected.size > 0 && {
              bgcolor: "#f0f0f0",
            }),
          }}
        >
          {selected.size > 0 ? (
            <Typography
              sx={{ flex: "1 1 100%" }}
              color="inherit"
              variant="subtitle1"
              component="div"
            >
              {selected.size} selected
            </Typography>
          ) : (
            <> </>
          )}

          {selected.size > 0 && (
            <>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleBulkPriorityClick}
                sx={{ mr: 1 }}
                size="small"
              >
                Set Priority
              </Button>
              <Menu
                id="bulk-priority-menu"
                anchorEl={openBulkPriorityMenu}
                open={Boolean(openBulkPriorityMenu)}
                onClose={handleBulkPriorityClose}
              >
                <MenuItem onClick={() => handleBulkPriorityUpdate("high")}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box
                      sx={{
                        width: 16,
                        height: 16,
                        borderRadius: "50%",
                        bgcolor: getPriorityColor("high"),
                        mr: 1,
                      }}
                    />
                    High
                  </Box>
                </MenuItem>
                <MenuItem onClick={() => handleBulkPriorityUpdate("medium")}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box
                      sx={{
                        width: 16,
                        height: 16,
                        borderRadius: "50%",
                        bgcolor: getPriorityColor("medium"),
                        mr: 1,
                      }}
                    />
                    Medium
                  </Box>
                </MenuItem>
                <MenuItem onClick={() => handleBulkPriorityUpdate("low")}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box
                      sx={{
                        width: 16,
                        height: 16,
                        borderRadius: "50%",
                        bgcolor: getPriorityColor("low"),
                        mr: 1,
                      }}
                    />
                    Low
                  </Box>
                </MenuItem>
                <MenuItem onClick={() => handleBulkPriorityUpdate("")}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box
                      sx={{
                        width: 16,
                        height: 16,
                        borderRadius: "50%",
                        bgcolor: "#e0e0e0",
                        mr: 1,
                      }}
                    />
                    None
                  </Box>
                </MenuItem>
              </Menu>
              <IconButton onClick={handleDelete}>
                <DeleteIcon />
              </IconButton>
            </>
          )}
        </Toolbar>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Search name, email or company"
          InputProps={{
            startAdornment: <SearchIcon color="action" sx={{ mr: 1 }} />,
          }}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          sx={{ m: 2, width: "calc(100% - 32px)" }}
        />
        <SortedContactsTable
          filteredContacts={filteredContacts}
          order={order}
          orderBy={orderBy}
          page={page}
          rowsPerPage={rowsPerPage}
          selected={selected}
          handleSelectAllClick={handleSelectAllClick}
          handleClick={handleClick}
          handleActionClick={handleActionClick}
          isSelected={isSelected}
          isMobile={isMobile}
          handleRequestSort={handleRequestSort}
          getColorForLetter={getColorForLetter}
          getPriorityColor={getPriorityColor}
          getPriorityLabel={getPriorityLabel}
          parseDate={parseDate}
        />
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredContacts.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <DeleteContactDialog
        open={openDeleteContactDialog}
        contacts={selected}
        deleteContacts={deleteContacts}
        closeDeleteContactDialog={() => setOpenDeleteContactDialog(false)}
      />
      <UpdateContactDialog
        open={openUpdateContactDialog}
        contact={selectedContactForUpdate}
        updateContact={handleUpdateContact}
        closeUpdateContactDialog={() => setOpenUpdateContactDialog(false)}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box sx={{ p: 2, display: "flex", flexDirection: "column" }}>
          <Button
            startIcon={<SaveIcon />}
            onClick={() => handleSaveContact(selectedContact)}
            sx={{ mb: 1 }}
          >
            Save Contact
          </Button>
          <Button
            startIcon={<EditIcon />}
            onClick={() => handleOpenUpdateDialog(selectedContact)}
            sx={{ mb: 1 }}
          >
            Edit Contact
          </Button>
          <Typography variant="subtitle2" sx={{ mt: 1, mb: 1 }}>
            Set Follow-up Priority:
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
            <Button
              size="small"
              variant={
                selectedContact?.follow_up_priority === "high"
                  ? "contained"
                  : "outlined"
              }
              onClick={() => {
                handleUpdateContact(selectedContact.id, {
                  follow_up_priority: "high",
                });
                handleClose();
              }}
              sx={{
                justifyContent: "flex-start",
                backgroundColor:
                  selectedContact?.follow_up_priority === "high"
                    ? getPriorityColor("high")
                    : "transparent",
                color:
                  selectedContact?.follow_up_priority === "high"
                    ? "white"
                    : getPriorityColor("high"),
                borderColor: getPriorityColor("high"),
                "&:hover": {
                  backgroundColor:
                    selectedContact?.follow_up_priority === "high"
                      ? getPriorityColor("high")
                      : "rgba(244, 67, 54, 0.1)",
                  borderColor: getPriorityColor("high"),
                },
              }}
            >
              High
            </Button>
            <Button
              size="small"
              variant={
                selectedContact?.follow_up_priority === "medium"
                  ? "contained"
                  : "outlined"
              }
              onClick={() => {
                handleUpdateContact(selectedContact.id, {
                  follow_up_priority: "medium",
                });
                handleClose();
              }}
              sx={{
                justifyContent: "flex-start",
                backgroundColor:
                  selectedContact?.follow_up_priority === "medium"
                    ? getPriorityColor("medium")
                    : "transparent",
                color:
                  selectedContact?.follow_up_priority === "medium"
                    ? "white"
                    : getPriorityColor("medium"),
                borderColor: getPriorityColor("medium"),
                "&:hover": {
                  backgroundColor:
                    selectedContact?.follow_up_priority === "medium"
                      ? getPriorityColor("medium")
                      : "rgba(255, 152, 0, 0.1)",
                  borderColor: getPriorityColor("medium"),
                },
              }}
            >
              Medium
            </Button>
            <Button
              size="small"
              variant={
                selectedContact?.follow_up_priority === "low"
                  ? "contained"
                  : "outlined"
              }
              onClick={() => {
                handleUpdateContact(selectedContact.id, {
                  follow_up_priority: "low",
                });
                handleClose();
              }}
              sx={{
                justifyContent: "flex-start",
                backgroundColor:
                  selectedContact?.follow_up_priority === "low"
                    ? getPriorityColor("low")
                    : "transparent",
                color:
                  selectedContact?.follow_up_priority === "low"
                    ? "white"
                    : getPriorityColor("low"),
                borderColor: getPriorityColor("low"),
                "&:hover": {
                  backgroundColor:
                    selectedContact?.follow_up_priority === "low"
                      ? getPriorityColor("low")
                      : "rgba(76, 175, 80, 0.1)",
                  borderColor: getPriorityColor("low"),
                },
              }}
            >
              Low
            </Button>
            <Button
              size="small"
              variant={
                !selectedContact?.follow_up_priority ? "contained" : "outlined"
              }
              onClick={() => {
                handleUpdateContact(selectedContact.id, {
                  follow_up_priority: "",
                });
                handleClose();
              }}
              sx={{
                justifyContent: "flex-start",
                backgroundColor: !selectedContact?.follow_up_priority
                  ? "#9e9e9e"
                  : "transparent",
                color: !selectedContact?.follow_up_priority
                  ? "white"
                  : "#9e9e9e",
                borderColor: "#9e9e9e",
                "&:hover": {
                  backgroundColor: !selectedContact?.follow_up_priority
                    ? "#9e9e9e"
                    : "rgba(158, 158, 158, 0.1)",
                  borderColor: "#9e9e9e",
                },
              }}
            >
              None
            </Button>
          </Box>
        </Box>
      </Popover>
    </Box>
  );
}
