import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  MenuItem,
  IconButton,
  CircularProgress,
  Snackbar,
  Alert,
  Paper,
  Divider,
  Avatar,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EmailIcon from "@mui/icons-material/Email";
import { styled } from "@mui/material/styles";
import { sendContactUs } from "../service/action";

// Bappa Card theme colors from App.js
const bappaColors = {
  primary: "#0f0f23", // Dark blue/navy
  primaryLight: "#1a1a3a", // Lighter version of primary
  primaryDark: "#080814", // Darker version of primary
  secondary: "#dc004e", // Pink/red accent
  secondaryLight: "#ff3378", // Lighter version of secondary
  secondaryDark: "#a30039", // Darker version of secondary
  textPrimary: "#333333",
  textSecondary: "#66615b",
  background: "#f5f5f5",
};

// Styled components
const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    borderRadius: "16px",
    maxWidth: "600px",
    width: "100%",
    boxShadow: "0 8px 32px rgba(0, 0, 0, 0.12)",
    overflow: "auto",
  },
  "& .MuiBackdrop-root": {
    backdropFilter: "blur(4px)",
    backgroundColor: "rgba(15, 15, 35, 0.5)",
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.breakpoints.down("sm") ? "16px" : "24px",
  "& .MuiOutlinedInput-root": {
    borderRadius: "10px",
    backgroundColor: "rgba(255, 255, 255, 0.08)",
    transition: "all 0.3s ease",
    "& fieldset": {
      borderColor: "rgba(0, 0, 0, 0.15)",
      transition: "all 0.3s ease",
    },
    "&:hover fieldset": {
      borderColor: "rgba(0, 0, 0, 0.3)",
    },
    "&.Mui-focused fieldset": {
      borderColor: bappaColors.secondary,
      borderWidth: "2px",
    },
    "& .MuiInputBase-input": {
      padding: theme.breakpoints.down("sm") ? "10px 12px" : "14px 16px",
    },
  },
  "& .MuiInputLabel-root": {
    color: bappaColors.textSecondary,
    fontSize: theme.breakpoints.down("sm") ? "0.9rem" : "0.95rem",
    fontWeight: 500,
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: bappaColors.secondary,
  },
  "& .MuiSelect-select": {
    padding: theme.breakpoints.down("sm") ? "10px 12px" : "14px 16px",
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  padding: "12px 24px",
  borderRadius: "10px",
  textTransform: "none",
  fontSize: "1rem",
  fontWeight: 600,
  boxShadow: `0 4px 12px rgba(15, 15, 35, 0.2)`,
  transition: "all 0.3s ease",
  background: `linear-gradient(45deg, ${bappaColors.primary} 30%, ${bappaColors.primaryLight} 90%)`,
  "&:hover": {
    boxShadow: `0 6px 16px rgba(15, 15, 35, 0.3)`,
    transform: "translateY(-2px)",
  },
  "&:active": {
    transform: "translateY(0)",
  },
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: bappaColors.secondary,
  width: 56,
  height: 56,
  boxShadow: `0 4px 12px rgba(220, 0, 78, 0.2)`,
  margin: "0 auto 16px auto",
}));

// Concern options for dropdown
const concernOptions = [
  { value: "general", label: "General Inquiry" },
  { value: "issue", label: "Technical Issue" },
  { value: "billing", label: "Billing Question" },
  { value: "feedback", label: "Feedback" },
];

// Modal component
const ContactUsModal = ({ open, onClose }) => {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    concern: "",
    message: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [notification, setNotification] = useState({
    show: false,
    message: "",
    type: "success",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Remove the form submission handler and create a button click handler instead
  const handleSendMessage = async () => {
    // Validate form fields manually
    if (
      !formData.fullName ||
      !formData.email ||
      !formData.concern ||
      !formData.message
    ) {
      setNotification({
        show: true,
        message: "Please fill in all required fields.",
        type: "error",
      });
      return;
    }

    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      setNotification({
        show: true,
        message: "Please enter a valid email address.",
        type: "error",
      });
      return;
    }

    setIsSubmitting(true);

    try {
      // Check if sendContactUs is a function
      if (typeof sendContactUs !== "function") {
        console.error("sendContactUs is not a function:", sendContactUs);
        throw new Error("sendContactUs is not a function");
      }

      const response = await sendContactUs({
        name: formData.fullName,
        email: formData.email,
        concern: formData.concern,
        message: formData.message,
      });

      setNotification({
        show: true,
        message: "Your message has been sent successfully!",
        type: "success",
      });

      // Reset form after successful submission
      setFormData({
        fullName: "",
        email: "",
        concern: "",
        message: "",
      });

      // Close modal after a short delay
      setTimeout(() => {
        onClose();
      }, 1500);
    } catch (error) {
      console.error("Error sending message:", error);
      console.error("Error details:", {
        message: error.message,
        stack: error.stack,
        name: error.name,
      });

      setNotification({
        show: true,
        message: `Failed to send message: ${error.message}. Please try again.`,
        type: "error",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCloseNotification = () => {
    setNotification({ ...notification, show: false });
  };

  return (
    <>
      <StyledDialog
        open={open}
        onClose={onClose}
        fullWidth
        maxWidth="sm"
        PaperProps={{
          sx: {
            margin: { xs: "16px", sm: "32px" },
            width: { xs: "calc(100% - 32px)", sm: "100%" },
            maxHeight: { xs: "calc(100% - 32px)", sm: "90vh" },
          },
        }}
      >
        <Paper
          elevation={0}
          sx={{
            position: "relative",
            overflow: "visible",
            bgcolor: "white",
            maxHeight: { xs: "100%", sm: "auto" },
          }}
        >
          {/* Background decoration */}
          <Box
            sx={{
              position: "absolute",
              top: -100,
              right: -100,
              width: 300,
              height: 300,
              borderRadius: "50%",
              background: `radial-gradient(circle, rgba(15, 15, 35, 0.1) 0%, rgba(15, 15, 35, 0) 70%)`,
              zIndex: 0,
            }}
          />
          <Box
            sx={{
              position: "absolute",
              bottom: -80,
              left: -80,
              width: 200,
              height: 200,
              borderRadius: "50%",
              background: `radial-gradient(circle, rgba(220, 0, 78, 0.08) 0%, rgba(220, 0, 78, 0) 70%)`,
              zIndex: 0,
            }}
          />

          <DialogTitle
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              p: 3,
              pb: 1,
            }}
          >
            <Typography
              variant="h5"
              sx={{ fontWeight: 700, color: bappaColors.primary }}
            >
              Contact Us
            </Typography>
            <IconButton
              onClick={onClose}
              size="small"
              sx={{
                backgroundColor: "rgba(0, 0, 0, 0.04)",
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.08)",
                },
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <DialogContent
            sx={{
              p: 3,
              pt: 2,
              position: "relative",
              zIndex: 1,
              overflowY: "auto", // Ensure vertical scrolling is enabled
              maxHeight: { xs: "calc(100vh - 120px)", sm: "none" }, // Limit height on mobile
            }}
          >
            <Box sx={{ textAlign: "center", mb: 3 }}>
              <StyledAvatar>
                <EmailIcon />
              </StyledAvatar>
              <Typography
                variant="body1"
                sx={{
                  color: bappaColors.textSecondary,
                  maxWidth: "90%",
                  mx: "auto",
                }}
              >
                We'd love to hear from you! Please fill out the form below and
                we'll get back to you as soon as possible.
              </Typography>
            </Box>

            <Divider sx={{ my: 3, opacity: 0.6 }} />

            {/* Change from form to div to prevent form submission */}
            <Box
              sx={{
                mt: 2,
                px: { xs: 0, sm: 1 }, // Add responsive horizontal padding
              }}
            >
              <StyledTextField
                label="Full Name*"
                name="fullName"
                value={formData.fullName}
                onChange={handleChange}
                fullWidth
                required
                variant="outlined"
                InputProps={{
                  sx: { fontWeight: 500 },
                }}
              />

              <StyledTextField
                label="Email Address*"
                name="email"
                type="email"
                value={formData.email}
                onChange={handleChange}
                fullWidth
                required
                variant="outlined"
                InputProps={{
                  sx: { fontWeight: 500 },
                }}
              />

              <StyledTextField
                select
                label="Concern*"
                name="concern"
                value={formData.concern}
                onChange={handleChange}
                fullWidth
                required
                variant="outlined"
                InputProps={{
                  sx: { fontWeight: 500 },
                }}
              >
                {concernOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </StyledTextField>

              <StyledTextField
                label="Message*"
                name="message"
                value={formData.message}
                onChange={handleChange}
                fullWidth
                required
                multiline
                rows={
                  useMediaQuery((theme) => theme.breakpoints.down("sm")) ? 3 : 4
                }
                variant="outlined"
                InputProps={{
                  sx: { fontWeight: 500 },
                }}
              />

              <StyledButton
                variant="contained"
                fullWidth
                disabled={isSubmitting}
                onClick={handleSendMessage}
                disableElevation
              >
                {isSubmitting ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Send Message"
                )}
              </StyledButton>

              <Typography
                variant="caption"
                sx={{
                  display: "block",
                  textAlign: "center",
                  mt: 2,
                  color: bappaColors.textSecondary,
                  opacity: 0.7,
                }}
              >
                We typically respond within 24-48 hours
              </Typography>
            </Box>
          </DialogContent>
        </Paper>
      </StyledDialog>

      <Snackbar
        open={notification.show}
        autoHideDuration={6000}
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseNotification}
          severity={notification.type}
          sx={{
            width: "100%",
            boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)",
            borderRadius: "8px",
          }}
        >
          {notification.message}
        </Alert>
      </Snackbar>
    </>
  );
};

// Main ContactUs component that includes the button and modal
const ContactUs = ({ open, handleClose }) => {
  return (
    <>
      <ContactUsModal open={open} onClose={handleClose} />
    </>
  );
};

// Make sure to export both components
export default ContactUs;
export { ContactUsModal };
