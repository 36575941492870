import React, { useState, useRef } from "react";
import {
  Container,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Paper,
  Divider,
  Tabs,
  Tab,
  List,
  ListItem,
  ListItemText,
  Grid,
  Chip,
  Button,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import UpdateIcon from "@mui/icons-material/Update";
import ContactUs from "../../components/ContactUs";
import AppHeader from "../../components/Navigation/AppHeader";

const LegalPage = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [expandedSection, setExpandedSection] = useState(null);
  const [contactUsOpen, setContactUsOpen] = useState(false);

  const privacyRef = useRef(null);
  const termsRef = useRef(null);

  // Handle tab change
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    if (newValue === 0 && privacyRef.current) {
      privacyRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (newValue === 1 && termsRef.current) {
      termsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  // Handle accordion expansion
  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedSection(isExpanded ? panel : null);
  };

  // Handle contact us modal
  const handleOpenContactUs = () => {
    setContactUsOpen(true);
  };

  const handleCloseContactUs = () => {
    setContactUsOpen(false);
  };

  // Current date for last updated
  const currentDate = new Date().toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  return (
    <>
      <AppHeader backgroundColor="#0f0f23" maxWidth="lg" />

      {/* Enhanced Legal Page Content */}
      <Container maxWidth="lg" sx={{ mt: 12, mb: 8 }}>
        {/* Header Section */}
        <Paper
          elevation={0}
          sx={{ p: 4, mb: 4, borderRadius: 2, bgcolor: "#f8f9fa" }}
        >
          <Typography
            variant="h4"
            component="h1"
            gutterBottom
            fontWeight="bold"
          >
            Legal Information
          </Typography>
          <Typography variant="body1" paragraph color="text.secondary">
            This page contains BappaCards' Privacy Policy and Terms of Service.
            These documents outline how we collect, use, and protect your data,
            as well as the terms governing your use of our services.
          </Typography>

          <Box sx={{ display: "flex", gap: 2, mt: 2 }}>
            <Chip
              icon={<UpdateIcon />}
              label={`Last updated: ${currentDate}`}
              variant="outlined"
              size="small"
            />
          </Box>
        </Paper>

        {/* Tab Navigation */}
        <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 4 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              aria-label="legal document tabs"
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab label="Privacy Policy" id="tab-0" />
              <Tab label="Terms of Service" id="tab-1" />
            </Tabs>
            <Button
              variant="contained"
              onClick={handleOpenContactUs}
              sx={{
                bgcolor: "#dc004e",
                textTransform: "none",
                fontSize: "0.9rem",
                fontWeight: 500,
                px: 3,
                py: 1,
                borderRadius: "8px",
                "&:hover": {
                  bgcolor: "#0f0f23",
                },
              }}
            >
              Contact Us
            </Button>
          </Box>
        </Box>

        {/* Main Content with Sidebar */}
        <Grid container spacing={4}>
          {/* Sidebar - Table of Contents */}
          <Grid item xs={12} md={3}>
            <Paper
              elevation={0}
              sx={{
                p: 3,
                borderRadius: 2,
                border: "1px solid #e0e0e0",
                position: { md: "sticky" },
                top: { md: 100 },
              }}
            >
              <Typography variant="h6" gutterBottom>
                Table of Contents
              </Typography>
              <Divider sx={{ mb: 2 }} />

              {activeTab === 0 ? (
                <List dense disablePadding>
                  <ListItem
                    button
                    onClick={() => setExpandedSection("privacy-collection")}
                  >
                    <ListItemText primary="1. Information We Collect" />
                  </ListItem>
                  <ListItem
                    button
                    onClick={() => setExpandedSection("privacy-use")}
                  >
                    <ListItemText primary="2. How We Use Your Information" />
                  </ListItem>
                  <ListItem
                    button
                    onClick={() => setExpandedSection("privacy-sharing")}
                  >
                    <ListItemText primary="3. Information Sharing" />
                  </ListItem>
                  <ListItem
                    button
                    onClick={() => setExpandedSection("privacy-security")}
                  >
                    <ListItemText primary="4. Data Security" />
                  </ListItem>
                  <ListItem
                    button
                    onClick={() => setExpandedSection("privacy-rights")}
                  >
                    <ListItemText primary="5. Your Rights" />
                  </ListItem>
                  <ListItem
                    button
                    onClick={() => setExpandedSection("privacy-changes")}
                  >
                    <ListItemText primary="6. Changes to Privacy Policy" />
                  </ListItem>
                  <ListItem button onClick={handleOpenContactUs}>
                    <ListItemText primary="7. Contact Us" />
                  </ListItem>
                </List>
              ) : (
                <List dense disablePadding>
                  <ListItem
                    button
                    onClick={() => setExpandedSection("terms-acceptance")}
                  >
                    <ListItemText primary="1. Acceptance of Terms" />
                  </ListItem>
                  <ListItem
                    button
                    onClick={() => setExpandedSection("terms-use")}
                  >
                    <ListItemText primary="2. Use of the Platform" />
                  </ListItem>
                  <ListItem
                    button
                    onClick={() => setExpandedSection("terms-accounts")}
                  >
                    <ListItemText primary="3. User Accounts" />
                  </ListItem>
                  <ListItem
                    button
                    onClick={() => setExpandedSection("terms-content")}
                  >
                    <ListItemText primary="4. User Content" />
                  </ListItem>
                  <ListItem
                    button
                    onClick={() => setExpandedSection("terms-intellectual")}
                  >
                    <ListItemText primary="5. Intellectual Property" />
                  </ListItem>
                  <ListItem
                    button
                    onClick={() => setExpandedSection("terms-liability")}
                  >
                    <ListItemText primary="6. Limitation of Liability" />
                  </ListItem>
                  <ListItem
                    button
                    onClick={() => setExpandedSection("terms-termination")}
                  >
                    <ListItemText primary="7. Termination" />
                  </ListItem>
                  <Divider sx={{ my: 1 }} />
                  <ListItem button onClick={handleOpenContactUs}>
                    <ListItemText
                      primary="Contact Us"
                      sx={{ color: "#dc004e" }}
                    />
                  </ListItem>
                </List>
              )}
            </Paper>
          </Grid>

          {/* Main Content Area */}
          <Grid item xs={12} md={9}>
            {/* Privacy Policy Section */}
            <Box
              ref={privacyRef}
              sx={{ display: activeTab === 0 ? "block" : "none" }}
            >
              <Typography variant="h4" gutterBottom fontWeight="medium">
                Privacy Policy
              </Typography>
              <Typography variant="body2" paragraph color="text.secondary">
                <strong>Effective Date:</strong> January 1st, 2022 |{" "}
                <strong>Last Updated:</strong> {currentDate}
              </Typography>
              <Typography variant="body1" paragraph>
                Welcome to BappaCards, a digital business card platform. We are
                committed to protecting your personal information and respecting
                your privacy. This Privacy Policy explains how we collect, use,
                and protect your information when you use our services.
              </Typography>

              {/* Information We Collect */}
              <Accordion
                expanded={expandedSection === "privacy-collection"}
                onChange={handleAccordionChange("privacy-collection")}
                sx={{
                  mb: 2,
                  border: "1px solid #e0e0e0",
                  borderRadius: "4px",
                  "&:before": { display: "none" },
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="privacy-collection-content"
                  id="privacy-collection-header"
                  sx={{ bgcolor: "#f8f9fa" }}
                >
                  <Typography variant="h6" fontWeight="medium">
                    1. Information We Collect
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ pt: 3 }}>
                  <Typography variant="body1" paragraph>
                    We collect several types of information from and about users
                    of our Services, including:
                  </Typography>

                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    gutterBottom
                  >
                    1.1 Personal Information
                  </Typography>
                  <Typography variant="body1" paragraph>
                    When you register for and use our Services, we may collect
                    the following personal information:
                  </Typography>
                  <ul>
                    <li>
                      <Typography variant="body1">
                        <strong>Contact Information:</strong> First name, last
                        name, email address, phone number, and professional
                        title.
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="body1">
                        <strong>Professional Information:</strong> Company name,
                        job title, department, and business address.
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="body1">
                        <strong>Social Media Profiles:</strong> Links to your
                        professional social media accounts (e.g., LinkedIn,
                        Twitter, GitHub).
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="body1">
                        <strong>Profile Image:</strong> Photographs or avatars
                        you choose to associate with your account.
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="body1">
                        <strong>Custom Fields:</strong> Any additional
                        information you choose to include on your digital
                        business card.
                      </Typography>
                    </li>
                  </ul>

                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    gutterBottom
                  >
                    1.2 Usage Information
                  </Typography>
                  <Typography variant="body1" paragraph>
                    We automatically collect certain information about your
                    device and how you interact with our Services:
                  </Typography>
                  <ul>
                    <li>
                      <Typography variant="body1">
                        <strong>Device Information:</strong> IP address, browser
                        type, operating system, device type, and mobile device
                        identifiers.
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="body1">
                        <strong>Usage Data:</strong> Information about how you
                        use our Services, including pages visited, features
                        used, and actions taken.
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="body1">
                        <strong>Card Interactions:</strong> Data on when your
                        digital business card is viewed, shared, or saved by
                        others.
                      </Typography>
                    </li>
                  </ul>
                </AccordionDetails>
              </Accordion>

              {/* How We Use Your Information */}
              <Accordion
                expanded={expandedSection === "privacy-use"}
                onChange={handleAccordionChange("privacy-use")}
                sx={{
                  mb: 2,
                  border: "1px solid #e0e0e0",
                  borderRadius: "4px",
                  "&:before": { display: "none" },
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="privacy-use-content"
                  id="privacy-use-header"
                  sx={{ bgcolor: "#f8f9fa" }}
                >
                  <Typography variant="h6" fontWeight="medium">
                    2. How We Use Your Information
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ pt: 3 }}>
                  <Typography variant="body1" paragraph>
                    We use the information we collect for various purposes,
                    including:
                  </Typography>

                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    gutterBottom
                  >
                    2.1 Providing and Improving Our Services
                  </Typography>
                  <ul>
                    <li>
                      <Typography variant="body1">
                        Creating and managing your BappaCards account and
                        digital business cards.
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="body1">
                        Displaying your information on digital business cards
                        you choose to share.
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="body1">
                        Processing transactions and fulfilling orders for
                        premium features or subscriptions.
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="body1">
                        Analyzing usage patterns to improve our Services and
                        develop new features.
                      </Typography>
                    </li>
                  </ul>
                </AccordionDetails>
              </Accordion>

              {/* Information Sharing */}
              <Accordion
                expanded={expandedSection === "privacy-sharing"}
                onChange={handleAccordionChange("privacy-sharing")}
                sx={{
                  mb: 2,
                  border: "1px solid #e0e0e0",
                  borderRadius: "4px",
                  "&:before": { display: "none" },
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="privacy-sharing-content"
                  id="privacy-sharing-header"
                  sx={{ bgcolor: "#f8f9fa" }}
                >
                  <Typography variant="h6" fontWeight="medium">
                    3. Information Sharing
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ pt: 3 }}>
                  <Typography variant="body1" paragraph>
                    We may share your information in the following
                    circumstances:
                  </Typography>

                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    gutterBottom
                  >
                    3.1 With Your Consent
                  </Typography>
                  <Typography variant="body1" paragraph>
                    We share your personal information when you explicitly
                    consent to the sharing, such as when you choose to share
                    your digital business card with others.
                  </Typography>

                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    gutterBottom
                  >
                    3.2 Service Providers
                  </Typography>
                  <Typography variant="body1" paragraph>
                    We may share your information with third-party vendors,
                    service providers, contractors, or agents who perform
                    services on our behalf, such as cloud storage providers,
                    payment processors, and analytics providers.
                  </Typography>
                </AccordionDetails>
              </Accordion>

              {/* Data Security */}
              <Accordion
                expanded={expandedSection === "privacy-security"}
                onChange={handleAccordionChange("privacy-security")}
                sx={{
                  mb: 2,
                  border: "1px solid #e0e0e0",
                  borderRadius: "4px",
                  "&:before": { display: "none" },
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="privacy-security-content"
                  id="privacy-security-header"
                  sx={{ bgcolor: "#f8f9fa" }}
                >
                  <Typography variant="h6" fontWeight="medium">
                    4. Data Security
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ pt: 3 }}>
                  <Typography variant="body1" paragraph>
                    We implement appropriate technical and organizational
                    measures to protect the security of your personal
                    information. However, please understand that no method of
                    transmission over the Internet or method of electronic
                    storage is 100% secure.
                  </Typography>

                  <Typography variant="body1" paragraph>
                    Our security measures include:
                  </Typography>
                  <ul>
                    <li>
                      <Typography variant="body1">
                        <strong>Encryption:</strong> We use industry-standard
                        encryption to protect your data in transit and at rest.
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="body1">
                        <strong>Access Controls:</strong> We restrict access to
                        your personal information to employees, contractors, and
                        agents who need to know that information to provide
                        services to you.
                      </Typography>
                    </li>
                  </ul>
                </AccordionDetails>
              </Accordion>

              {/* Your Rights */}
              <Accordion
                expanded={expandedSection === "privacy-rights"}
                onChange={handleAccordionChange("privacy-rights")}
                sx={{
                  mb: 2,
                  border: "1px solid #e0e0e0",
                  borderRadius: "4px",
                  "&:before": { display: "none" },
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="privacy-rights-content"
                  id="privacy-rights-header"
                  sx={{ bgcolor: "#f8f9fa" }}
                >
                  <Typography variant="h6" fontWeight="medium">
                    5. Your Rights
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ pt: 3 }}>
                  <Typography variant="body1" paragraph>
                    Depending on your location, you may have certain rights
                    regarding your personal information. These may include:
                  </Typography>

                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    gutterBottom
                  >
                    5.1 Access and Information
                  </Typography>
                  <Typography variant="body1" paragraph>
                    You have the right to access the personal information we
                    hold about you and to receive information about how we use
                    it.
                  </Typography>

                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    gutterBottom
                  >
                    5.2 Correction
                  </Typography>
                  <Typography variant="body1" paragraph>
                    You have the right to request that we correct any inaccurate
                    or incomplete personal information we hold about you.
                  </Typography>

                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    gutterBottom
                  >
                    5.3 Deletion
                  </Typography>
                  <Typography variant="body1" paragraph>
                    You have the right to request that we delete your personal
                    information in certain circumstances, such as when it is no
                    longer necessary for the purposes for which it was
                    collected.
                  </Typography>
                </AccordionDetails>
              </Accordion>

              {/* Changes to Privacy Policy */}
              <Accordion
                expanded={expandedSection === "privacy-changes"}
                onChange={handleAccordionChange("privacy-changes")}
                sx={{
                  mb: 2,
                  border: "1px solid #e0e0e0",
                  borderRadius: "4px",
                  "&:before": { display: "none" },
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="privacy-changes-content"
                  id="privacy-changes-header"
                  sx={{ bgcolor: "#f8f9fa" }}
                >
                  <Typography variant="h6" fontWeight="medium">
                    6. Changes to Privacy Policy
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ pt: 3 }}>
                  <Typography variant="body1" paragraph>
                    We may update this Privacy Policy from time to time to
                    reflect changes in our practices or for other operational,
                    legal, or regulatory reasons. We will notify you of any
                    material changes by:
                  </Typography>
                  <ul>
                    <li>
                      <Typography variant="body1">
                        Posting the updated Privacy Policy on our website
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="body1">
                        Updating the "Last Updated" date at the top of this
                        Privacy Policy
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="body1">
                        Sending an email notification to the email address
                        associated with your account (for significant changes)
                      </Typography>
                    </li>
                  </ul>

                  <Typography variant="body1" paragraph>
                    We encourage you to review this Privacy Policy periodically
                    to stay informed about our information practices. Your
                    continued use of our Services after any changes to this
                    Privacy Policy constitutes your acceptance of the updated
                    policy.
                  </Typography>
                </AccordionDetails>
              </Accordion>

              {/* Contact Us */}
              <Accordion
                expanded={expandedSection === "privacy-contact"}
                onChange={handleAccordionChange("privacy-contact")}
                sx={{
                  mb: 2,
                  border: "1px solid #e0e0e0",
                  borderRadius: "4px",
                  "&:before": { display: "none" },
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="privacy-contact-content"
                  id="privacy-contact-header"
                  sx={{ bgcolor: "#f8f9fa" }}
                >
                  <Typography variant="h6" fontWeight="medium">
                    7. Contact Us
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ pt: 3 }}>
                  <Typography variant="body1" paragraph>
                    If you have any questions, concerns, or requests regarding
                    this Privacy Policy or our privacy practices, please use the
                    Contact Us button at the top of this page.
                  </Typography>
                  <Typography variant="body1" paragraph>
                    We will respond to your inquiry as soon as possible and
                    within the timeframe required by applicable law.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Box>

            {/* Terms of Service Section */}
            <Box
              ref={termsRef}
              sx={{ display: activeTab === 1 ? "block" : "none" }}
            >
              <Typography variant="h4" gutterBottom fontWeight="medium">
                Terms of Service
              </Typography>
              <Typography variant="body2" paragraph color="text.secondary">
                <strong>Effective Date:</strong> January 1st, 2022 |{" "}
                <strong>Last Updated:</strong> {currentDate}
              </Typography>
              <Typography variant="body1" paragraph>
                These Terms of Service ("Terms") govern your access to and use
                of BappaCards' website, services, and applications
                (collectively, the "Services"). Please read these Terms
                carefully before using our Services.
              </Typography>

              {/* Acceptance of Terms */}
              <Accordion
                expanded={expandedSection === "terms-acceptance"}
                onChange={handleAccordionChange("terms-acceptance")}
                sx={{
                  mb: 2,
                  border: "1px solid #e0e0e0",
                  borderRadius: "4px",
                  "&:before": { display: "none" },
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="terms-acceptance-content"
                  id="terms-acceptance-header"
                  sx={{ bgcolor: "#f8f9fa" }}
                >
                  <Typography variant="h6" fontWeight="medium">
                    1. Acceptance of Terms
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ pt: 3 }}>
                  <Typography variant="body1" paragraph>
                    By accessing or using our Services, you acknowledge that you
                    have read, understood, and agree to be bound by these Terms.
                    If you do not agree to these Terms, you must not access or
                    use our Services.
                  </Typography>

                  <Typography variant="body1" paragraph>
                    We may modify these Terms at any time. If we make changes,
                    we will provide notice of such changes, such as by sending
                    an email notification, providing notice through our
                    Services, or updating the "Last Updated" date at the
                    beginning of these Terms. Your continued use of our Services
                    following notification of changes will constitute your
                    acceptance of such changes. If you do not agree with any
                    modifications to these Terms, you must immediately stop
                    using our Services.
                  </Typography>
                </AccordionDetails>
              </Accordion>

              {/* Use of the Platform */}
              <Accordion
                expanded={expandedSection === "terms-use"}
                onChange={handleAccordionChange("terms-use")}
                sx={{
                  mb: 2,
                  border: "1px solid #e0e0e0",
                  borderRadius: "4px",
                  "&:before": { display: "none" },
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="terms-use-content"
                  id="terms-use-header"
                  sx={{ bgcolor: "#f8f9fa" }}
                >
                  <Typography variant="h6" fontWeight="medium">
                    2. Use of the Platform
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ pt: 3 }}>
                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    gutterBottom
                  >
                    2.1 Eligibility
                  </Typography>
                  <Typography variant="body1" paragraph>
                    To use our Services, you must be at least 18 years old and
                    capable of forming a binding contract with BappaCards. By
                    using our Services, you represent and warrant that you meet
                    these requirements.
                  </Typography>

                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    gutterBottom
                  >
                    2.2 Account Registration
                  </Typography>
                  <Typography variant="body1" paragraph>
                    To access certain features of our Services, you may need to
                    register for an account. When you register, you agree to
                    provide accurate, current, and complete information about
                    yourself and to update such information as necessary. You
                    are responsible for safeguarding your account credentials
                    and for all activities that occur under your account. You
                    agree to notify us immediately of any unauthorized use of
                    your account.
                  </Typography>

                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    gutterBottom
                  >
                    2.3 Prohibited Activities
                  </Typography>
                  <Typography variant="body1" paragraph>
                    You agree not to engage in any of the following prohibited
                    activities:
                  </Typography>
                  <ul>
                    <li>
                      <Typography variant="body1">
                        Using our Services for any illegal purpose or in
                        violation of any local, state, national, or
                        international law
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="body1">
                        Violating or infringing other people's intellectual
                        property, privacy, publicity, or other legal rights
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="body1">
                        Impersonating any person or entity, or falsely stating
                        or otherwise misrepresenting your affiliation with a
                        person or entity
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="body1">
                        Interfering with or disrupting the Services or servers
                        or networks connected to the Services
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="body1">
                        Attempting to gain unauthorized access to any part of
                        our Services, other accounts, or computer systems or
                        networks connected to our Services
                      </Typography>
                    </li>
                  </ul>
                </AccordionDetails>
              </Accordion>

              {/* User Accounts */}
              <Accordion
                expanded={expandedSection === "terms-accounts"}
                onChange={handleAccordionChange("terms-accounts")}
                sx={{
                  mb: 2,
                  border: "1px solid #e0e0e0",
                  borderRadius: "4px",
                  "&:before": { display: "none" },
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="terms-accounts-content"
                  id="terms-accounts-header"
                  sx={{ bgcolor: "#f8f9fa" }}
                >
                  <Typography variant="h6" fontWeight="medium">
                    3. User Accounts
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ pt: 3 }}>
                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    gutterBottom
                  >
                    3.1 Account Responsibility
                  </Typography>
                  <Typography variant="body1" paragraph>
                    You are responsible for maintaining the confidentiality of
                    your account credentials and for all activities that occur
                    under your account. You agree to notify us immediately of
                    any unauthorized use of your account or any other breach of
                    security.
                  </Typography>

                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    gutterBottom
                  >
                    3.2 Account Termination
                  </Typography>
                  <Typography variant="body1" paragraph>
                    We reserve the right to suspend or terminate your account
                    and access to our Services at any time, without notice, for
                    conduct that we determine violates these Terms or is harmful
                    to other users of our Services, us, or third parties, or for
                    any other reason at our sole discretion.
                  </Typography>
                </AccordionDetails>
              </Accordion>

              {/* User Content */}
              <Accordion
                expanded={expandedSection === "terms-content"}
                onChange={handleAccordionChange("terms-content")}
                sx={{
                  mb: 2,
                  border: "1px solid #e0e0e0",
                  borderRadius: "4px",
                  "&:before": { display: "none" },
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="terms-content-content"
                  id="terms-content-header"
                  sx={{ bgcolor: "#f8f9fa" }}
                >
                  <Typography variant="h6" fontWeight="medium">
                    4. User Content
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ pt: 3 }}>
                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    gutterBottom
                  >
                    4.1 Content Ownership
                  </Typography>
                  <Typography variant="body1" paragraph>
                    You retain all ownership rights to the content you submit,
                    post, or display on or through our Services ("User
                    Content"). By submitting, posting, or displaying User
                    Content on or through our Services, you grant us a
                    worldwide, non-exclusive, royalty-free license to use,
                    reproduce, modify, adapt, publish, translate, create
                    derivative works from, distribute, and display such User
                    Content in connection with providing and promoting our
                    Services.
                  </Typography>

                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    gutterBottom
                  >
                    4.2 Content Responsibility
                  </Typography>
                  <Typography variant="body1" paragraph>
                    You are solely responsible for your User Content and the
                    consequences of posting or publishing it. By uploading User
                    Content, you represent and warrant that:
                  </Typography>
                  <ul>
                    <li>
                      <Typography variant="body1">
                        You own or have the necessary rights to use and
                        authorize us to use your User Content as described in
                        these Terms
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="body1">
                        Your User Content does not violate the privacy rights,
                        publicity rights, copyright rights, or other rights of
                        any person or entity
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="body1">
                        Your User Content does not contain any material that is
                        defamatory, obscene, indecent, abusive, offensive,
                        harassing, violent, hateful, inflammatory, or otherwise
                        objectionable
                      </Typography>
                    </li>
                  </ul>
                </AccordionDetails>
              </Accordion>

              {/* Intellectual Property */}
              <Accordion
                expanded={expandedSection === "terms-intellectual"}
                onChange={handleAccordionChange("terms-intellectual")}
                sx={{
                  mb: 2,
                  border: "1px solid #e0e0e0",
                  borderRadius: "4px",
                  "&:before": { display: "none" },
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="terms-intellectual-content"
                  id="terms-intellectual-header"
                  sx={{ bgcolor: "#f8f9fa" }}
                >
                  <Typography variant="h6" fontWeight="medium">
                    5. Intellectual Property
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ pt: 3 }}>
                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    gutterBottom
                  >
                    5.1 BappaCards Intellectual Property
                  </Typography>
                  <Typography variant="body1" paragraph>
                    Our Services and their entire contents, features, and
                    functionality (including but not limited to all information,
                    software, text, displays, images, video, and audio, and the
                    design, selection, and arrangement thereof) are owned by
                    BappaCards, its licensors, or other providers of such
                    material and are protected by copyright, trademark, patent,
                    trade secret, and other intellectual property or proprietary
                    rights laws.
                  </Typography>

                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    gutterBottom
                  >
                    5.2 License to Use
                  </Typography>
                  <Typography variant="body1" paragraph>
                    Subject to these Terms, we grant you a limited,
                    non-exclusive, non-transferable, and revocable license to
                    access and use our Services for your personal,
                    non-commercial use. You may not:
                  </Typography>
                  <ul>
                    <li>
                      <Typography variant="body1">
                        Reproduce, distribute, modify, create derivative works
                        of, publicly display, publicly perform, republish,
                        download, store, or transmit any of the material on our
                        Services, except as permitted by these Terms
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="body1">
                        Delete or alter any copyright, trademark, or other
                        proprietary rights notices from copies of materials from
                        our Services
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="body1">
                        Access or use for any commercial purposes any part of
                        our Services or any services or materials available
                        through our Services
                      </Typography>
                    </li>
                  </ul>
                </AccordionDetails>
              </Accordion>

              {/* Limitation of Liability */}
              <Accordion
                expanded={expandedSection === "terms-liability"}
                onChange={handleAccordionChange("terms-liability")}
                sx={{
                  mb: 2,
                  border: "1px solid #e0e0e0",
                  borderRadius: "4px",
                  "&:before": { display: "none" },
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="terms-liability-content"
                  id="terms-liability-header"
                  sx={{ bgcolor: "#f8f9fa" }}
                >
                  <Typography variant="h6" fontWeight="medium">
                    6. Limitation of Liability
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ pt: 3 }}>
                  <Typography variant="body1" paragraph>
                    TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT SHALL
                    BAPPACARDS, ITS AFFILIATES, OR THEIR LICENSORS, SERVICE
                    PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, OR DIRECTORS BE
                    LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY,
                    ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY
                    TO USE, OUR SERVICES, INCLUDING ANY DIRECT, INDIRECT,
                    SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES.
                  </Typography>

                  <Typography variant="body1" paragraph>
                    THE FOREGOING DOES NOT AFFECT ANY LIABILITY THAT CANNOT BE
                    EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
                  </Typography>
                </AccordionDetails>
              </Accordion>

              {/* Termination */}
              <Accordion
                expanded={expandedSection === "terms-termination"}
                onChange={handleAccordionChange("terms-termination")}
                sx={{
                  mb: 2,
                  border: "1px solid #e0e0e0",
                  borderRadius: "4px",
                  "&:before": { display: "none" },
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="terms-termination-content"
                  id="terms-termination-header"
                  sx={{ bgcolor: "#f8f9fa" }}
                >
                  <Typography variant="h6" fontWeight="medium">
                    7. Termination
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ pt: 3 }}>
                  <Typography variant="body1" paragraph>
                    We may terminate or suspend your account and access to our
                    Services immediately, without prior notice or liability, for
                    any reason whatsoever, including, without limitation, if you
                    breach these Terms.
                  </Typography>

                  <Typography variant="body1" paragraph>
                    Upon termination, your right to use our Services will
                    immediately cease. If you wish to terminate your account,
                    you may simply discontinue using our Services or contact us
                    to request account deletion.
                  </Typography>

                  <Typography variant="body1" paragraph>
                    All provisions of these Terms which by their nature should
                    survive termination shall survive termination, including,
                    without limitation, ownership provisions, warranty
                    disclaimers, indemnity, and limitations of liability.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Box>
          </Grid>
        </Grid>
      </Container>

      {/* Contact Us Modal */}
      <ContactUs open={contactUsOpen} handleClose={handleCloseContactUs} />
    </>
  );
};

export default LegalPage;
