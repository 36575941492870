import React, { useState } from "react";
import {
  Box,
  Container,
  Typography,
  Link,
  Grid,
  IconButton,
  Divider,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { Instagram as InstagramIcon } from "@mui/icons-material";
import { Link as RouterLink } from "react-router-dom";
import bappaLogo from "../../images/bappa-logo.png";
import ContactUs from "../../components/ContactUs";

const Footer = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const currentYear = new Date().getFullYear();
  const [contactModalOpen, setContactModalOpen] = useState(false);

  const handleOpenContactModal = () => {
    setContactModalOpen(true);
  };

  const handleCloseContactModal = () => {
    setContactModalOpen(false);
  };

  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: "#0f0f23",
        color: "white",
        py: { xs: 1, md: 2 },
        width: "100%",
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={1}>
          {/* Logo and Description - Hidden on mobile */}
          <Grid
            item
            xs={12}
            md={3}
            sx={{ display: { xs: "none", md: "block" } }}
          >
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              <Box
                component="img"
                src={bappaLogo}
                alt="BappaCards"
                sx={{ height: 20, mb: 0.5 }}
              />
              <Typography variant="body2" sx={{ fontSize: "0.7rem" }}>
                Digital business cards for modern professionals.
              </Typography>
            </Box>
          </Grid>

          {/* Company Links */}
          <Grid item xs={6} sm={4} md={3}>
            <Typography
              variant="subtitle2"
              sx={{ mb: 0.5, fontWeight: "bold", fontSize: "0.75rem" }}
            >
              COMPANY
            </Typography>
            <Box display="flex" flexDirection="column">
              <Link
                component={RouterLink}
                to="/about"
                color="inherit"
                underline="hover"
                sx={{ mb: 0.5, fontSize: "0.8rem" }}
              >
                About Us
              </Link>
              <Link
                component="button"
                color="inherit"
                underline="hover"
                onClick={handleOpenContactModal}
                sx={{ mb: 0.5, fontSize: "0.8rem", textAlign: "left" }}
              >
                Contact
              </Link>
              <Link
                component={RouterLink}
                to="/privacy-notice"
                color="inherit"
                underline="hover"
                sx={{ mb: 0.5, fontSize: "0.8rem" }}
              >
                Privacy Notice & Terms
              </Link>
            </Box>
          </Grid>

          {/* Products Links */}
          <Grid item xs={6} sm={4} md={3}>
            <Typography
              variant="subtitle2"
              sx={{ mb: 0.5, fontWeight: "bold", fontSize: "0.75rem" }}
            >
              PRODUCTS
            </Typography>
            <Box display="flex" flexDirection="column">
              <Link
                component={RouterLink}
                to="/pricing"
                color="inherit"
                underline="hover"
                sx={{ mb: 0.5, fontSize: "0.8rem" }}
              >
                Features
              </Link>
              <Link
                component={RouterLink}
                to="/pricing"
                color="inherit"
                underline="hover"
                sx={{ mb: 0.5, fontSize: "0.8rem" }}
              >
                Pricing
              </Link>
              <Link
                component={RouterLink}
                to="/teams"
                color="inherit"
                underline="hover"
                sx={{ mb: 0.5, fontSize: "0.8rem" }}
              >
                For Teams
              </Link>
            </Box>
          </Grid>

          {/* Stay Updated - Hidden on small screens */}
          <Grid
            item
            xs={12}
            sm={4}
            md={3}
            sx={{ display: { xs: isTablet ? "none" : "block", sm: "block" } }}
          >
            <Typography
              variant="subtitle2"
              sx={{ mb: 0.5, fontWeight: "bold", fontSize: "0.75rem" }}
            >
              STAY UPDATED
            </Typography>
            <Typography variant="body2" sx={{ mb: 0.5, fontSize: "0.7rem" }}>
              Connect with us to stay up to date on latest Bappa News!
            </Typography>
            <Box display="flex" gap={1}>
              <IconButton
                color="inherit"
                aria-label="Instagram"
                size="small"
                component="a"
                href="https://instagram.com/bappacards"
                target="_blank"
              >
                <InstagramIcon fontSize="small" />
              </IconButton>
            </Box>
          </Grid>
        </Grid>

        <Divider sx={{ my: 1, backgroundColor: "rgba(255, 255, 255, 0.1)" }} />

        <Box
          display="flex"
          flexDirection={isMobile ? "column" : "row"}
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            variant="body2"
            sx={{
              fontSize: "0.7rem",
              textAlign: isMobile ? "center" : "left",
            }}
          >
            © {currentYear} BappaCards. All rights reserved.
          </Typography>
          {!isMobile && (
            <Box display="flex">
              <Link
                component={RouterLink}
                to="/privacy-notice"
                color="inherit"
                underline="hover"
                sx={{ mx: 1, fontSize: "0.7rem" }}
              >
                Privacy Policy
              </Link>
              <Link
                component={RouterLink}
                to="/privacy-notice"
                color="inherit"
                underline="hover"
                sx={{ mx: 1, fontSize: "0.7rem" }}
              >
                Terms of Service
              </Link>
            </Box>
          )}
        </Box>
      </Container>
      <ContactUs
        open={contactModalOpen}
        handleClose={handleCloseContactModal}
      />
    </Box>
  );
};

export default Footer;
