import React, { useState } from "react";
import { useUserContext } from "../pages/Login/userContext";
import * as action from "../service/action";
import {
  Button,
  Box,
  Alert,
  IconButton,
  FormControl,
  Typography,
} from "@mui/material";
import CopyAllIcon from "@mui/icons-material/CopyAll";

const CreateCardSection = () => {
  const { getUser } = useUserContext();
  const [cardUrl, setCardUrl] = useState("");
  const [cardId, setCardId] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const createCard = async () => {
    try {
      setIsLoading(true);
      const token = await getUser()?.getIdToken();
      if (!token) {
        throw new Error("Authentication token not available");
      }

      const response = await action.createCard(token);
      setCardUrl(response.card_url);
      setCardId(response.id);
    } catch (error) {
      console.error("Error creating card:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
  };

  return (
    <Box>
      <Typography variant="body1" paragraph>
        Create a new card to distribute to users. Once created, you can copy the
        card URL or ID.
      </Typography>

      <FormControl fullWidth>
        <Button
          variant="contained"
          color="primary"
          onClick={createCard}
          disabled={isLoading}
          sx={{ mb: 3 }}
        >
          {isLoading ? "Creating..." : "Create New Card"}
        </Button>

        {cardUrl && (
          <>
            <Alert
              severity="success"
              icon={false}
              action={
                <IconButton
                  aria-label="copy"
                  color="inherit"
                  size="small"
                  onClick={() => copyToClipboard(cardUrl)}
                >
                  <CopyAllIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              <Typography variant="body2" fontWeight={500}>
                Card URL:
              </Typography>
              <Typography variant="body2" sx={{ wordBreak: "break-all" }}>
                {cardUrl}
              </Typography>
            </Alert>

            <Alert
              severity="info"
              icon={false}
              action={
                <IconButton
                  aria-label="copy"
                  color="inherit"
                  size="small"
                  onClick={() => copyToClipboard(cardId)}
                >
                  <CopyAllIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              <Typography variant="body2" fontWeight={500}>
                Card ID:
              </Typography>
              <Typography variant="body2">{cardId}</Typography>
            </Alert>
          </>
        )}
      </FormControl>
    </Box>
  );
};

export default CreateCardSection;
