import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Button, CircularProgress, Alert, Divider } from "@mui/material";
import { generateAppleWalletPass } from "../../service/action";
import { useUserContext } from "../Login/userContext";
import FileSaver from "file-saver";
import AppleIcon from "@mui/icons-material/Apple";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import LockIcon from "@mui/icons-material/Lock";
import StarIcon from "@mui/icons-material/Star";
import AppleWalletButton from "../../components/AppleWalletButton";
import * as action from "../../service/action";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: "0 4px 20px rgba(0,0,0,0.15)",
  p: 4,
};

const AppleWalletModal = ({ open, handleClose }) => {
  const { getUser } = useUserContext();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [userData, setUserData] = useState(null);
  const [isLoadingUserData, setIsLoadingUserData] = useState(true);
  const [passUrl, setPassUrl] = useState(null);

  // Fetch user data to check subscription status
  useEffect(() => {
    const fetchUserData = async () => {
      if (open) {
        setIsLoadingUserData(true);
        try {
          const token = await getUser()?.getIdToken();
          const response = await action.getMe(token);
          setUserData(response);
        } catch (error) {
          console.error("Error fetching user data:", error);
          setError("Failed to verify subscription status");
        } finally {
          setIsLoadingUserData(false);
        }
      }
    };

    fetchUserData();
  }, [open, getUser]);

  // Check if user has premium subscription
  const hasPremiumSubscription =
    userData?.subscription?.plan === "bappa-premium" &&
    userData?.subscription?.status === "active";

  const handleGeneratePass = async () => {
    setLoading(true);
    setError(null);
    setSuccess(false);

    try {
      const token = await getUser()?.getIdToken();
      console.log("Generating Apple Wallet pass...");
      const response = await generateAppleWalletPass(token);
      console.log("API response:", response);

      // Check if the response indicates an error
      if (!response || response.status !== 200 || !response.data) {
        const errorMessage =
          response?.message || "Failed to generate Apple Wallet pass";
        console.error("Error response:", errorMessage);
        throw new Error(errorMessage);
      }

      // Get the pass URL from the response
      const passUrl = response.data.url || response.data;
      console.log("Pass URL:", passUrl);

      // Store the URL for potential manual download
      setPassUrl(passUrl);

      // Open the pass URL in a new tab to avoid navigation issues
      window.open(passUrl, "_blank");

      setSuccess(true);

      // Close the modal after a delay
      setTimeout(() => {
        handleClose();
      }, 5000);
    } catch (error) {
      console.error("Error generating Apple Wallet pass:", error);
      setError(
        error.message ||
          "Failed to generate Apple Wallet pass. Please check if the API server is running."
      );
    } finally {
      setLoading(false);
    }
  };

  const handleUpgradeClick = () => {
    // Redirect to subscription page
    window.location.href = "/my-subscription";
  };

  // Show loading state while checking subscription
  if (isLoadingUserData) {
    return (
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="apple-wallet-modal-title"
      >
        <Box sx={style}>
          <Box sx={{ textAlign: "center", py: 4 }}>
            <CircularProgress size={40} />
            <Typography variant="body1" sx={{ mt: 2 }}>
              Checking subscription status...
            </Typography>
          </Box>
        </Box>
      </Modal>
    );
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="apple-wallet-modal-title"
    >
      <Box sx={style}>
        {success ? (
          <Box sx={{ textAlign: "center", mb: 3 }}>
            <CheckCircleOutlineIcon
              sx={{ fontSize: 60, color: "success.main" }}
            />
            <Typography
              variant="h5"
              component="h2"
              fontWeight="bold"
              mt={2}
              color="success.main"
            >
              Success!
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
              Your Apple Wallet pass has been generated.
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ mt: 1, mb: 3 }}
            >
              The pass should open automatically in Safari. Follow the on-screen
              instructions to add it to your Apple Wallet.
            </Typography>

            {passUrl && (
              <AppleWalletButton
                onClick={() => {
                  // Try to open the pass again
                  window.open(passUrl, "_blank");
                }}
              />
            )}
          </Box>
        ) : !hasPremiumSubscription ? (
          // Premium subscription required view
          <>
            <Box sx={{ textAlign: "center", mb: 3 }}>
              <LockIcon sx={{ fontSize: 60, color: "#dc004e" }} />
              <Typography variant="h5" component="h2" fontWeight="bold" mt={2}>
                Premium Feature
              </Typography>
            </Box>

            <Typography variant="body1" sx={{ mb: 3 }}>
              Apple Wallet integration is a premium feature available
              exclusively to Bappa Premium subscribers.
            </Typography>

            <Box sx={{ mb: 3 }}>
              <Typography variant="subtitle1" fontWeight="medium" gutterBottom>
                With Bappa Premium you get:
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
                <StarIcon sx={{ color: "#dc004e", mr: 1, fontSize: "small" }} />
                <Typography variant="body2">
                  Apple Wallet Integration
                </Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
                <StarIcon sx={{ color: "#dc004e", mr: 1, fontSize: "small" }} />
                <Typography variant="body2">Advanced Customization</Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
                <StarIcon sx={{ color: "#dc004e", mr: 1, fontSize: "small" }} />
                <Typography variant="body2">Custom Branding</Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
                <StarIcon sx={{ color: "#dc004e", mr: 1, fontSize: "small" }} />
                <Typography variant="body2">Detailed Analytics</Typography>
              </Box>
            </Box>

            <Divider sx={{ my: 2 }} />

            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}
            >
              <Button variant="outlined" onClick={handleClose}>
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={handleUpgradeClick}
                sx={{
                  bgcolor: "#dc004e",
                  "&:hover": {
                    bgcolor: "#e64a2e",
                  },
                }}
              >
                Upgrade to Premium
              </Button>
            </Box>
          </>
        ) : (
          // Premium user view - can generate pass
          <>
            <Box sx={{ textAlign: "center", mb: 3 }}>
              <AppleIcon sx={{ fontSize: 60, color: "#000" }} />
              <Typography
                id="apple-wallet-modal-title"
                variant="h5"
                component="h2"
                fontWeight="bold"
                mt={2}
              >
                Add to Apple Wallet
              </Typography>
            </Box>

            <Typography variant="body1" sx={{ mb: 3 }}>
              Generate a digital card for your Apple Wallet that contains your
              BappaCard profile information and QR code.
            </Typography>

            <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
              Once generated, open the .pkpass file on your iOS device to add it
              to your Apple Wallet.
            </Typography>

            {error && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {error}
              </Alert>
            )}

            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}
            >
              <Button
                variant="outlined"
                onClick={handleClose}
                disabled={loading}
              >
                Cancel
              </Button>
              {loading ? (
                <Button
                  variant="contained"
                  disabled
                  startIcon={<CircularProgress size={20} />}
                >
                  Generating...
                </Button>
              ) : (
                <AppleWalletButton
                  onClick={handleGeneratePass}
                  disabled={loading}
                />
              )}
            </Box>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default AppleWalletModal;
