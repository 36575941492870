import React from "react";
import appleWalletBadge from "../images/US-UK_Add_to_Apple_Wallet_RGB_101421.svg";

/**
 * AppleWalletButton component that displays the official Apple Wallet badge
 *
 * @param {Object} props - Component props
 * @param {Function} props.onClick - Click handler function
 * @param {boolean} [props.disabled=false] - Whether the button is disabled
 * @returns {JSX.Element} - Rendered component
 */
const AppleWalletButton = ({ onClick, disabled = false }) => {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      style={{
        background: "none",
        border: "none",
        padding: 0,
        cursor: disabled ? "default" : "pointer",
        opacity: disabled ? 0.5 : 1,
        transition: "opacity 0.2s",
        display: "inline-block",
      }}
      aria-label="Add to Apple Wallet"
    >
      <img
        src={appleWalletBadge}
        alt="Add to Apple Wallet"
        style={{
          height: "44px",
          width: "auto",
        }}
      />
    </button>
  );
};

export default AppleWalletButton;
