import React, { useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  Container,
  Chip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import AppHeader from "../Navigation/AppHeader";
import ContactUs from "../ContactUs";
import { motion } from "framer-motion"; // Import motion from framer-motion

// Custom styled components
const StyledCard = styled(Card)(({ theme, featured }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  position: "relative",
  transition: "all 0.3s ease",
  backgroundColor: featured
    ? "linear-gradient(135deg, #0f0f23 0%, #1a1a3a 100%)"
    : "white",
  color: featured ? "white" : "inherit",
  borderRadius: "24px",
  boxShadow: featured
    ? "0 15px 50px rgba(0,0,0,0.15)"
    : "0 15px 50px rgba(0,0,0,0.05)",
  border: featured ? "none" : "1px solid rgba(0,0,0,0.03)",
  overflow: "hidden",
  transform: featured ? "scale(1.05)" : "scale(1)",
  zIndex: featured ? 1 : 0,
}));

const SubscriptionsPage = () => {
  const [contactModalOpen, setContactModalOpen] = useState(false);

  // Custom action items for the pricing page
  const actionItems = [
    { label: "Login", href: "/log-in", variant: "text" },
    {
      label: "Get Started",
      href: "/get-started",
      variant: "contained",
      sx: {
        bgcolor: "#dc004e",
        textTransform: "none",
        fontSize: "1rem",
        fontWeight: 400,
        px: 3,
        py: 1,
        borderRadius: "8px",
        "&:hover": {
          bgcolor: "#0f0f23",
        },
      },
    },
  ];

  // Updated plans with information from Lander.js
  const plans = [
    {
      title: "For Individuals",
      subtitle: "Your Personal Brand, Perfected",
      price: "Free",
      period: "7-day free trial",
      description:
        "Stand out with a sleek, digital business card that makes sharing your details seamless and memorable. Leave a lasting impression and grow your network effortlessly.",
      buttonText: "Try for free",
      headerBg:
        "linear-gradient(135deg, rgba(0,0,0,0.01) 0%, rgba(0,0,0,0.02) 100%)",
      buttonBg: "linear-gradient(45deg, #dc004e 30%, #ff3378 90%)",
      buttonHoverBg: "linear-gradient(45deg, #c80046 30%, #e92e6c 90%)",
      iconBg: "rgba(220, 0, 78, 0.1)",
      iconColor: "#dc004e",
      features: [
        "Unlimited digital card shares",
        "Basic analytics",
        "Custom branding options",
        "Social media integration",
      ],
    },
    {
      title: "For Teams",
      subtitle: "Equip Your Team to Win",
      price: "$5",
      period: "per month",
      description:
        "Empower your team to represent your brand with consistency and impact. Track performance, streamline networking at events, and turn every interaction into an opportunity.",
      buttonText: "Contact Sales",
      featured: true,
      headerBg: "linear-gradient(135deg, #8B3DFF 0%, #A97FFF 100%)",
      buttonBg: "linear-gradient(45deg, #8B3DFF 30%, #A97FFF 90%)",
      buttonHoverBg: "linear-gradient(45deg, #7835D8 30%, #9E78F5 90%)",
      iconBg: "rgba(139, 61, 255, 0.1)",
      iconColor: "#8B3DFF",
      features: [
        "Team admin dashboard",
        "Advanced analytics",
        "Branded card templates",
        "Contact management system",
        "Custom integrations",
      ],
    },
    {
      title: "For Enterprises",
      subtitle: "Scalable Organization Solutions",
      price: "Contact Us",
      period: "for custom pricing",
      description:
        "Take control with a secure, customizable platform designed for large organizations. Manage teams, track ROI, and integrate seamlessly with your existing CRM to drive measurable results.",
      buttonText: "Contact Sales",
      headerBg: "linear-gradient(135deg, #0f0f23 0%, #1a1a3a 100%)",
      buttonBg: "white",
      buttonHoverBg: "rgba(255,255,255,0.9)",
      iconBg: "rgba(255, 255, 255, 0.1)",
      iconColor: "white",
      darkBg: true,
      features: [
        "Enterprise admin portal",
        "Advanced security features",
        "White-label options",
        "Priority support",
      ],
    },
  ];

  // Handle contact modal
  const handleOpenContactModal = () => {
    setContactModalOpen(true);
  };

  const handleCloseContactModal = () => {
    setContactModalOpen(false);
  };

  return (
    <>
      <ContactUs
        open={contactModalOpen}
        handleClose={handleCloseContactModal}
      />

      <AppHeader
        actionItems={actionItems}
        backgroundColor="#0f0f23"
        maxWidth="lg"
      />

      <Box
        sx={{
          bgcolor: "#f5f5f5",
          pt: { xs: "80px", md: "100px" },
          pb: 16,
          position: "relative",
          overflow: "hidden",
          background: "linear-gradient(135deg, #f6f6f8 0%, #f0f0f5 100%)",
        }}
      >
        {/* Decorative background elements */}
        <Box
          sx={{
            position: "absolute",
            top: "20%",
            right: "-10%",
            width: "400px",
            height: "400px",
            borderRadius: "50%",
            background:
              "radial-gradient(circle, rgba(15, 15, 35, 0.04) 0%, rgba(15, 15, 35, 0) 70%)",
            filter: "blur(50px)",
            zIndex: 0,
          }}
        />
        <Box
          sx={{
            position: "absolute",
            bottom: "-5%",
            left: "-5%",
            width: "300px",
            height: "300px",
            borderRadius: "50%",
            background:
              "radial-gradient(circle, rgba(220, 0, 78, 0.05) 0%, rgba(220, 0, 78, 0) 70%)",
            filter: "blur(60px)",
            zIndex: 0,
          }}
        />

        <Container maxWidth="lg" sx={{ position: "relative", zIndex: 1 }}>
          <Box sx={{ textAlign: "center", mb: 10 }}>
            <Typography
              variant="h6"
              sx={{
                color: "#dc004e",
                fontWeight: "600",
                textTransform: "uppercase",
                letterSpacing: "1.5px",
                mb: 2,
              }}
            >
              Pricing
            </Typography>
            <Typography
              variant="h2"
              sx={{
                fontSize: { xs: "2.5rem", md: "3.5rem" },
                fontWeight: "800",
                mb: 3,
                background: "linear-gradient(90deg, #0f0f23 30%, #333 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              Plans for Professionals at Every Scale
            </Typography>
            <Typography
              variant="h6"
              sx={{
                color: "#666",
                maxWidth: "800px",
                mx: "auto",
                mb: 2,
                fontSize: "1.1rem",
                lineHeight: 1.6,
              }}
            >
              Choose the perfect plan that fits your needs - whether you're an
              individual professional, growing team, or enterprise organization.
            </Typography>
          </Box>

          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: { xs: "1fr", md: "repeat(3, 1fr)" },
              gap: { xs: 4, md: 6 },
              alignItems: "stretch",
            }}
          >
            {plans.map((plan, index) => (
              <motion.div
                key={plan.title}
                whileHover={{ y: -12 }}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{
                  duration: 0.3,
                  delay: index * 0.1,
                  hover: { duration: 0.3 },
                }}
              >
                <Box
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    borderRadius: "24px",
                    background: plan.darkBg
                      ? "linear-gradient(135deg, #0f0f23 0%, #1a1a3a 100%)"
                      : "white",
                    overflow: "hidden",
                    boxShadow: plan.darkBg
                      ? "0 15px 50px rgba(0,0,0,0.15)"
                      : "0 15px 50px rgba(0,0,0,0.05)",
                    transition: "all 0.3s ease",
                    border: plan.darkBg ? "none" : "1px solid rgba(0,0,0,0.03)",
                    transform: plan.featured
                      ? { xs: "scale(1)", md: "scale(1.05)" }
                      : "scale(1)",
                    zIndex: plan.featured ? 1 : 0,
                    "&:hover": {
                      boxShadow: plan.darkBg
                        ? "0 25px 60px rgba(0,0,0,0.2)"
                        : "0 25px 60px rgba(0,0,0,0.1)",
                    },
                  }}
                >
                  <Box
                    sx={{
                      p: 4,
                      borderBottom: plan.darkBg
                        ? "1px solid rgba(255,255,255,0.1)"
                        : "1px solid rgba(0,0,0,0.05)",
                      background: plan.headerBg,
                      color: plan.featured || plan.darkBg ? "white" : "inherit",
                    }}
                  >
                    {plan.featured && (
                      <Box
                        sx={{
                          display: "inline-block",
                          px: 2,
                          py: 0.5,
                          bgcolor: "rgba(255,255,255,0.2)",
                          borderRadius: "20px",
                          fontSize: "0.8rem",
                          fontWeight: "bold",
                          mb: 2,
                        }}
                      >
                        Most Popular
                      </Box>
                    )}
                    <Typography
                      variant="h3"
                      sx={{
                        fontSize: "2rem",
                        fontWeight: "700",
                        color:
                          plan.featured || plan.darkBg ? "white" : "#0f0f23",
                        mb: 1,
                      }}
                    >
                      {plan.title}
                    </Typography>
                    <Typography
                      sx={{
                        color: plan.featured ? "white" : "#dc004e",
                        fontWeight: "600",
                        mb: 1,
                        fontSize: "1.1rem",
                      }}
                    >
                      {plan.subtitle}
                    </Typography>
                  </Box>

                  <Box sx={{ p: 4, flexGrow: 1 }}>
                    <Typography
                      sx={{
                        fontSize: "1rem",
                        color: plan.darkBg ? "rgba(255,255,255,0.8)" : "#666",
                        mb: 4,
                        lineHeight: 1.6,
                      }}
                    >
                      {plan.description}
                    </Typography>

                    <Box sx={{ mb: 4 }}>
                      {plan.features.map((feature) => (
                        <Box
                          key={feature}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 2,
                            mb: 2,
                          }}
                        >
                          <Box
                            sx={{
                              width: "20px",
                              height: "20px",
                              borderRadius: "50%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              backgroundColor: plan.iconBg,
                              color: plan.iconColor,
                              fontSize: "0.8rem",
                              fontWeight: "bold",
                              flexShrink: 0,
                            }}
                          >
                            ✓
                          </Box>
                          <Typography
                            sx={{
                              color: plan.darkBg
                                ? "rgba(255,255,255,0.8)"
                                : "#444",
                              fontSize: "0.95rem",
                            }}
                          >
                            {feature}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  </Box>

                  <Box sx={{ p: 4, pt: 0, mt: "auto" }}>
                    <Box sx={{ textAlign: "center", mb: 4 }}>
                      <Typography
                        variant="h3"
                        sx={{
                          fontSize: "2.5rem",
                          fontWeight: "bold",
                          color: plan.darkBg ? "white" : "#0f0f23",
                        }}
                      >
                        {plan.price}
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          color: plan.darkBg ? "rgba(255,255,255,0.6)" : "#666",
                        }}
                      >
                        {plan.period}
                      </Typography>
                    </Box>

                    <Button
                      variant="contained"
                      fullWidth
                      sx={{
                        background: plan.buttonBg,
                        color:
                          plan.darkBg || plan.featured ? "#0f0f23" : "white",
                        boxShadow: `0 6px 20px ${
                          plan.darkBg
                            ? "rgba(255,255,255,0.15)"
                            : plan.featured
                            ? "rgba(139, 61, 255, 0.25)"
                            : "rgba(220, 0, 78, 0.25)"
                        }`,
                        py: 1.5,
                        textTransform: "none",
                        fontSize: "1.1rem",
                        fontWeight: "600",
                        borderRadius: "12px",
                        "&:hover": {
                          background: plan.buttonHoverBg,
                          boxShadow: `0 8px 25px ${
                            plan.darkBg
                              ? "rgba(255,255,255,0.2)"
                              : plan.featured
                              ? "rgba(139, 61, 255, 0.3)"
                              : "rgba(220, 0, 78, 0.3)"
                          }`,
                          transform: "translateY(-3px)",
                        },
                        transition: "all 0.3s ease",
                      }}
                      onClick={
                        plan.title === "For Individuals"
                          ? () => {
                              window.location.href = "/log-in";
                            }
                          : handleOpenContactModal
                      }
                    >
                      {plan.buttonText}
                    </Button>

                    {plan.title === "For Individuals" && (
                      <Typography
                        sx={{
                          fontSize: "0.85rem",
                          color: "#666",
                          textAlign: "center",
                          mt: 2,
                        }}
                      >
                        7-day free trial of Bappa Premium
                      </Typography>
                    )}

                    {plan.title === "For Teams" && (
                      <Typography
                        sx={{
                          fontSize: "0.85rem",
                          color: "#666",
                          textAlign: "center",
                          mt: 2,
                        }}
                      >
                        Custom pricing based on team size
                      </Typography>
                    )}

                    {plan.title === "For Enterprises" && (
                      <Typography
                        sx={{
                          fontSize: "0.85rem",
                          color: "rgba(255,255,255,0.6)",
                          textAlign: "center",
                          mt: 2,
                        }}
                      >
                        Custom enterprise-grade solutions
                      </Typography>
                    )}
                  </Box>
                </Box>
              </motion.div>
            ))}
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default SubscriptionsPage;
