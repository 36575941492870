import React, { useState, useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import "./LoadingScreen.css";

const imageMap = {
  default: require("../../images/bappa-logo-black.png"),
  monroe: require("../../images/monroe.png"),
  test: require("../../images/test-image.png"),
};

const LoadingScreen = () => {
  const [loadingImage, setLoadingImage] = useState(imageMap.default);

  useEffect(() => {
    // Get the current URL search parameters
    const urlSearchParams = new URLSearchParams(window.location.search);

    // Get a specific query parameter by name
    const paramValue = urlSearchParams.get("group");

    if (paramValue) {
      setLoadingImage(imageMap[paramValue]);
    }
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        bgcolor: "#f5f5f5",
        width: "100%",
      }}
      className="loading-screen"
    >
      <Box
        sx={{
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "40px",
          borderRadius: "12px",
          backgroundColor: "white",
          boxShadow: "0 4px 20px rgba(0,0,0,0.08)",
        }}
      >
        <img
          width="80px"
          src={loadingImage}
          alt="Custom Logo"
          className="logo"
          style={{ marginBottom: "30px" }}
        />
        <CircularProgress size={50} sx={{ color: "#dc004e", mb: 3 }} />
        <Typography
          variant="h6"
          sx={{
            fontWeight: "500",
            color: "#555",
            mt: 2,
            letterSpacing: "0.5px",
          }}
        >
          Loading<span className="dot">.</span>
          <span className="dot">.</span>
          <span className="dot">.</span>
        </Typography>
      </Box>
    </Box>
  );
};

export default LoadingScreen;
