import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import { createGlobalStyle } from "styled-components";
import { Box, CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import Sidebar from "./components/Sidebar";
import Products from "./pages/Products/Products";
import ProfilePage from "./pages/Account/Profile";
import Login from "./pages/Login/Login";
import Signup from "./pages/Signup/Signup";
import ScrollToTop from "./components/ScrollToTop";
import { AuthProvider } from "./components/Auth";
import PrivateRoute from "./components/PrivateRoute";
import AdminRoute from "./components/AdminRoute";
import UserProfile from "./pages/Account/UserProfile";
import GroupProfile from "./pages/Account/GroupProfile";
import Contacts from "./pages/Dashboard/Contacts";
import AdminDash from "./pages/Account/AdminDash";
import ResetPassword from "./pages/Login/ResetPassword";
import Analytics from "./pages/Account/Analytics";
import Lander from "./pages/Home/Lander";
import CheckoutForm from "./pages/Home/Checkout";
import LegalPage from "./pages/Home/LegalPage";
import SubscriptionsPage from "./components/Pricing/SubscriptionsPage";
import MySubscription from "./pages/Account/MySubscription";
import Footer from "./components/Footer/Footer";
import GroupAdminRoute from "./components/GroupAdminRoute";

import "bootstrap/scss/bootstrap.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/scss/paper-kit.scss";
import "./assets/demo/demo.css?v=1.3.0";

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    background-color: ##0f0f23;
    overflow-x: hidden;
  }

  * {
    box-sizing: border-box;
  }
`;

const theme = createTheme({
  palette: {
    primary: {
      main: "#0f0f23",
    },
    secondary: {
      main: "#dc004e",
    },
    background: {
      default: "#f5f5f5",
    },
  },
});

function AppContent() {
  const location = useLocation();

  // Determine if sidebar is shown to adjust layout
  const showSidebar = ![
    "/log-in",
    "/sign-up",
    "/reset-password",
    "/lander",
    "/products",
    "/privacy-notice",
    "/pricing",
    "/",
  ].includes(location.pathname);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        position: "relative",
      }}
    >
      {showSidebar && <Sidebar />}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          // width: "100%",
          maxWidth: "100vw",
          overflowX: "hidden",
          p: { xs: 1, sm: 2 },
          mt: { xs: "56px", sm: "64px" },
          // Increase bottom padding to create more space between content and footer
          pb: { xs: "300px", sm: "250px", md: "220px" },
          // Adjust margin-left if sidebar is shown
          ml: showSidebar ? { xs: 0, md: "240px" } : 0,
          // Adjust width if sidebar is shown
          width: showSidebar
            ? { xs: "100%", md: "calc(100% - 240px)" }
            : "100%",
        }}
      >
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Lander />} />

          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <UserProfile />
              </PrivateRoute>
            }
          />
          <Route
            path="/group/:groupname"
            element={
              <GroupAdminRoute>
                <GroupProfile />
              </GroupAdminRoute>
            }
          />
          <Route
            path="/admin"
            element={
              <AdminRoute>
                <AdminDash />
              </AdminRoute>
            }
          />
          <Route
            path="/contacts"
            element={
              <PrivateRoute>
                <Contacts />
              </PrivateRoute>
            }
          />

          <Route
            path="/my-subscription"
            element={
              <PrivateRoute>
                <MySubscription />
              </PrivateRoute>
            }
          />
          <Route path="/analytics" element={<Analytics />} />
          <Route path="/products" element={<Products />} />
          <Route path="/log-in" element={<Login />} />
          <Route path="/sign-up" element={<Signup />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/profile/:id" element={<ProfilePage />} />
          <Route path="*" element={<Lander />} />
          <Route path="/lander" element={<Lander />} />
          <Route path="/checkout" element={<CheckoutForm />} />
          <Route path="/privacy-notice" element={<LegalPage />} />
          <Route path="/pricing" element={<SubscriptionsPage />} />
        </Routes>
      </Box>
      <Box
        sx={{
          position: "absolute",
          bottom: 0,
          width: "100%",
        }}
      >
        <Footer />
      </Box>
    </Box>
  );
}

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <Router>
          <GlobalStyle />
          <CssBaseline />
          <AppContent />
        </Router>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
