import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { styled, keyframes } from "@mui/material/styles";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  useMediaQuery,
  useTheme,
  AppBar,
  Toolbar,
  Typography,
  Box,
  Chip,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import PersonIcon from "@mui/icons-material/Person";
import ContactsIcon from "@mui/icons-material/Contacts";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import GroupIcon from "@mui/icons-material/Group";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import LoginIcon from "@mui/icons-material/Login";
import StarIcon from "@mui/icons-material/Star";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import bappaLogo from "../images/bappa-logo.png";
import monroeLogo from "../images/monroe-white.png";
import { useUserContext } from "../pages/Login/userContext";
import { ADMIN_EMAILS } from "./AdminRoute";
import * as action from "../service/action";

const drawerWidth = 240;

const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
`;

const fadeIn = keyframes`
  0% {
    opacity: 0.4;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.4;
  }
`;

const NewBadge = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.error.main,
  color: theme.palette.error.contrastText,
  animation: `${fadeIn} 2s infinite ease-in-out`,
  fontWeight: "bold",
  fontSize: "0.7rem",
  height: 20,
  marginLeft: 8,
}));

const AnimatedIcon = styled("div")(({ theme }) => ({
  animation: `${pulse} 2s infinite ease-in-out`,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const AnimatedText = styled("div")(({ theme }) => ({
  animation: `${pulse} 2s infinite ease-in-out`,
  display: "flex",
  alignItems: "center",
  width: "100%",
}));

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  "& .MuiDrawer-paper": {
    width: drawerWidth,
    boxSizing: "border-box",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}));

const navBarMap = {
  default: {
    image: bappaLogo,
    text: "Bappa Cards",
    path: "https://bappacards.com/",
  },
  monroe: {
    image: monroeLogo,
    text: "Monroe College",
    path: "https://www.monroecollege.edu/",
  },
};

const Sidebar = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [navbarData, setNavbarData] = useState(navBarMap.default);
  const [userData, setUserData] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const location = useLocation();
  const navigate = useNavigate();
  const { user, logoutUser } = useUserContext();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLogout = () => {
    logoutUser();
    navigate("/");
  };

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const paramValue = urlSearchParams.get("group");
    if (paramValue) {
      setNavbarData(navBarMap[paramValue]);
    }
  }, []);

  // Fetch user data including group information
  useEffect(() => {
    const fetchUserData = async () => {
      if (user) {
        try {
          const token = await user.getIdToken?.();
          if (token) {
            const userData = await action.getMe(token);
            setUserData(userData);
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
    };

    fetchUserData();
  }, [user]);

  const menuItems = [
    // { text: "Home", icon: <HomeIcon />, path: "/" },
    // { text: "Products", icon: <ShoppingCartIcon />, path: "/products" },
    ...(user
      ? [
          { text: "Manage Profile", icon: <PersonIcon />, path: "/dashboard" },
          { text: "Contacts", icon: <ContactsIcon />, path: "/contacts" },
          {
            text: "Analytics",
            icon: (
              <AnimatedIcon>
                <AnalyticsIcon sx={{ color: "#4caf50" }} />
              </AnimatedIcon>
            ),
            path: "/analytics",
            isNew: true,
          },
          {
            text:
              userData?.subscription?.status === "active" ? (
                <Typography>My Subscription</Typography>
              ) : (
                <AnimatedText>
                  <Typography sx={{ fontWeight: "bold", color: "#e53935" }}>
                    Upgrade Now
                  </Typography>
                </AnimatedText>
              ),
            icon:
              userData?.subscription?.status === "active" ? (
                <StarIcon />
              ) : (
                <AnimatedIcon>
                  <StarIcon sx={{ color: "#e53935" }} />
                </AnimatedIcon>
              ),
            path: "/my-subscription",
          },
          // Add the "Manage <Group Name>" tab only if user is a group admin
          ...(userData?.group_info?.admin === true
            ? [
                {
                  text: `Manage ${
                    userData.group_info.name.charAt(0).toUpperCase() +
                    userData.group_info.name.slice(1)
                  }`,
                  icon: <GroupIcon />,
                  path: `/group/${userData.group_info.name}`,
                },
              ]
            : []),
          ...(ADMIN_EMAILS.includes(user.email)
            ? [
                {
                  text: "Admin",
                  icon: <AdminPanelSettingsIcon />,
                  path: "/admin",
                },
              ]
            : []),
          { text: "Log Out", icon: <ExitToAppIcon />, onClick: handleLogout },
        ]
      : [{ text: "Log In", icon: <LoginIcon />, path: "/log-in" }]),
  ];

  const LogoWithTitle = () => (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
      }}
      onClick={() => {
        window.location.href = navbarData?.path;
      }}
    >
      <img
        src={navbarData?.image}
        alt={navbarData?.text}
        style={{ width: "30px", marginRight: "10px" }}
      />
      <Typography variant="h6" noWrap component="div">
        {navbarData?.text}
      </Typography>
    </Box>
  );

  const drawer = (
    <div>
      {!isMobile && (
        <Box sx={{ p: 2 }}>
          <LogoWithTitle />
        </Box>
      )}
      <List>
        {menuItems.map((item) => (
          <ListItem
            button
            key={item.text}
            component={item.onClick ? "div" : Link}
            to={item.path}
            onClick={() => {
              if (item.onClick) {
                item.onClick();
              }
              if (isMobile) {
                handleDrawerToggle();
              }
            }}
            selected={location.pathname === item.path}
            sx={{
              ...(location.pathname === item.path
                ? {
                    backgroundColor: "rgba(255, 255, 255, 0.2)",
                    borderLeft: "4px solid",
                    borderColor: "secondary.main",
                    "&:hover": {
                      backgroundColor: "rgba(255, 255, 255, 0.25)",
                    },
                  }
                : {}),
              ...(item.isNew
                ? {
                    background: (theme) =>
                      `linear-gradient(90deg, transparent, ${theme.palette.success.light}22, transparent)`,
                    position: "relative",
                  }
                : {}),
            }}
          >
            <ListItemIcon sx={{ color: "inherit" }}>{item.icon}</ListItemIcon>
            <ListItemText
              primary={
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  {item.text}
                  {item.isNew && <NewBadge label="NEW" size="small" />}
                </Box>
              }
            />
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          {isMobile && <LogoWithTitle />}
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      >
        <StyledDrawer
          variant={isMobile ? "temporary" : "permanent"}
          open={isMobile ? mobileOpen : true}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawer}
        </StyledDrawer>
      </Box>
    </>
  );
};

export default Sidebar;
