import React, { useState, useEffect, useRef, useCallback } from "react";
import CreateCardSection from "../../components/CreateCardSection";
import {
  Box,
  Grid,
  Paper,
  Typography,
  TextField,
  List,
  ListItem,
  ListItemText,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Tabs,
  Tab,
  Container,
  Chip,
  Avatar,
  IconButton,
  Tooltip,
  useTheme,
  useMediaQuery,
  InputAdornment,
  ListItemAvatar,
  Switch,
  FormControlLabel,
  Stack,
} from "@mui/material";
import { styled } from "@mui/system";
import SearchIcon from "@mui/icons-material/Search";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PersonIcon from "@mui/icons-material/Person";
import TodayIcon from "@mui/icons-material/Today";
import DateRangeIcon from "@mui/icons-material/DateRange";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import HistoryIcon from "@mui/icons-material/History";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import * as action from "../../service/action";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import { useUserContext } from "../Login/userContext";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import "leaflet.markercluster";
import GroupsList from "../../components/GroupsList";

// Fix for default marker icon
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

// Create a function to generate colored marker icons
const createColoredIcon = (color) => {
  // Create a custom icon with the specified color using SVG for a location pin
  return new L.DivIcon({
    className: "custom-map-marker",
    html: `
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 36" width="24" height="36" style="filter: drop-shadow(0 2px 2px rgba(0,0,0,0.5));">
        <path fill="${color}" d="M12 0C5.4 0 0 5.4 0 12c0 7.2 12 24 12 24s12-16.8 12-24c0-6.6-5.4-12-12-12zm0 18c-3.3 0-6-2.7-6-6s2.7-6 6-6 6 2.7 6 6-2.7 6-6 6z"/>
        <circle fill="white" cx="12" cy="12" r="4"/>
      </svg>
    `,
    iconSize: [24, 36],
    iconAnchor: [12, 36],
    popupAnchor: [0, -34],
  });
};

const DashboardContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: theme.palette.background.default,
  minHeight: "100vh",
}));

const StyledCard = styled(Card)(({ theme }) => ({
  height: "100%",
  boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
  borderRadius: theme.shape.borderRadius,
  transition: "transform 0.3s ease, box-shadow 0.3s ease",
  "&:hover": {
    transform: "translateY(-5px)",
    boxShadow: "0 8px 25px rgba(0, 0, 0, 0.15)",
  },
}));

const StatValue = styled(Typography)(({ theme }) => ({
  fontSize: "2rem",
  fontWeight: 600,
  color: theme.palette.primary.main,
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.5rem",
  },
}));

const StatLabel = styled(Typography)(({ theme }) => ({
  fontSize: "0.875rem",
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}));

const CityChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0.5),
  backgroundColor: theme.palette.background.paper,
  boxShadow: "0 2px 5px rgba(0, 0, 0, 0.08)",
  "& .MuiChip-label": {
    fontWeight: 500,
  },
}));

const StyledMapContainer = styled(Box)(({ theme }) => ({
  height: "400px",
  width: "100%",
  marginBottom: theme.spacing(3),
  borderRadius: theme.shape.borderRadius,
  overflow: "hidden",
  boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
}));

const Dashboard = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { getUser } = useUserContext();
  const [userData, setUserData] = useState(undefined);
  const [profilesData, setProfilesData] = useState([]);
  const [timeRange, setTimeRange] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [tabValue, setTabValue] = useState(0);
  const mapRef = useRef(null);
  const [cityColorData, setCityColorData] = useState([]);
  const [thresholds, setThresholds] = useState({
    high: 0,
    medium: 0,
    highest: 0,
  });
  const [showPremiumOnly, setShowPremiumOnly] = useState(false);

  const fetchData = useCallback(async () => {
    const token = (await getUser()?.getIdToken()) || "token";
    const [userDataResponse, profilesData] = await Promise.all([
      action.getMe(token),
      action.getProfiles(token),
    ]);
    setUserData(userDataResponse);
    setProfilesData(profilesData.data);
  }, [getUser]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Generate color data for cities based on tap counts
  useEffect(() => {
    if (profilesData.length === 0) return;

    // Get city data
    const cityData = getCityData(timeRange);

    // Sort cities by count to find high, medium and low tap counts
    const sortedCities = [...cityData].sort((a, b) => b[1].count - a[1].count);

    if (sortedCities.length === 0) {
      setCityColorData([]);
      return;
    }

    // Find the highest tap count
    const highestTapCount = sortedCities[0][1].count;

    // Calculate thresholds dynamically based on highest tap count
    // High: >= 70% of highest, Medium: >= 15% of highest (more generous), Low: < 15% of highest
    const highThreshold = highestTapCount * 0.7;
    const mediumThreshold = highestTapCount * 0.15;

    // Save thresholds for display
    setThresholds({
      high: Math.round(highThreshold),
      medium: Math.round(mediumThreshold),
      highest: highestTapCount,
    });

    // Define colors for each category
    const highColor = theme.palette.error.main; // Red for high
    const mediumColor = theme.palette.warning.main; // Yellow/amber for medium
    const lowColor = "#2b83cb"; // Default Leaflet blue for low

    // Create city color data with dynamic thresholds
    const colorData = sortedCities.map(([city, data]) => {
      let color;

      if (data.count >= highThreshold) {
        color = highColor; // High tap count (red)
      } else if (data.count >= mediumThreshold) {
        color = mediumColor; // Medium tap count (yellow)
      } else {
        color = lowColor; // Low tap count (blue)
      }

      return {
        city,
        taps: data.count,
        color,
        category:
          data.count >= highThreshold
            ? "high"
            : data.count >= mediumThreshold
            ? "medium"
            : "low",
      };
    });

    setCityColorData(colorData);
  }, [profilesData, timeRange, theme]);

  if (!userData || profilesData.length === 0) {
    return <LoadingScreen />;
  }

  const citiesArray = Object.entries(profilesData.scan_data.scans_by_city).sort(
    (a, b) => b[1].count - a[1].count
  );

  const citiesArrayToday = Object.entries(
    profilesData.scan_data.scans_by_city_today
  ).sort((a, b) => b[1].count - a[1].count);

  const citiesArrayThisWeek = Object.entries(
    profilesData.scan_data.scans_by_city_this_week
  ).sort((a, b) => b[1].count - a[1].count);

  const citiesArrayThisMonth = Object.entries(
    profilesData.scan_data.scans_by_city_this_month || {}
  ).sort((a, b) => b[1].count - a[1].count);

  const getCityData = (timeRange) => {
    if (timeRange === 0) {
      return citiesArray;
    } else if (timeRange === 25) {
      return citiesArrayThisMonth;
    } else if (timeRange === 50) {
      return citiesArrayThisWeek;
    } else {
      return citiesArrayToday;
    }
  };

  const getTimeRangeTitle = (timeRange) => {
    switch (timeRange) {
      case 0:
        return "All Time";
      case 25:
        return "This Month";
      case 50:
        return "This Week";
      case 100:
        return "Today";
      default:
        return "All Time";
    }
  };

  const getTotalTaps = (timeRange) => {
    if (timeRange === 0) {
      return profilesData.scan_data.total_scans;
    } else if (timeRange === 25) {
      return profilesData.scan_data.total_scans_this_month || 0;
    } else if (timeRange === 50) {
      return profilesData.scan_data.total_scans_this_week;
    } else {
      return profilesData.scan_data.total_scans_today;
    }
  };

  const getSorting = (a, b) => {
    if (timeRange === 0) {
      // For All Time tab, sort by name if both have 0 taps, otherwise sort by taps
      if (a.amount_scanned === 0 && b.amount_scanned === 0) {
        return (a.firstname || "").localeCompare(b.firstname || "");
      }
      // If only one has 0 taps, prioritize the one with taps
      if (a.amount_scanned === 0) return 1;
      if (b.amount_scanned === 0) return -1;
      // Otherwise sort by tap count
      return a.amount_scanned > b.amount_scanned ? -1 : 1;
    } else if (timeRange === 25) {
      return (a.amount_scanned_this_month || 0) >
        (b.amount_scanned_this_month || 0)
        ? -1
        : 1;
    } else if (timeRange === 50) {
      return a.amount_scanned_this_week > b.amount_scanned_this_week ? -1 : 1;
    }
    return a.amount_scanned_today > b.amount_scanned_today ? -1 : 1;
  };

  const getAmountScanned = (profile, timeRange) => {
    if (timeRange === 0) {
      return profile.amount_scanned;
    } else if (timeRange === 25) {
      return profile.amount_scanned_this_month || 0;
    } else if (timeRange === 50) {
      return profile.amount_scanned_this_week;
    }
    return profile.amount_scanned_today;
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const getTimeRangeIcon = (timeRange) => {
    switch (timeRange) {
      case 0:
        return <HistoryIcon />;
      case 25:
        return <CalendarMonthIcon />;
      case 50:
        return <DateRangeIcon />;
      case 100:
        return <TodayIcon />;
      default:
        return <HistoryIcon />;
    }
  };

  return (
    <DashboardContainer maxWidth="xl">
      <Box mb={4}>
        <Typography
          variant="h4"
          gutterBottom
          sx={{
            fontWeight: 700,
            color: theme.palette.text.primary,
            textAlign: "center",
            mb: 3,
          }}
        >
          Admin Dashboard
        </Typography>

        <Paper
          elevation={0}
          sx={{
            p: 1,
            display: "flex",
            justifyContent: "center",
            backgroundColor: theme.palette.background.paper,
            borderRadius: 2,
            mb: 4,
          }}
        >
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            variant={isMobile ? "scrollable" : "fullWidth"}
            scrollButtons={isMobile ? "auto" : false}
            indicatorColor="primary"
            textColor="primary"
            sx={{
              "& .MuiTab-root": {
                minHeight: "48px",
                fontWeight: 500,
              },
            }}
          >
            <Tab
              icon={<HistoryIcon />}
              label={isMobile ? "" : "All Time"}
              onClick={() => setTimeRange(0)}
              iconPosition="start"
            />
            <Tab
              icon={<CalendarMonthIcon />}
              label={isMobile ? "" : "This Month"}
              onClick={() => setTimeRange(25)}
              iconPosition="start"
            />
            <Tab
              icon={<DateRangeIcon />}
              label={isMobile ? "" : "This Week"}
              onClick={() => setTimeRange(50)}
              iconPosition="start"
            />
            <Tab
              icon={<TodayIcon />}
              label={isMobile ? "" : "Today"}
              onClick={() => setTimeRange(100)}
              iconPosition="start"
            />
          </Tabs>
        </Paper>
      </Box>

      <Grid container spacing={3} mb={4}>
        <Grid item xs={12} sm={6} md={4}>
          <StyledCard>
            <CardContent sx={{ textAlign: "center", py: 3 }}>
              <Avatar
                sx={{
                  bgcolor: theme.palette.primary.main,
                  width: 56,
                  height: 56,
                  margin: "0 auto",
                  mb: 2,
                }}
              >
                <PersonIcon fontSize="large" />
              </Avatar>
              <StatLabel>Total Profiles</StatLabel>
              <StatValue>{profilesData.profiles.length}</StatValue>
            </CardContent>
          </StyledCard>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <StyledCard>
            <CardContent sx={{ textAlign: "center", py: 3 }}>
              <Avatar
                sx={{
                  bgcolor: theme.palette.secondary.main,
                  width: 56,
                  height: 56,
                  margin: "0 auto",
                  mb: 2,
                }}
              >
                {getTimeRangeIcon(timeRange)}
              </Avatar>
              <StatLabel>Total Taps ({getTimeRangeTitle(timeRange)})</StatLabel>
              <StatValue>{getTotalTaps(timeRange)}</StatValue>
            </CardContent>
          </StyledCard>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <StyledCard>
            <CardContent sx={{ textAlign: "center", py: 3 }}>
              <Avatar
                sx={{
                  bgcolor: theme.palette.success.main,
                  width: 56,
                  height: 56,
                  margin: "0 auto",
                  mb: 2,
                }}
              >
                <LocationOnIcon fontSize="large" />
              </Avatar>
              <StatLabel>Cities with Activity</StatLabel>
              <StatValue>{getCityData(timeRange).length}</StatValue>
            </CardContent>
          </StyledCard>
        </Grid>
      </Grid>

      <StyledMapContainer sx={{ position: "relative" }}>
        {/* 
          The key prop forces the MapContainer to completely re-render when timeRange changes.
          This is necessary because the React Leaflet map doesn't automatically update its markers
          when the data props change, as it maintains its own internal state.
        */}
        <MapContainer
          key={`map-${timeRange}`}
          center={[40, -95]}
          zoom={4}
          style={{ height: "100%" }}
          ref={mapRef}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          {getCityData(timeRange).map(([city, data], index) => {
            const coordinates = data.coordinates
              ? {
                  lat: data.coordinates.latitude,
                  lon: data.coordinates.longitude,
                }
              : null;

            if (!coordinates) {
              return null;
            }

            // Find the matching city in cityColorData to get its color
            const cityData = cityColorData.find((item) => item.city === city);

            // Use the color from cityColorData if available, otherwise use a default color
            const markerColor = cityData ? cityData.color : "#2b83cb"; // Default Leaflet blue

            return (
              <Marker
                key={index}
                position={[coordinates.lat, coordinates.lon]}
                icon={createColoredIcon(markerColor)}
              >
                <Popup>
                  <Typography variant="subtitle1" fontWeight={600}>
                    {city}
                  </Typography>
                  <Typography variant="body2">{data.count} taps</Typography>
                </Popup>
              </Marker>
            );
          })}
        </MapContainer>

        {/* Map Legend - moved outside but still using timeRange-dependent key to force re-render */}
        {/* Adding a key to this box ensures it re-renders when timeRange changes, fixing the issue where the legend doesn't update */}
        <Box
          key={`legend-${timeRange}`}
          sx={{
            position: "absolute",
            bottom: 16,
            right: 16,
            backgroundColor: "rgba(255, 255, 255, 0.85)",
            borderRadius: 1,
            p: 1.5,
            boxShadow: "0 0 10px rgba(0,0,0,0.15)",
            zIndex: 1000,
            maxWidth: "200px",
          }}
        >
          <Typography variant="subtitle2" fontWeight={600} sx={{ mb: 1 }}>
            Tap Activity
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box
                sx={{
                  width: 16,
                  height: 16,
                  borderRadius: "50%",
                  backgroundColor: theme.palette.error.main,
                  mr: 1,
                  flexShrink: 0,
                }}
              />
              <Typography variant="caption">
                High (≥{thresholds.high} taps)
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box
                sx={{
                  width: 16,
                  height: 16,
                  borderRadius: "50%",
                  backgroundColor: theme.palette.warning.main,
                  mr: 1,
                  flexShrink: 0,
                }}
              />
              <Typography variant="caption">
                Medium (≥{thresholds.medium} taps)
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box
                sx={{
                  width: 16,
                  height: 16,
                  borderRadius: "50%",
                  backgroundColor: "#2b83cb",
                  mr: 1,
                  flexShrink: 0,
                }}
              />
              <Typography variant="caption">
                Low (&lt;{thresholds.medium} taps)
              </Typography>
            </Box>
            {thresholds.highest > 0 && (
              <Typography
                variant="caption"
                color="text.secondary"
                sx={{ mt: 0.5 }}
              >
                Highest: {thresholds.highest} taps
              </Typography>
            )}
            <Typography
              variant="caption"
              color="text.secondary"
              sx={{
                mt: 1,
                fontSize: "0.65rem",
                fontStyle: "italic",
                display: "block",
              }}
            >
              Thresholds based on 70%/15% of highest tap count
            </Typography>
          </Box>
        </Box>
      </StyledMapContainer>

      {/* Threshold Information */}
      {/* Adding a key to this box ensures it re-renders with updated threshold values when timeRange changes */}
      {thresholds.highest > 0 && (
        <Box
          key={`thresholds-${timeRange}`}
          sx={{
            mb: 3,
            p: 2,
            borderRadius: 1,
            backgroundColor: alpha(theme.palette.info.light, 0.1),
            border: `1px solid ${alpha(theme.palette.info.main, 0.3)}`,
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            gap: 2,
          }}
        >
          <Typography variant="body2" fontWeight={500}>
            Current Thresholds:
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Box
              sx={{
                width: 10,
                height: 10,
                borderRadius: "50%",
                backgroundColor: theme.palette.error.main,
              }}
            />
            <Typography variant="body2">
              High: ≥{thresholds.high} taps
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Box
              sx={{
                width: 10,
                height: 10,
                borderRadius: "50%",
                backgroundColor: theme.palette.warning.main,
              }}
            />
            <Typography variant="body2">
              Medium: ≥{thresholds.medium} taps
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Box
              sx={{
                width: 10,
                height: 10,
                borderRadius: "50%",
                backgroundColor: "#2b83cb",
              }}
            />
            <Typography variant="body2">
              Low: &lt;{thresholds.medium} taps
            </Typography>
          </Box>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ ml: "auto" }}
          >
            (Highest: {thresholds.highest} taps)
          </Typography>
        </Box>
      )}

      <Grid container spacing={3}>
        <Grid item xs={12} lg={8}>
          <StyledCard>
            <CardHeader
              title={
                <Typography variant="h6" fontWeight={600}>
                  {`Taps by City (${getTimeRangeTitle(timeRange)})`}
                </Typography>
              }
              avatar={<LocationOnIcon color="primary" />}
            />
            <Divider />
            <CardContent sx={{ p: 3 }}>
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                {getCityData(timeRange)
                  .slice(0, 20)
                  .map(([city, data], index) => {
                    // Find the matching city in cityColorData to get its color
                    const cityData = cityColorData.find(
                      (item) => item.city === city
                    );

                    // Use the color from cityColorData if available, otherwise use default styling
                    const chipColor = cityData ? cityData.color : "#2b83cb"; // Default Leaflet blue

                    // Determine variant based on category
                    const variant =
                      cityData && cityData.category === "high"
                        ? "filled"
                        : "outlined";

                    return (
                      <CityChip
                        key={index}
                        icon={<LocationOnIcon />}
                        label={`${city}: ${data.count}`}
                        variant={variant}
                        sx={{
                          borderColor: chipColor,
                          color: variant === "filled" ? "#fff" : chipColor,
                          backgroundColor:
                            variant === "filled" ? chipColor : "transparent",
                          "& .MuiChip-icon": {
                            color: variant === "filled" ? "#fff" : chipColor,
                          },
                        }}
                      />
                    );
                  })}
                {getCityData(timeRange).length > 20 && (
                  <CityChip
                    label={`+${getCityData(timeRange).length - 20} more`}
                    color="secondary"
                  />
                )}
              </Box>

              {getCityData(timeRange).length > 0 && (
                <Box mt={4}>
                  <Typography variant="subtitle1" fontWeight={600} gutterBottom>
                    Top Cities
                  </Typography>
                  <Grid container spacing={2}>
                    {getCityData(timeRange)
                      .slice(0, 6)
                      .map(([city, data], index) => {
                        // Find the matching city in cityColorData to get its color
                        const cityData = cityColorData.find(
                          (item) => item.city === city
                        );

                        // Use the color from cityColorData if available
                        const cityColor = cityData ? cityData.color : "#2b83cb"; // Default Leaflet blue

                        // Determine elevation and styles based on category
                        const isHighActivity =
                          cityData && cityData.category === "high";
                        const isMediumActivity =
                          cityData && cityData.category === "medium";

                        return (
                          <Grid item xs={12} sm={6} md={4} key={index}>
                            <Paper
                              elevation={isHighActivity ? 2 : 0}
                              sx={{
                                p: 2,
                                backgroundColor: alpha(
                                  cityColor,
                                  isHighActivity ? 0.15 : 0.07
                                ),
                                border: "1px solid",
                                borderColor: cityColor,
                                borderRadius: 2,
                                boxShadow: isHighActivity
                                  ? `0 4px 8px ${alpha(cityColor, 0.3)}`
                                  : "none",
                                transition:
                                  "transform 0.2s ease, box-shadow 0.2s ease",
                                "&:hover": {
                                  transform: "translateY(-3px)",
                                  boxShadow: `0 4px 12px ${alpha(
                                    cityColor,
                                    0.25
                                  )}`,
                                },
                              }}
                            >
                              <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                              >
                                <Box>
                                  <Typography
                                    variant="body2"
                                    color="textSecondary"
                                  >
                                    {`#${index + 1}`}
                                  </Typography>
                                  <Typography
                                    variant="subtitle1"
                                    fontWeight={600}
                                  >
                                    {city}
                                  </Typography>
                                  {isHighActivity && (
                                    <Chip
                                      size="small"
                                      label="High Activity"
                                      sx={{
                                        mt: 0.5,
                                        backgroundColor: cityColor,
                                        color: "#fff",
                                        fontSize: "0.7rem",
                                      }}
                                    />
                                  )}
                                  {isMediumActivity && (
                                    <Chip
                                      size="small"
                                      label="Medium Activity"
                                      variant="outlined"
                                      sx={{
                                        mt: 0.5,
                                        borderColor: cityColor,
                                        color: cityColor,
                                        fontSize: "0.7rem",
                                      }}
                                    />
                                  )}
                                </Box>
                                <Typography
                                  variant="h6"
                                  fontWeight={700}
                                  color="textPrimary"
                                  sx={{ color: cityColor }}
                                >
                                  {data.count}
                                </Typography>
                              </Box>
                            </Paper>
                          </Grid>
                        );
                      })}
                  </Grid>
                </Box>
              )}
            </CardContent>
          </StyledCard>
        </Grid>

        <Grid item xs={12} lg={4}>
          <StyledCard>
            <CardHeader
              title={
                <Typography variant="h6" fontWeight={600}>
                  {`User Activity (${getTimeRangeTitle(timeRange)})`}
                </Typography>
              }
              avatar={<PersonIcon color="primary" />}
              action={
                <Stack direction="column" spacing={1} alignItems="flex-end">
                  <TextField
                    placeholder="Search users..."
                    variant="outlined"
                    size="small"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon fontSize="small" />
                        </InputAdornment>
                      ),
                    }}
                    sx={{ width: { xs: "100%", sm: 200 } }}
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        size="small"
                        checked={showPremiumOnly}
                        onChange={(e) => setShowPremiumOnly(e.target.checked)}
                        color="error"
                      />
                    }
                    label={
                      <Typography variant="caption" fontWeight={500}>
                        Premium only
                      </Typography>
                    }
                    sx={{
                      mr: 0,
                      "& .MuiFormControlLabel-label": {
                        fontSize: "0.75rem",
                      },
                    }}
                  />
                </Stack>
              }
            />
            <Divider />
            <CardContent sx={{ p: 0, maxHeight: 500, overflow: "auto" }}>
              <List disablePadding>
                {profilesData.profiles
                  .filter((profile) => {
                    // Filter by premium status if showPremiumOnly is true
                    if (showPremiumOnly && !profile.subscription) {
                      return false;
                    }
                    // Filter by search query
                    return (
                      profile.firstname
                        ?.toLowerCase()
                        .includes(searchQuery.toLowerCase()) ||
                      profile.lastname
                        ?.toLowerCase()
                        .includes(searchQuery.toLowerCase()) ||
                      profile.email
                        ?.toLowerCase()
                        .includes(searchQuery.toLowerCase())
                    );
                  })
                  .sort((a, b) => {
                    return getSorting(a, b);
                  })
                  .slice(0, timeRange === 0 ? undefined : 50) // Only limit to 50 for non-All Time tabs
                  .map((profile) => {
                    const taps = getAmountScanned(profile, timeRange);
                    if (!profile.email || (timeRange !== 0 && taps === 0))
                      return null;

                    return (
                      <ListItem
                        key={profile.profile_id}
                        divider
                        secondaryAction={
                          <Tooltip title="Open profile">
                            <IconButton
                              edge="end"
                              size="small"
                              onClick={() =>
                                window
                                  .open(
                                    profile.group
                                      ? `${window.location.origin}/profile/${profile.profile_id}?group=${profile.group}`
                                      : `${window.location.origin}/profile/${profile.profile_id}`,
                                    "_blank"
                                  )
                                  .focus()
                              }
                            >
                              <OpenInNewIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        }
                      >
                        <ListItemAvatar>
                          <Avatar
                            src={profile.profile_photo}
                            alt={`${profile.firstname || ""} ${
                              profile.lastname || ""
                            }`}
                            sx={{
                              width: 40,
                              height: 40,
                              bgcolor: !profile.profile_photo
                                ? theme.palette.primary.main
                                : undefined,
                            }}
                          >
                            {!profile.profile_photo &&
                              (profile.firstname?.[0] ||
                                profile.lastname?.[0] ||
                                "U")}
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <Box display="flex" alignItems="center">
                              <Typography variant="body2" fontWeight={500}>
                                {`${profile.firstname || ""} ${
                                  profile.lastname || ""
                                }`}
                              </Typography>
                              {profile.subscription && (
                                <Chip
                                  size="small"
                                  label="PREMIUM"
                                  color="error"
                                  sx={{
                                    ml: 1,
                                    height: 20,
                                    fontSize: "0.6rem",
                                    fontWeight: 700,
                                  }}
                                />
                              )}
                            </Box>
                          }
                          secondary={
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <Typography
                                variant="caption"
                                color="textSecondary"
                                noWrap
                                sx={{ maxWidth: "150px" }}
                              >
                                {profile.email}
                              </Typography>
                              <Chip
                                size="small"
                                label={`${taps} taps`}
                                color={taps > 10 ? "primary" : "default"}
                                variant={taps > 10 ? "filled" : "outlined"}
                                sx={{ ml: 1 }}
                              />
                            </Box>
                          }
                        />
                      </ListItem>
                    );
                  })}
              </List>
              {showPremiumOnly &&
                profilesData.profiles.filter((profile) => profile.subscription)
                  .length === 0 && (
                  <Box p={3} textAlign="center">
                    <Typography variant="body2" color="text.secondary">
                      No premium users found.
                    </Typography>
                  </Box>
                )}
            </CardContent>
          </StyledCard>
        </Grid>
      </Grid>

      <Box mt={4}>
        <Typography
          variant="h5"
          gutterBottom
          sx={{
            fontWeight: 600,
            color: theme.palette.text.primary,
            mb: 2,
          }}
        >
          Manage Groups
        </Typography>
        <GroupsList />
      </Box>

      <Box mt={4}>
        <Typography
          variant="h5"
          gutterBottom
          sx={{
            fontWeight: 600,
            color: theme.palette.text.primary,
            mb: 2,
          }}
        >
          Create New Card
        </Typography>
        <StyledCard>
          <CardContent>
            <CreateCardSection />
          </CardContent>
        </StyledCard>
      </Box>
    </DashboardContainer>
  );
};

// Helper function to create alpha colors
function alpha(color, opacity) {
  return (
    color +
    Math.round(opacity * 255)
      .toString(16)
      .padStart(2, "0")
  );
}

export default Dashboard;
